import './styles/index.scss'
import { useCalendarApp, ScheduleXCalendar } from '@schedule-x/react'
import {
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
  CalendarEvent,
  CalendarConfig,
  viewMonthGrid,
  viewWeek,
} from '@schedule-x/calendar'
import { createEventsServicePlugin } from '@schedule-x/events-service'
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { MissionEquipmentPlanning, MissionEquipmentPlanningMeta } from '@/interfaces/missionEquipment'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { RemoteInfiniteAutoFetchContainer } from '../RemoteFetchContainer/RemoteInfiniteAutoFetchContainer'
import CustomDateGridEvent from './components/CustomDateGridEvent'
import CustomMonthGridEvent from './components/CustomMonthGridEvent'


export interface Event {
  title: string,
  start: Date,
  end: Date,
  allDay?: boolean
  resource?: any,
}

export interface EquipmentCalendarProps extends Partial<CalendarConfig> {
  formatEvents: (data: ApiResource<MissionEquipmentPlanning, MissionEquipmentPlanningMeta>[]) => CalendarEvent[],
  query: UseInfiniteQueryResult<InfiniteData<ApiCollectionResponse<MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta> | null, unknown >, Error >
}


export function EquipmentCalendar({
  formatEvents,
  query,
  ...props
}: EquipmentCalendarProps) {
  const eventsServicePlugin = createEventsServicePlugin()
  const calendarControls = createCalendarControlsPlugin()

  const { callbacks, ...otherProps } = props

  const calendar = useCalendarApp({
    views: [createViewDay(), createViewWeek(), createViewMonthGrid(), createViewMonthAgenda()],
    events: [],
    locale: 'fr-FR',
    plugins: [eventsServicePlugin, calendarControls],
    defaultView: viewWeek.name,
    callbacks: {
      onSelectedDateUpdate: (date) => {
        console.log('onSelectedDateUpdate', { date })
      },
      onClickAgendaDate: (date) => {
        console.log('onClickAgendaDate', { date })
      },
      onDoubleClickDate(date) {
        console.log('onDoubleClickDate', { date })
      },
      // onClickDateTime(dateTime) {
      //   console.log('onClickDateTime', { dateTime })
      // },
      onDoubleClickDateTime(dateTime) {
        console.log('onDoubleClickDateTime', { dateTime })
      },
      // onClickPlusEvents: (date) => {
      //   console.log('onClickPlusEvents', { date })
      //   calendarControls.setView(viewWeek.name)
      // },
      ...callbacks,
    },
    ...otherProps
  })

  eventsServicePlugin.beforeInit(calendar['$app'])


  return (<>
    <ScheduleXCalendar
      calendarApp={calendar}
      customComponents={{
        monthGridEvent: CustomMonthGridEvent,
        dateGridEvent: CustomDateGridEvent,
      }}
    />
    <RemoteInfiniteAutoFetchContainer<
      MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta>
      {...query}
      render={(data) => {

        const filteredData: CalendarEvent[] = formatEvents(data.data)
        eventsServicePlugin.set(filteredData)

        return <></>
      }} />
  </>
  )
}