import React from 'react';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useLogin } from '@/hooks/auth';
import { PasswordResetPayload, PasswordResetPayloadValues, resetPassword } from '@/api/user';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export interface ResetPasswordFormProps {
  reset_password_token: string
  email: string
  onLogin: () => void
}

export function ResetPasswordForm({ reset_password_token, email, onLogin }: ResetPasswordFormProps) {
  const login = useLogin();
  const navigate = useNavigate()

  const [error, setError] = React.useState<string | null>(null);

  const { mutateAsync, data } = useMutation({
    mutationFn: (payload: PasswordResetPayload) => resetPassword(payload),
  })

  const onSubmit = async (
    values: PasswordResetPayloadValues,
    { setErrors }: FormikHelpers<PasswordResetPayloadValues>
  ) => {

    try {
      const userData = await mutateAsync({ user: values });

      if (!userData.ok) {
        const err = await userData.json()
        setErrors(err.errors);
        setError(err.error);
      }

      await login.mutateAsync({ user: { email, password: values.password } })
      onLogin()

    } catch (err: any) {
      console.error(err)
      if (err.error) {
        setError(err.error);
      }
      else if (err.errors) {
        setErrors(err.errors);

        if (err.errors?.reset_password_token) {
          setError(`Le lien fourni ${err.errors?.reset_password_token}`);
        }
      }
    }
  }

  const initialValues = {
    reset_password_token,
    password: '',
    password_confirmation: '',
  }

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {(props) => (
      <Form>
        <Stack spacing="6">
          <Stack spacing="5">
            <Field name='reset_password_token'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!(form.errors.reset_password_token && form.touched.reset_password_token)}>
                  <FormLabel htmlFor='reset_password_token'>Email</FormLabel>
                  <Input {...field} id='reset_password_token' type='hidden' />
                  <Input value={email} id='misc_email' type='email' disabled />
                  <FormErrorMessage>{form.errors.reset_password_token as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='password'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!(form.errors.password && form.touched.password)}>
                  <FormLabel htmlFor='password'>Mot de passe</FormLabel>
                  <Input {...field} id='password' type='password' placeholder='Mot de passe' />
                  <FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='password_confirmation'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!(form.errors.password_confirmation && form.touched.password_confirmation)}>
                  <FormLabel htmlFor='password_confirmation'>Confirmation de mot de passe</FormLabel>
                  <Input {...field} id='password_confirmation' type='password' placeholder='Confirmation' />
                  <FormErrorMessage>{form.errors.password_confirmation as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
          {!props.isSubmitting && error && <Text color="red.700">{error}</Text>}
        </Stack>
        <Stack spacing="6">
          <Button
            mt={4}
            variant="primary"
            colorScheme='teal'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Mise à jour du mot de passe
          </Button>
        </Stack>
      </Form>
    )}
  </Formik>
  );
}
