import { Badge, Button, Checkbox, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { RefinementListConnectorParams } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import { useRefinementList } from "react-instantsearch";

export interface EquipementRefinementListProps extends RefinementListConnectorParams {
  lockedItems?: string[]
}
export default function EquipementRefinementList({ lockedItems = [], ...props }: EquipementRefinementListProps) {
  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList(props);

  return (
    <>
      <VStack alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        {items.map((item) => (
          <label key={item.label}>
            <HStack>
            <Checkbox
              isChecked={item.isRefined}
              onChange={() => refine(item.value)}
              isDisabled={(lockedItems as string[]).includes(item.value)}
            />
            <Text noOfLines={1}>{item.label}</Text>
            <Badge size='sm'>{item.count}</Badge>
            </HStack>
          </label>
        ))}
      </VStack>
      <Button size={'sm'} onClick={toggleShowMore} hidden={!canToggleShowMore} isDisabled={!canToggleShowMore}>
        {isShowingMore ? 'Voir moins' : 'Voir plus'}
      </Button>
    </>
  );
}

