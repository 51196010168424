import { getOrganizationStructures, getOrganizationStructuresKey } from "@/api/structure";
import { useNanuqQuery } from "@/hooks/query";
import { RecordId } from "@/interfaces/id";
import { StructureAttributes, StructureAttributesMeta } from "@/interfaces/structure";
import { ApiCollectionResponse } from "@/utils/api";
import { AsyncSelect, SingleValue } from "chakra-react-select";
import { FieldProps } from "formik";
import { useMemo } from "react";

interface Option {
  value: string
  label: string;
  type?: string
}

type OptionList = Array<Option>;

interface LocationSelectProps extends FieldProps {
  orgId: RecordId,
}

export function LocationSelect({
  orgId,
  field: { name, value },
  form: { setFieldValue, errors, touched },
  ...props
}: LocationSelectProps) {

  const queryKey = [getOrganizationStructuresKey, orgId, value]
  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationStructures(orgId, 1, { search: value }) : null
  )

  // const options: OptionList = useMemo(() => 
  //   data?.data?.map(o => ({ label: o.attributes[dataLabel], value: `${o[dataValue]}` })) || []
  // , [data, dataValue, dataLabel])

  // const defaultValue = (options: OptionList, value: string) => {
  //   console.log("Finding default value for ", { value , options })
  //   return options ? options.find(option => option.value === value?.toString()) : undefined
  // }

  // useEffect(() => {
  //   const columnName = name.replace("_id", "")
  //   console.log({ errors, columnName })
  //   if (errors[columnName]) {
  //     setFieldError(name, (errors[columnName] as string[]).join(', '))
  //   }
  // }, [errors, name, setFieldError])

  const formatData = (results: ApiCollectionResponse<StructureAttributes, unknown, StructureAttributesMeta>) => {
    return results.data.map(r => ({
      value: r.id.toString(),
      label: r.attributes.name,
      type: 'Structure'
    }))
  }

  const fetchAndParseLocations = async (search: string): Promise<Option[]> => {
    const results = await getOrganizationStructures(orgId, 1, { search })

    return formatData(results)
  }

  const options: OptionList = useMemo(() =>
    data?.data ? formatData(data) : [],
    [data]
  )

  return (
    <>
      {isLoading ? (
        <div>Chargement</div>
      ) : error ? (
        <div>Erreur: {error.message}</div>
      ) : data ? (
        <AsyncSelect
          cacheOptions
          defaultOptions
          options={options}
          loadOptions={fetchAndParseLocations}
          isInvalid={errors[name] && touched[name] ? true : false}
          isLoading={isLoading}
          placeholder={`Sélectionner`}
          value={value}
          onChange={
            (option: SingleValue<Option>) => setFieldValue(name, option)
          }
        />
      ) : null}
    </>
  )
}