
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  FiSettings,
} from 'react-icons/fi'
import {
  TbArrowLoopRight2,
  TbBuildingWarehouse,
  TbCalendar,
  TbGauge,
  TbPackage,
  TbSearch,
  TbTruck
} from 'react-icons/tb'
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { MobileNavButton, NavButton } from '../NavButton/NavButton'

import { useLogout, useUser } from '@/hooks/auth';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { structuresRoute, settingsOrganizationRoute, equipmentsRoute, missionsRoute, generalSettingsOrganizationRoute, equipmentTypesRoute, structureSummaryRoute, ridesRoute, reservationsRoute, planningForStructureRoute, reservationsSearchRoute, vehiculesRoute, accountRoute } from '@/constants/routes';
import { NavSubButton } from '../NavSubButton/NavSubButton'
import { UserProfile } from '../UserProfile/UserProfile'
import { ColorModeSwitcher } from '../ColorModeSwitcher/ColorModeSwitcher'
import { RiAccountCircleLine, RiLoginBoxLine } from 'react-icons/ri'



export const Navbar = () => {

  const { data: user } = useUser()
  const logout = useLogout()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { organizations, getSelectedOrganization, ability } = useOrganizations()

  const currentOrganization = getSelectedOrganization()

  const preferedStructure = user?.meta.user_structures && user?.meta.user_structures[0]

  const onLogout = () => {
    logout.mutate({})
    navigate('/login')
  }

  return (
    <Box
      width="full"
      borderTop={'1px solid'}
      borderTopColor={'bg.muted'}
      bg="white"
      px={1}
      py={3}
      overflowX={'auto'}
      boxShadow={useColorModeValue('sm', 'sm-dark')}
    >
      <Flex justify="space-between">

        {currentOrganization && ability && (
          <HStack spacing="1" alignItems={'stretch'} flex={1}>
            {ability?.can('reserve', 'organization') && preferedStructure && (
              <MobileNavButton label="Réserver" as={RouterLink} to={reservationsSearchRoute(currentOrganization?.id)} icon={TbSearch} />
            )}

            {ability?.can('reserve', 'organization') && preferedStructure && (
              <MobileNavButton label="Calendrier" as={RouterLink} to={reservationsRoute(currentOrganization?.id)} icon={TbCalendar} />
            )}

            {ability?.can('list', 'equipment') && (<>
              <MobileNavButton label="Véhicules" as={RouterLink} to={vehiculesRoute(currentOrganization?.id)} icon={TbTruck} />
            </>)}

            <MobileNavButton label="Compte" as={RouterLink} to={accountRoute(getSelectedOrganization()?.id)} icon={RiAccountCircleLine} />

          </HStack>
        )}
      </Flex>
    </Box>
  )
}