import { As, Badge, Button, ButtonProps, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import * as React from 'react'

interface NavButtonProps extends ButtonProps {
  icon?: As
  label: string
  counter?: string | number | null
  to?: string
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, counter, to, ...buttonProps } = props
  return (
    <Button variant="sidebar" to={to} w={"100%"} justifyContent="start" {...buttonProps}>
      <HStack spacing="3">
        <HStack spacing="3">
          {icon  && <Icon as={icon} boxSize="6" color="subtle" />}
          <Text>{label}</Text>
        </HStack>
        {counter && <Badge color="currentcolor" alignSelf={"flex-end"}>{counter}</Badge>}
      </HStack>
    </Button>
  )
}

export const MobileNavButton = (props: NavButtonProps) => {
  const { icon, label, counter, to, ...buttonProps } = props
  return (
    <Button variant="sidebar-mobile" to={to} w={"100%"} justifyContent="center" {...buttonProps}>
      <HStack spacing="0">
        <VStack spacing="0">
          {icon && <Icon as={icon} boxSize="6" color="subtle" />}
          <Text>{label}</Text>
        </VStack>
        {counter && <Badge color="currentcolor" alignSelf={"flex-end"}>{counter}</Badge>}
      </HStack>
    </Button>
  )
}