import { EquipmentEvent } from "@/interfaces/equipment"
import { LABEL_FOR_ACTION_FIELDS } from "@/utils/equipment"
import { EVENT_LABELS } from "@/utils/states"
import { Button, FormLabel, FormControl, FormErrorMessage, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, HStack } from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik"
import { LocationSelect } from "../../../components/LocationSelect/LocationSelect"
import { useParams } from "react-router-dom"

export interface EquipmentActionMetadata { [key: string]: string }

export interface EquipmentActionModalProps {
  action: EquipmentEvent,
  onClose: () => void,
  onOpen: () => void,
  isOpen: boolean,
  onAction: (action: EquipmentEvent, metadata: EquipmentActionMetadata) => Promise<void>
}

export const EquipmentActionModal = ({
  action,
  onAction,
  isOpen,
  onOpen,
  onClose
}: EquipmentActionModalProps) => {

  const { orgId } = useParams()
  console.log({ action })
  const { label: mainLabel, icon: MainIcon, color, fields } = EVENT_LABELS[action]

  const initialValues = Object.fromEntries(Object.keys(fields).map(e => ([e, ''])))

  const fieldsForType = {
    string: (fieldName: string) => {
      return (
        <Field key={fieldName} name={fieldName}>
          {({ field, form }: FieldProps) => (
            <FormControl isInvalid={form.errors[fieldName] && form.touched[fieldName] ? true : false}>
              <FormLabel htmlFor={fieldName}>{LABEL_FOR_ACTION_FIELDS[fieldName]}</FormLabel>
              <Input {...field} id={fieldName} placeholder={''} />
              <FormErrorMessage>{form.errors[fieldName] as string}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      )
    },
    location: (fieldName: string) => {
      return (
        <Field key={fieldName} name={fieldName}>
          {(formProps: FieldProps) => (<FormControl isInvalid={formProps.form.errors[fieldName] && formProps.form.touched[fieldName] ? true : false}>
            <FormLabel htmlFor={fieldName}>{LABEL_FOR_ACTION_FIELDS[fieldName]}</FormLabel>
              <LocationSelect {...formProps} orgId={orgId!} />
              <FormErrorMessage>{formProps.form.errors[fieldName] as string}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      )
    }
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{mainLabel} ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initialValues}
              onSubmit={(
                values: EquipmentActionMetadata,
                { setSubmitting }: FormikHelpers<EquipmentActionMetadata>
              ) => {
                onAction(action, values)
                setSubmitting(false);
              }}
            >
              <Form>
                <VStack spacing={3} justifyContent={'stretch'} flex={1}>
                  { Object.keys(fields).map((fieldName) => fieldsForType[fields[fieldName]](fieldName) )}
                  <HStack justifyContent={'space-between'} flex={1} alignContent={'center'}>
                    <Button onClick={onClose} variant='ghost'>Annuler</Button>
                    <Button mr={3} type="submit">{mainLabel}</Button>
                  </HStack>
                </VStack>
              </Form>
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}