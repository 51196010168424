
import { actionOnEquipment, getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta, EquipmentEvent, TakeoutState } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Button, HStack, Heading, IconButton, Stack, Step, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, Stepper, Text, VStack, useColorModeValue, useToast } from '@chakra-ui/react';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useEffect, useState } from 'react';
import { StepOneVehiculeState } from '@/screens/EquipmentTakeoutSteps/StepOneVehiculeState';
import { StepTwoVehiculePictures } from '@/screens/EquipmentTakeoutSteps/StepTwoVehiculePicture';
import { StepThreeVehiculeMetrics } from '@/screens/EquipmentTakeoutSteps/StepThreeVehiculeMetrics';
import { TbArrowLeft } from 'react-icons/tb';
import { StepFourVehiculeComment } from '@/screens/EquipmentTakeoutSteps/StepFourVehiculeComment';
import { StepFiveFinalStart } from '@/screens/EquipmentTakeoutSteps/StepFiveFinalStart';
import { createRideFromFormData } from '@/api/ride';
import { AxiosProgressEvent } from "axios";
import { TakeoutUploadProgress } from './TakeoutUploadProgress';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import { goEquipmentRoute } from '@/constants/routes';
import { useEquipmentReservation } from '@/hooks/useEquipmentReservation';
import { getMission, getMissionQuery } from '@/api/mission';
import { isFuture, parseISO } from 'date-fns';
import { EquipmentActionMetadata } from '@/screens/EquipmentActionButton/Action/Modal';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const STEPS = [
  { Component: StepOneVehiculeState, label: "État du véhicule" },
  { Component: StepFourVehiculeComment, label: "Commentaire" },
  { Component: StepTwoVehiculePictures, label: "Photos" },
  { Component: StepThreeVehiculeMetrics, label: "Kilométrage" },
  { Component: StepFiveFinalStart, label: "C'est tout bon !" },
  { Component: StepFiveFinalStart, label: "C'est tout bon !" },
]

export const TakeoutStart = () => {

  const { equipmentId, missionId } = useParams()
  const { data: user } = useUser();
  const toast = useToast()
  const { ability, selectedOrganizationId } = useOrganizations()
  const navigate = useNavigate()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { data, refetch } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })
  const { data: mission } = useNanuqQuery(getMissionQuery(missionId as RecordId).queryKey, () => missionId ? getMission(missionId) : null)

  const { data: equipment } = (data || {})

  const [takeoutState, setTakeoutState] = useState<TakeoutState>({})
  const [currentStep, setCurrentStep] = useState(0)
  const [currentProgress, setCurrentProgress] = useState<number | undefined>(0)

  const onUpdateProgress = (progress: AxiosProgressEvent) => {
    if (progress) {
      setCurrentProgress(progress.progress)
    }
  }

  useEffect(() => {
    if (mission && isFuture(parseISO(mission.data.attributes.begin_at))) {
      navigate(goEquipmentRoute(selectedOrganizationId, equipmentId))
    }
  }, [mission, navigate, selectedOrganizationId, equipmentId])

  const {
    currentActiveRideForUser
  } = useEquipmentReservation(user, equipment?.meta)

  const { Component: StepComponent, label} = STEPS[currentStep];

  useEffect(() => {
    if (equipment?.meta?.last_ride && takeoutState.current_km == undefined) {
      setTakeoutState({ ...takeoutState, current_km: equipment?.meta?.last_ride.km_after })
    }
  }, [equipment?.meta?.last_ride, takeoutState])

  useEffect(() => {
    if (currentStep == (STEPS.length - 1)) {
      navigate(goEquipmentRoute(selectedOrganizationId, equipmentId))
    }
  }, [currentStep, navigate, selectedOrganizationId, equipmentId])

  useEffect(() => {
    if (currentStep != 4 && currentActiveRideForUser) {
      toast({ title: 'Un trajet est déja en cours', status: 'error', isClosable: true, duration: 5000 })
      navigate(goEquipmentRoute(selectedOrganizationId, equipmentId))
    }
  }, [currentStep, currentActiveRideForUser, toast, navigate, selectedOrganizationId, equipmentId])

  const { mutateAsync, error, isPending, status } = useMutation({
    mutationFn: (formPayload: FormData) => createRideFromFormData(equipmentId || '', formPayload, onUpdateProgress)
  })

  const { mutateAsync: mutateAction, error: errorAction, isPending: isPendingAction } = useMutation({
    mutationFn: ({ action, meta } : { action: EquipmentEvent, meta: EquipmentActionMetadata }) => actionOnEquipment(equipmentId || '', action, meta)
  })

  const onNext = async (newState: TakeoutState) => {
    setTakeoutState(newState)
    if (currentStep == 3) {
      const response = await mutateAsync(buildFormData(newState))
      console.log("DATA = ", response)
      toast({ title: 'Trajet commencé', description: 'Bonne route !', status: 'success', isClosable: true, duration: 5000 })
    } else {
      setCurrentProgress(0)
    }
    setCurrentStep(currentStep + 1)
  }

  useEffect(() => {
    if (takeoutState && takeoutState.usable === "no" && currentStep > 1) {
      mutateAction({
        action: 'mark_bad',
        meta: {
          notes: takeoutState.comment || ''
        }
      }, {
        onSuccess: () => {
          refetch()
          toast({ title: 'Le véhicule a été marqué inutilisable', status: 'success', isClosable: true, duration: 5000 })
          navigate(goEquipmentRoute(selectedOrganizationId, equipmentId))
        }
      })
    }
  }, [takeoutState, currentStep, refetch, mutateAction, equipmentId, navigate, selectedOrganizationId, toast])


  const buildFormData = (newState: TakeoutState) => {
    const formData = new FormData();
    if (newState.imageFront) {
      formData.append('ride[photo_front_before]', newState.imageFront, newState.imageFront.name)
    }
    if (newState.imageLeft) {
      formData.append('ride[photo_left_before]', newState.imageLeft, newState.imageLeft.name)
    }
    if (newState.imageRight) {
      formData.append('ride[photo_right_before]', newState.imageRight, newState.imageRight.name)
    }
    if (newState.imageBack) {
      formData.append('ride[photo_back_before]', newState.imageBack, newState.imageBack.name)
    }
    formData.append('ride[km_before]', `${newState.current_km}`)
    formData.append('ride[usable]', newState.usable ?? 'yes')
    formData.append('ride[comment]', newState.comment ?? '')
    formData.append('ride[mission_id]', missionId ?? '')
    formData.append('ride[equipment_id]', equipmentId ?? '')
    formData.append('ride[user_id]', user?.attributes.id?.toString() ?? '')

    return formData
  }

  return (
    
      <VStack
        m={4}
        flexGrow={1}
        flexShrink={0}
        flexBasis={{ base: '100%', md: '250px' }}
        spacing={6}
        alignItems={'stretch'}
        px={[4, 6]}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderColor={'bg.accent'}
        borderWidth={1}
        borderRadius={6}
      >
      {data && mission && (<>
        {currentStep < (STEPS.length - 2) && (<Stack spacing={3}>
          <HStack>
            {currentStep <= 0 ? (
              <IconButton aria-label="Retour" as={RouterLink} to={goEquipmentRoute(selectedOrganizationId, equipmentId)} icon={<TbArrowLeft />} />
            ) : (
              <IconButton aria-label="Retour" onClick={(() => setCurrentStep(currentStep - 1))} icon={<TbArrowLeft />} />
            )}
            <VStack spacing={0} alignItems={'stretch'}>
              <Text color={'fg.subtle'}>Emprunter le véhicule</Text>
              <Heading lineHeight={'1.5'} fontSize={'large'}>{label}</Heading>
            </VStack>
          </HStack>
          <Stepper colorScheme='brand' size='md' index={currentStep} gap='0'>
            {STEPS.slice(0, STEPS.length - 2).map((step, index) => (
              <Step key={index} style={{gap: 0}}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <StepSeparator style={{marginLeft: 0}} />
              </Step>
            ))}
          </Stepper>
        </Stack>)}
        {!isPending && !error && equipment?.attributes && <StepComponent 
          equipment={equipment?.attributes}
          currentState={takeoutState}
          onNext={onNext}
        />}
        {error && <>
          <ErrorBox 
            message="Une erreur s'est produite"
            error={error}
          />
          <Button as={RouterLink} leftIcon={<TbArrowLeft />} variant={'outline'} to={goEquipmentRoute(selectedOrganizationId, equipmentId)}>Retour a la réservation</Button>
        </>}
        <TakeoutUploadProgress type='start' active={isPending} progress={currentProgress || 0} error={error?.message} />
      </>)}
    </VStack>
    
  );
}

export default TakeoutStart;