import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Table, Tbody, Td, Tr
  } from '@chakra-ui/react'
import { inviteOrganizationsUsers, getOrganizationsUsersKey } from '@/api/organization';

import { OrganizationsUserAttributes, CreateOrganizationsUserAttributes } from '@/interfaces/organization';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNanuqQuery } from '@/hooks/query';
import { getUserUserStructures, getUserUserStructuresKey } from '@/api/userStructure';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import EditUserStructureForm from './EditUserStructureForm';
import { ApiResourceResponse } from '@/utils/api';
import { UserStructureAttributes } from '@/interfaces/userStructure';

export interface UserDetailModalProps {
  isOpen: boolean
  onClose: () => void
  orgId: string,
  organizationsUser?: OrganizationsUserAttributes,
}

const UserDetailModal = ({ orgId, isOpen, onClose, organizationsUser }: UserDetailModalProps) => {


  const { ability } = useOrganizations()

  const queryClient = useQueryClient()

  const { mutate, isPending, isSuccess, data, reset, error } = useMutation(
    {
      mutationFn: (organizationsUser: CreateOrganizationsUserAttributes) => inviteOrganizationsUsers(orgId, organizationsUser),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [getOrganizationsUsersKey, orgId], stale: true })
      }
    }
  )

  const { data: userStructures, refetch } = useNanuqQuery(
    [getUserUserStructuresKey, organizationsUser?.user_id],
    () => organizationsUser?.user_id ? getUserUserStructures(organizationsUser?.user_id) : null
  )

  const handleClose = async () => {
    await queryClient.refetchQueries({ queryKey: [getOrganizationsUsersKey, orgId], stale: true })

    reset()
    onClose()
  }

  const onUserStructureUpdate = async (newUserStructure: ApiResourceResponse<UserStructureAttributes, unknown, unknown>) => {
    setTimeout(() => refetch(), 1000)
    await queryClient.invalidateQueries({ queryKey: [getUserUserStructuresKey, organizationsUser?.user_id], stale: true })

  }

  return (
    <>
      {ability?.isOrgOwner() && <Modal size={'3xl'} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{organizationsUser?.full_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Table>
              <Tbody>
                {userStructures?.data?.flatMap(us => <Tr key={`${us.id}`}>
                      <Td>
                        <EditUserStructureForm
                          orgId={orgId}
                          initialUserStructure={us.attributes}
                          onSave={onUserStructureUpdate}
                        />
                      </Td>
                    </Tr>
                  )}
              </Tbody>
            </Table>

          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={handleClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>}
    </>
  )
}

export default UserDetailModal