import { useNanuqQuery } from "@/hooks/query";
import { ApiCollectionResponse, ApiErrorResponse, ApiNamedResource, ApiResource } from "@/utils/api";
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { Select, SelectComponent, SingleValue } from "chakra-react-select";
import { ErrorMessage, FieldProps } from "formik";
import { useEffect, useMemo, useState } from "react";

export interface Option {
  value: string
  label: string;
}

export type OptionList = Array<Option>;

interface AsyncFieldProps<M> extends FieldProps {
  query: UseQueryResult<ApiCollectionResponse<M>, ApiErrorResponse>
  label: string
  dataLabel?: string
  dataValue?: string
  helperText?: string
  filterFn?: (value: string) => boolean
  additionalOptions?: OptionList
  selectOptions?: any
}

export function AsyncSelect<T>({
  field: { name, value },
  form: { setFieldValue, errors, setFieldError, touched },
  query,
  label,
  helperText,
  filterFn,
  dataLabel = 'name',
  dataValue = 'id',
  additionalOptions = [],
  selectOptions
}: AsyncFieldProps<T>) {

  const {
    isLoading,
    data,
    error
  } = query

  const [currentValue, setCurrentValue] = useState<Option>()

  const options: OptionList = useMemo(() => {
    let baseOptions = data?.data?.map(o => ({ label: o.attributes[dataLabel], value: `${o[dataValue]}` })) || []

    if (filterFn) {
      baseOptions = baseOptions.filter(e => filterFn(e.value.toString()))
    }
    return [...(additionalOptions || []), ...baseOptions]


  }, [filterFn, data, dataValue, dataLabel, additionalOptions])

  useEffect(() => {
    const columnName = name.replace("_id", "")
    if (errors[columnName]) {
      setFieldError(name, (errors[columnName] as string[]).join(', '))
    }
  }, [errors, name, setFieldError])

  useEffect(() => {
    if (!currentValue || (currentValue.value !== `${value}`)) {
      const defaultValue = options ? options.find(option => option.value === value?.toString()) : { label: `${value} ?`, value }
      setCurrentValue(defaultValue)
    }
  }, [options, value, setCurrentValue, currentValue])

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      {isLoading ? (
        <div>Chargement</div>
      ) : error ? (
        <div>Erreur: {error.message}</div>
      ) : data ? (
        <Box>
          <Select
            isInvalid={errors[name] && touched[name] ? true : false}
            placeholder={`Sélectionner`}
            options={options}
            value={currentValue}
            onChange={
              (option: SingleValue<Option>) => setFieldValue(name, option?.value)
            }
            {...selectOptions}
          />
        </Box>
      ) : null}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errors[name] as string}</FormErrorMessage>
    </FormControl>
  )
}