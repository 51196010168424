
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta, TakeoutState } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Button, HStack, Heading, IconButton, Stack, Step, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, Stepper, Text, VStack, useColorModeValue, useToast } from '@chakra-ui/react';
import { QueryClient, useMutation } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useEffect, useState } from 'react';
import { StepOneVehiculeState } from '@/screens/EquipmentTakeoutSteps/StepOneVehiculeState';
import { StepTwoVehiculePictures } from '@/screens/EquipmentTakeoutSteps/StepTwoVehiculePicture';
import { StepThreeVehiculeMetrics } from '@/screens/EquipmentTakeoutSteps/StepThreeVehiculeMetrics';
import { TbArrowLeft } from 'react-icons/tb';
import { StepFourVehiculeComment } from '@/screens/EquipmentTakeoutSteps/StepFourVehiculeComment';
import { StepFiveFinalFinish } from '@/screens/EquipmentTakeoutSteps/StepFiveFinalFinish';
import { finishRideFromFormData, getRide, getRideQuery } from '@/api/ride';
import { AxiosProgressEvent } from "axios";
import { TakeoutUploadProgress } from './TakeoutUploadProgress';
import { goEquipmentRoute } from '@/constants/routes';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import { StepVehiculeGas } from '@/screens/EquipmentTakeoutSteps/StepVehiculeGas';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const STEPS = [
  { Component: StepOneVehiculeState, label: "État du véhicule" },
  { Component: StepVehiculeGas, label: "Carburant" },
  { Component: StepTwoVehiculePictures, label: "Photos" },
  { Component: StepThreeVehiculeMetrics, label: "Kilométrage" },
  { Component: StepFourVehiculeComment, label: "Commentaire" },
  { Component: StepFiveFinalFinish, label: "C'est tout bon !" },
]

export const TakeoutEnd = () => {

  const { equipmentId, rideId } = useParams()
  const { data: user } = useUser();
  const toast = useToast()
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizations()

  const [takeoutState, setTakeoutState] = useState<TakeoutState>({})
  const [currentStep, setCurrentStep] = useState(0)
  const [currentProgress, setCurrentProgress] = useState<number | undefined>(0)
  

  const onUpdateProgress = (progress: AxiosProgressEvent) => {
    if (progress) {
      setCurrentProgress(progress.progress)
    }
  }

  useEffect(() => {
    if (currentStep == (STEPS.length - 1)) {
      navigate(goEquipmentRoute(selectedOrganizationId, equipmentId))
    }
  }, [currentStep, navigate, selectedOrganizationId, equipmentId])

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })
  const rideState = useNanuqQuery(getRideQuery(rideId as RecordId).queryKey, () => rideId ? getRide(rideId) : null)

  const { data: equipment } = (data || {})

  const { Component: StepComponent, label} = STEPS[currentStep];


  const { mutateAsync, error, isPending } = useMutation({
    mutationFn: (formPayload: FormData) => finishRideFromFormData(rideId || '', formPayload, onUpdateProgress)
  })

  const onNext = async (newState: TakeoutState) => {
    setTakeoutState(newState)
    if (currentStep == 4) {
      const response = await mutateAsync(buildFormData(newState))
      console.log("DATA = ", response)
      toast({ title: 'Trajet terminé', description: 'Votre trajet a bien été enregistré.', status: 'success', isClosable: true, duration: 5000 })
    } else {
      setCurrentProgress(0)
    }
    setCurrentStep(currentStep + 1)
  }

  const buildFormData = (newState: TakeoutState) => {
    const formData = new FormData();
    if (newState.imageFront) {
      formData.append('ride[photo_front_after]', newState.imageFront, newState.imageFront.name)
    }
    if (newState.imageLeft) {
      formData.append('ride[photo_left_after]', newState.imageLeft, newState.imageLeft.name)
    }
    if (newState.imageRight) {
      formData.append('ride[photo_right_after]', newState.imageRight, newState.imageRight.name)
    }
    if (newState.imageBack) {
      formData.append('ride[photo_back_after]', newState.imageBack, newState.imageBack.name)
    }
    formData.append('ride[km_after]', `${newState.current_km}`)
    formData.append('ride[usable]', newState.usable ?? 'yes')
    formData.append('ride[comment]', newState.comment ?? '')
    formData.append('ride[gas_level_after]', `${newState.current_gas_level}`)

    return formData
  }

  return (
    <VStack
      mx={4}
      flexGrow={1}
      flexShrink={0}
      flexBasis={{ base: '100%', md: '250px' }}
      spacing={6}
      alignItems={'stretch'}
      px={[4, 6]}
      py={3}
      bg={useColorModeValue('white', 'gray.800')}
      borderColor={'bg.accent'}
      borderWidth={1}
      borderRadius={6}
    >
      <RemoteFetchContainer {...rideState} render={(ride) => (
        <>
        {currentStep < (STEPS.length - 1) && (<Stack spacing={3}>
          <HStack>
            {currentStep <= 0 ? (
              <IconButton aria-label="Retour" as={RouterLink} variant={'outline'} to={goEquipmentRoute(selectedOrganizationId, equipmentId)} icon={<TbArrowLeft />} />
            ): (
              <IconButton aria-label="Retour" onClick={(() => setCurrentStep(currentStep - 1))} icon={<TbArrowLeft />} />
            )}
            <VStack spacing={0} alignItems={'stretch'}>
              <Heading lineHeight={'1.5'} fontSize={'large'}>Retour du véhicule</Heading>
              <Text color={'fg.subtle'}>{label}</Text>
            </VStack>
          </HStack>
          <Stepper colorScheme='brand' size='md' index={currentStep} gap='0'>
            {STEPS.map((step, index) => (
              <Step key={index} style={{ gap: 0 }}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <StepSeparator style={{ marginLeft: 0 }} />
              </Step>
            ))}
          </Stepper>
        </Stack>)}
          {!isPending && equipment?.attributes && <StepComponent
            equipment={equipment?.attributes}
            ride={ride?.data?.attributes}
            currentState={takeoutState}
            onNext={onNext}
          />}
          <TakeoutUploadProgress type='end' active={isPending} progress={currentProgress || 0} error={error?.message} />
          {currentStep < (STEPS.length - 1) && (
            <Button as={RouterLink} variant={'outline'} to={goEquipmentRoute(selectedOrganizationId, equipmentId)}>Annuler</Button>
          )}
        </>
      )}/>
    </VStack>
  );
}

export default TakeoutEnd;