
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { equipmentsNewRoute } from '@/constants/routes';
import { Button } from '@chakra-ui/react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { VehiculesList } from './VehiculesList';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useEffect, useMemo } from 'react';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import QueryString from 'qs';


export const AllVehiculesPage = () => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: user } = useUser();
  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  useEffect(() => {
    if (!queryParams.structure_id && (user?.meta?.user_structures || []).length > 0) {
      const structure_id = (user?.meta?.user_structures || [])[0]?.structure_id
      setSearchParams(QueryString.stringify({ ...queryParams, structure_id }))
    }
  }, [queryParams, setSearchParams, user?.meta?.user_structures])


  return (
    <PageWithTitle
      title="Véhicules"
      actions={[
        ability?.can('create', 'equipment') && <Button key='new' size={'sm'} as={Link} variant="primary" to={equipmentsNewRoute(orgId)}>Nouveau</Button>
      ]}
    >

      {ability?.can('list', 'equipment') && queryParams.structure_id && <VehiculesList />}
    </PageWithTitle>
  );
}

export default AllVehiculesPage;