import { FieldProps } from 'formik'
import * as React from 'react'

interface ImageInputProps extends FieldProps {
}

export const ImageInput = ({
  field: { name, value, ...fieldProps },
  form: { setFieldValue, errors, setFieldError, touched },
}: ImageInputProps) => {
  
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFieldValue(name, e?.target?.files && e?.target?.files[0])
  }

  return (
    <input
      {...fieldProps}
      name={name}
      type="file"
      capture="environment"
      accept="image/*"
      onChange={onChange}
    />
  )
}
