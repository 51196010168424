import { colorsForCode } from "@/utils/missions";
import { CalendarEvent } from "@schedule-x/calendar";

export default function CustomMonthGridEvent({ calendarEvent }: {calendarEvent: CalendarEvent}) {

  const colors = colorsForCode(calendarEvent['mission_type'])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: colors.bg,
        color: colors.fg,
        padding: '2px 5px',
        marginLeft: '10px',
        borderRadius: 5,
        border: '1px solid white',
      }}
    >
      {calendarEvent.title}
    </div>
  )
}