import { Badge, Box, Checkbox, HStack, List, ListIcon, ListItem, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { TbArrowDown, TbArrowRight, TbMinus, TbPlus } from "react-icons/tb";
import {
  useHierarchicalMenu,
  UseHierarchicalMenuProps,
} from 'react-instantsearch';

export function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
    event.altKey ||
    event.ctrlKey ||
    event.metaKey ||
    event.shiftKey
  );
}

type HierarchicalListProps = Pick<
  ReturnType<typeof useHierarchicalMenu>,
  'items' | 'createURL'
> & {
  onNavigate(value: string): void
  level?: number
};

function HierarchicalList({
  items,
  createURL,
  onNavigate,
  level = 0
}: HierarchicalListProps) {
  if (items.length === 0) {
    return null;
  }
  return (
    <VStack alignItems={'flex-start'} spacing={3}>
      {items.map((item) => (
        <VStack alignItems={'flex-start'} pl={level * 3} key={item.value}>
          <a
            href={createURL(item.value)}
            onClick={(event) => {
              if (isModifierClick(event)) {
                return;
              }
              event.preventDefault();

              onNavigate(item.value);
            }}
            // style={{ fontWeight: item.isRefined ? 'bold' : 'normal' }}
          >
            <HStack>
              <Checkbox isChecked={item.isRefined} />
              <Text fontWeight={level == 0 ? '400' : '400'} noOfLines={1}>{item.label}</Text>
              <Badge size='sm'>{item.count}</Badge>
            </HStack>
          </a>
          {item.data && (
            <HierarchicalList
              items={item.data}
              onNavigate={onNavigate}
              createURL={createURL}
              level={level + 1}
            />
          )}
        </VStack>
      ))}
    </VStack>
  );
}

export function HierarchicalMenu(props: UseHierarchicalMenuProps) {
  const {
    items,
    refine,
    canToggleShowMore,
    toggleShowMore,
    isShowingMore,
    createURL,
  } = useHierarchicalMenu(props);

  return (
    <>
      <HierarchicalList
        items={items}
        onNavigate={refine}
        createURL={createURL}
      />
      {props.showMore && (
        <button disabled={!canToggleShowMore} onClick={toggleShowMore}>
          {isShowingMore ? 'Voir moins' : 'Voir plus'}
        </button>
      )}
    </>
  );
}