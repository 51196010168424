import React, { useEffect } from 'react';
import { LoginForm } from '@/screens/Auth/LoginForm';
import { MagicLinkForm } from '@/screens/Auth/MagicLinkForm';

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { Logo } from '@/components/Logo/Logo';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/hooks/auth';
import { LOCALSTORAGE_REDIRECT_KEY, organizationsRoute } from '@/constants/routes';

export type AuthMode = 'login' | 'register' | 'password_forgot';

export function Auth() {
  const [loginMode, setLoginMode] = React.useState<AuthMode>('register');
  const navigate = useNavigate()
  const { data } = useUser()

  useEffect(() => {
    if (data?.attributes?.email) {
      const wantedRedirect = localStorage.getItem(LOCALSTORAGE_REDIRECT_KEY)
      if (wantedRedirect && wantedRedirect.length > 0) {
        console.log(`Removing ${wantedRedirect} for redirect`)
        localStorage.removeItem(LOCALSTORAGE_REDIRECT_KEY)
        navigate(wantedRedirect)
      } else {
        navigate(organizationsRoute(data?.attributes?.organization_id))
      }
    }
  }, [data, navigate])

  const ComponentForAuthMode = {
    'login': LoginForm,
    'register': MagicLinkForm,
    'password_forgot': MagicLinkForm,
  }[loginMode]

  const TitleForAuthMode = {
    'login': 'Se connecter',
    'register': 'Se connecter',
    'password_forgot': 'Mot de passe oublié',
  }[loginMode]

  return (
    <Flex
      as="section"
      direction={{ base: 'column', lg: 'row' }}
      height="100vh"
      bg={useColorModeValue('gray.50', 'gray.900')}
      overflowY="auto"
    >
      <Container py="8" px={0} flex="1">
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '3', sm: '6' }}>
            <Stack spacing="8">
              <Stack spacing="6">
                <VStack justifyContent="center">
                  <Logo withIcon maxW='8em' />
                </VStack>
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                  <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                    {TitleForAuthMode}
                  </Heading>
                </Stack>
              </Stack>
              <Box
                py={{ base: '4', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius={{ base: 'xl', sm: 'xl' }}
              >
                {<ComponentForAuthMode />}
              </Box>
              <hr />
              <VStack spacing={6} justify="flex-start" alignItems={'flex-start'} justifyContent={'flex-start'}>
                {loginMode !== 'login' && (<HStack flexWrap={'wrap'} as="p">
                  <Button size={'sm'} onClick={() => setLoginMode('login')} variant="link" colorScheme="brand">Se connecter avec un mot de passe</Button>
                </HStack>)}
                {loginMode !== 'register' && (<HStack flexWrap={'wrap'} as="p">
                  <Button size={'sm'} onClick={() => setLoginMode('register')} variant="link" colorScheme="brand">Recevoir un lien de connexion par mail</Button>
                </HStack>)}
                {loginMode !== 'password_forgot' && (<HStack flexWrap={'wrap'} as="p">
                  <Button size={'sm'} onClick={() => setLoginMode('password_forgot')} variant="link" colorScheme="brand">Mot de passe oublié ?</Button>
                </HStack>)}
              </VStack>

            </Stack>
          </Container>
        </Stack>
      </Container>
    </Flex>

  )
}
