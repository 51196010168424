import { Button, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react"
import { ReactNode, useState } from "react"
import { TbTruck } from "react-icons/tb"
import { VehiculeReservationBoxModal } from "./Modal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getEquipmentHistoriesKey } from "@/api/equipmentHistory"
import { getEquipmentQuery } from "@/api/equipment"
import { RecordId } from "@/interfaces/id"
import { addEquipmentToMission } from "@/api/missionEquipment"
import { EquipmentAttributes } from "@/interfaces/equipment"
import { VehiculesListQueryParams } from "@/pages/Vehicules/VehiculesList"


export interface VehiculeReservationBoxButtonProps extends ButtonProps {
  equipmentId: RecordId,
  equipment?: EquipmentAttributes,
  queryParams?: VehiculesListQueryParams,
  buttonLabel?: string | ReactNode,
  isLoading?: boolean,
  onReservationSuccess?: (equipmentId: RecordId, missionId: RecordId) => void
}

export const VehiculeReservationBoxButton = ({
  equipmentId,
  equipment,
  queryParams,
  isLoading = false,
  buttonLabel = 'Réserver',
  isDisabled,
  onReservationSuccess,
  ...props
}: VehiculeReservationBoxButtonProps) => {

  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedEquipments, setSelectedEquipments] = useState<RecordId>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onTakeoutSelected = (equipmentId: RecordId) => {
    setSelectedEquipments(equipmentId)
    onOpen()
  }

  const { data, mutateAsync: mutateTakeout, isPending: actionPending } = useMutation({
    mutationFn: (payload: { equipmentId: RecordId, missionId: RecordId }) => addEquipmentToMission(payload.equipmentId, payload.missionId)
  })

  const onTakeout = async (equipmentId: RecordId, missionId: RecordId) => {
    console.log({ equipmentId, missionId })

    try {
      await mutateTakeout({ equipmentId, missionId }, {
        onSettled: async () => {
          const toInvalidate = [
            getEquipmentQuery(equipmentId).queryKey,
            [getEquipmentHistoriesKey, equipmentId],
          ]

          console.log("Invalidate !", toInvalidate)
          for await (const key of toInvalidate) {
            await queryClient.invalidateQueries({ queryKey: key })
          }
          return true
        }
      })
      onClose()
      setSelectedEquipments(undefined)
      onReservationSuccess && onReservationSuccess(equipmentId, missionId)
      toast({ title: 'Réservation effectuée', status: 'success', isClosable: true, duration: 2000 })
    } catch (error) {
      console.log("onError", { error })
      let errorMessage = error.message || error.error
      if (!errorMessage) {
        errorMessage = [...Object.values(error)].join(", ")
      }
      toast({ title: "Erreur lors de la réservation", description: errorMessage, status: 'error', isClosable: true, duration: 5000 })
    }
  }

  return (<>
    {selectedEquipments && (
    <VehiculeReservationBoxModal
      equipment={equipment}
      equipmentId={selectedEquipments}
      onTakeout={onTakeout}
      queryParams={queryParams}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    />)}
    <Button
      isDisabled={isDisabled || isLoading || actionPending}
      onClick={() => onTakeoutSelected(equipmentId)}
      leftIcon={<TbTruck />}
      data-button="reserve-vehicule"
      {...props}
    >{buttonLabel}</Button>
  </>)
}