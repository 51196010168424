
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { ReservationsList } from './ReservationsList';
import { Button } from '@chakra-ui/react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { ridesNewRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import { useEffect, useMemo } from 'react';
import QueryString from 'qs';

export const ReservationsPage = () => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: user } = useUser();
  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  useEffect(() => {
    if (!queryParams.structure_id && (user?.meta?.user_structures || []).length > 0) {
      const structure_id = (user?.meta?.user_structures || [])[0]?.structure_id
      setSearchParams(QueryString.stringify({ ...queryParams, structure_id }))
    }
  }, [queryParams, setSearchParams, user?.meta?.user_structures])

  return (
    <PageWithTitle
      title="Réservations"
      actions={[
        // ability?.can('create', 'ride') && <Button key="new" size={'sm'} as={Link} variant="primary" to={ridesNewRoute(orgId)}>Créer</Button>
      ]}
    >
      <ReservationsList />
    </PageWithTitle>
  );
}

export default ReservationsPage;