import { EquipmentActionMetadata } from "@/screens/EquipmentActionButton/Action/Modal";
import { ROUTES } from "../constants/routes";
import { EquipmentAttributes, CreateEquipmentAttributes, EquipmentAttributesMeta, EquipmentEvent, EditEquipmentAttributes } from "../interfaces/equipment";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, formDataHeaders, handleApiResponse } from "../utils/api";
import qs from "qs";
import axios from "axios";


export const getOrganizationEquipmentsKey = "OrganizationEquipments"
export const getOrganizationVehiculesKey = "OrganizationVehicules"
export const getStructureEquipmentsKey = "StructureEquipments"
export const getEquipmentsSuggestedForMissionKey = "getEquipmentsSuggestedForMission"
export const getEquipmentKey = "Equipment"
export const getMissionEquipmentKey = "MissionEquipment"

export async function getOrganizationEquipments(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentAttributes, any, EquipmentAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipments.list(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getOrganizationVehicules(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentAttributes, any, EquipmentAttributesMeta>> {
  return await getOrganizationEquipments(organizationId, page, {...filters, categories: ['vehicule']})
}

export async function getStructureEquipments(structureId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentAttributes, any, EquipmentAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, {  });

  const response = await fetch(
    ROUTES.structures.equipmentsList(structureId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getEquipment(equipmentId: RecordId): Promise<ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>> {
  const response = await fetch(
    ROUTES.equipments.get(equipmentId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export function getEquipmentQuery(equipmentId: RecordId) {
  return {
    queryKey: [getEquipmentKey, `${equipmentId}`],
    queryFn: () => getEquipment(equipmentId)
  }
}


export async function createEquipment(organizationId: RecordId, equipment: CreateEquipmentAttributes): Promise<ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>> {
  const response = await fetch(
    ROUTES.equipments.create(organizationId), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ equipment }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateEquipment(equipmentId: RecordId, equipment: EditEquipmentAttributes): Promise<ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>> {
  const response = await fetch(
    ROUTES.equipments.update(`${equipmentId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
      body: JSON.stringify({ equipment }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateEquipmentFromFormData(equipmentId: RecordId, body: EditEquipmentAttributes): Promise<ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>> {
  // const response = await fetch(
  //   ROUTES.equipments.update(`${equipmentId}`), {
  //     headers: defaultAuthHeaders(),
  //     method: 'PATCH',
  //     body: JSON.stringify({ equipment }),
  //   }
  // )

  const response = await axios.patch(
    ROUTES.equipments.update(`${equipmentId}`),
    { equipment: body },
    {
      headers: {
        ...formDataHeaders(),
        'Content-Type': 'multipart/form-data'
      }
    }
  );  
  return response.data;
}

export async function deleteEquipment(equipmentId: RecordId): Promise<ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>> {

  const response = await fetch(
    ROUTES.equipments.delete(`${equipmentId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}

export async function actionOnEquipment(equipmentId: RecordId, action: EquipmentEvent, meta: EquipmentActionMetadata): Promise<ApiResourceResponse<EquipmentAttributesMeta>> {
  const response = await fetch(
    ROUTES.equipments.action(`${equipmentId}`), {
    headers: defaultAuthHeaders(),
    method: 'PATCH',
    body: JSON.stringify({ payload: { action, meta } })
  })
  return await handleApiResponse(response);
}
