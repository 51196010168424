
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";
import React from 'react';

import { loader as structureShowLoader } from "./pages/Structures/StructureShow";
import { loader as missionShowLoader } from "./pages/Missions/MissionShow";
import { loader as missionEditLoader } from "./pages/Missions/MissionEdit";
import { loader as missionSummaryLoader } from "./pages/Missions/MissionSummary";
import { loader as equipmentLoader } from "./pages/Equipments/EquipmentShow";
import { loader as equipmentEditLoader } from "./pages/Equipments/EquipmentEdit";
import { loader as equipmentSummaryLoader } from "./pages/Equipments/EquipmentSummary";
import { loader as equipmentReservationLoader } from "./pages/Vehicules/VehiculeCalendar";
import { loader as TakeoutStartLoader } from "./pages/Takeout/TakeoutStart";
import { loader as TakeoutEndLoader } from "./pages/Takeout/TakeoutEnd";
import { loader as rideShowLoader } from "./pages/Rides/RidesShow";
import * as Sentry from "@sentry/react";

import { EmptyLayout } from "./components/Layout/EmptyLayout";
import { Layout } from "./components/Layout/Layout";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Auth } from "./screens/Auth/Auth";
import { NotFound } from "./components/NotFound/NotFound";
import { getUserProfile } from "./api/user";
import { QueryClient } from "@tanstack/react-query";
import { ResetPassword } from "./screens/Auth/ResetPassword";
import { LOCALSTORAGE_REDIRECT_KEY } from "./constants/routes";
import { getPathFromRequest } from "./utils/route";
import { MobileLayout } from "./components/Layout/MobileLayout";
import ReservationsSearch from "./pages/Reservations/ReservationsSearch";
import AllVehiculesPage from "./pages/Vehicules/AllVehiculesPage";
import VehiculeShowPage from "./pages/Vehicules/VehiculeShowPage";
import VehiculeSummary from "./pages/Vehicules/VehiculeSummary";
import { Magic } from "./screens/Auth/Magic";
import UserShowPage from "./pages/Account/User/UserShow";

import OrganizationPage from "./pages/Organization/Organization"
import StructuresPage from "./pages/Structures/Structures"
import UserEditPage from "./pages/Account/User/UserEdit"
import SettingsLayoutPage from "./pages/Settings/Layout/Layout"
import GeneralSettingsPage from "./pages/Settings/General/General"
import NewEquipment from "./pages/Equipments/NewEquipment"
import NewMission from "./pages/Missions/NewMission"
import StructurePage from "./pages/Structures/StructureShow"
import StructureEquipmentsPage from "./pages/Equipments/StructureEquipments"
import AllEquipmentsPage from "./pages/Equipments/AllEquipments"
import AllMissionsPage from "./pages/Missions/AllMissions"
import StructureMissionsPage from "./pages/Missions/StructureMissions"
import MissionPage from "./pages/Missions/MissionShow"
import MissionEdit from "./pages/Missions/MissionEdit"
import MissionSummary from "./pages/Missions/MissionSummary"
import EquipmentPage from "./pages/Equipments/EquipmentShow"
import EquipmentEdit from "./pages/Equipments/EquipmentEdit"
import StructureSummary from "./pages/Structures/StructureSummary"
import EquipmentSummary from "./pages/Equipments/EquipmentSummary"
import VehiculeCalendar from "./pages/Vehicules/VehiculeCalendar"
import AllEquipmentTypesPage from "./pages/EquipmentTypes/AllEquipmentTypes"
import NewEquipmentType from "./pages/EquipmentTypes/NewEquipmentType"
import EquipmentTypePage from "./pages/EquipmentTypes/EquipmentTypeShow"
import EquipmentTypeEdit from "./pages/EquipmentTypes/EquipmentTypeEdit"
import InviteEdit from "./pages/Invite/Edit"
import StructureUsersPage from "./pages/Users/StructureUsers"
import StructurePlanning from "./pages/Structures/StructurePlanning"
import TakeoutEquipmentPage from "./pages/Takeout/EquipmentShow"
import TakeoutEquipmentSummary from "./pages/Takeout/EquipmentSummary"
import RidesPage from "./pages/Rides/RidesPage"
import TakeoutStart from "./pages/Takeout/TakeoutStart"
import TakeoutEnd from "./pages/Takeout/TakeoutEnd"
import RidePage from "./pages/Rides/RidesShow"
import RideSummary from "./pages/Rides/RideSummary"
import ReservationsPage from "./pages/Reservations/ReservationsPage"
import ReservationsList from "./pages/Reservations/ReservationsList"
import HelpPage from "./pages/Help/HelpPage";

const userRedirectLoader = async ({ request }: { request: Request }) => {

  try {
    const { data } = await getUserProfile();
    if (!data) {
      // console.log(`Stored ${getPathFromRequest(request)} for redirect`)
      localStorage.setItem(LOCALSTORAGE_REDIRECT_KEY, getPathFromRequest(request))
      return redirect("/login");
    }
    Sentry.setUser({ email: data?.attributes?.email, id: data?.attributes?.id });

  } catch (error) {
    // console.log(`Stored ${getPathFromRequest(request)} for redirect`)
    localStorage.setItem(LOCALSTORAGE_REDIRECT_KEY, getPathFromRequest(request))
    console.error(error)
    return redirect("/login");
  }
  return null
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = (queryClient: QueryClient) => sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route element={<EmptyLayout />}>
        <Route
          path="orgs"
          element={<Layout />}
          errorElement={<ErrorBoundary />}
          loader={userRedirectLoader}
        >
          <Route path=":orgId" element={<OrganizationPage />}>
            <Route path="rides">
              <Route path=":rideId" id="ride" loader={rideShowLoader(queryClient)} element={<RidePage />} errorElement={<ErrorBoundary />}>
                <Route path="summary" element={<RideSummary />} />
                <Route index element={<Navigate to={'summary'} />} />
              </Route>
              <Route index element={<RidesPage />} />
            </Route>


            <Route path="structures" errorElement={<ErrorBoundary />}>
              <Route path=":structureId" id="structure" loader={structureShowLoader(queryClient)} element={<StructurePage />} errorElement={<ErrorBoundary />}>
                <Route path="equipments">
                  <Route index element={<StructureEquipmentsPage />} />
                </Route>

                <Route path="missions">
                  <Route index element={<StructureMissionsPage />} />
                </Route>
                <Route path="users">
                  <Route index element={<StructureUsersPage />} />
                </Route>
                <Route path="summary" element={<StructureSummary />} />
                <Route path="planning" element={<StructurePlanning />} />
                <Route path="reservations" element={<ReservationsList />} />
                <Route index element={<Navigate to={'summary'} />} />
              </Route>
              <Route index element={<StructuresPage />} />
            </Route>
            
            <Route path="missions" errorElement={<ErrorBoundary />}>
              <Route path="new" element={<NewMission />} />
              <Route path=":missionId" id="mission-show" loader={missionShowLoader(queryClient)} element={<MissionPage />}>
                <Route path="edit" loader={missionEditLoader(queryClient)} element={<MissionEdit />} />
                {/* <Route path="equipments" loader={missionEquipmentLoader(queryClient)} element={<MissionEquipment />} /> */}
                <Route path="summary" loader={missionSummaryLoader(queryClient)} element={<MissionSummary />} />
                <Route index element={<Navigate to={'summary'} />} />
              </Route>

              <Route index element={<AllMissionsPage />} />
            </Route>

            <Route path="equipments" errorElement={<ErrorBoundary />}>
              <Route path="new" element={<NewEquipment />} />
              <Route path=":equipmentId" id="equipment-show" loader={equipmentLoader(queryClient)} element={<EquipmentPage />} errorElement={<ErrorBoundary />}>
                <Route path="edit" loader={equipmentEditLoader(queryClient)} element={<EquipmentEdit />} />
                {/* <Route path="equipments" loader={equipmentEquipmentLoader(queryClient)} element={<MissionEquipment />} /> */}
                <Route path="summary" loader={equipmentSummaryLoader(queryClient)} element={<EquipmentSummary />} />
                <Route path="calendar" loader={equipmentReservationLoader(queryClient)} element={<VehiculeCalendar />} />
                <Route index element={<Navigate to={'summary'} />} />
              </Route>
              
              <Route index element={<AllEquipmentsPage />} />
            </Route>

            <Route path="vehicules" errorElement={<ErrorBoundary />}>
              <Route path=":equipmentId" id="vehicule-show" loader={equipmentLoader(queryClient)} element={<VehiculeShowPage />} errorElement={<ErrorBoundary />}>
                <Route path="summary" loader={equipmentSummaryLoader(queryClient)} element={<VehiculeSummary />} />
                <Route path="calendar" loader={equipmentReservationLoader(queryClient)} element={<VehiculeCalendar />} />

                <Route index element={<Navigate to={'summary'} />} />
              </Route>

              <Route index element={<AllVehiculesPage />} />
            </Route>

            <Route path="reservations" element={<ReservationsPage />} />
            <Route path="search" element={<ReservationsSearch />} />

            <Route path="account">
              <Route path="edit" element={<UserEditPage />} />
              <Route index element={<UserShowPage />} />
            </Route>

            <Route path="help">
              <Route index element={<HelpPage />} />
            </Route>

            <Route path="settings" element={<SettingsLayoutPage />} errorElement={<ErrorBoundary />}>
              <Route path="general" element={<GeneralSettingsPage />} />
              <Route path="equipmentTypes">
                <Route path="new" element={<NewEquipmentType />} />
                <Route path=":equipmentTypeId" element={<EquipmentTypePage />}>
                  <Route path="edit" element={<EquipmentTypeEdit />} />
                </Route>

                <Route index element={<AllEquipmentTypesPage />} />
              </Route>
              <Route index element={<Navigate to={'general'} />} />
            </Route>

            <Route index element={<Navigate to={'vehicules'} />} />
          </Route>
        </Route>

        {/* /go/o/1/e/1 */}
        <Route
          path="go"
          element={<MobileLayout />}
          errorElement={<ErrorBoundary />}
          loader={userRedirectLoader}
        >
          <Route path="o" errorElement={<ErrorBoundary />}>
            <Route path=":orgId" element={<OrganizationPage />}>
              <Route path="e" errorElement={<ErrorBoundary />}>
                <Route path=":equipmentId" id="takeout-equipment" loader={equipmentLoader(queryClient)} element={<TakeoutEquipmentPage />} errorElement={<ErrorBoundary />}>

                  <Route path="m">
                    <Route path=":missionId">
                      <Route path="start" loader={TakeoutStartLoader(queryClient)} element={<TakeoutStart />} />
                      <Route path="r">
                        <Route path=":rideId">
                          <Route path="finish" loader={TakeoutEndLoader(queryClient)} element={<TakeoutEnd />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="summary" loader={equipmentSummaryLoader(queryClient)} element={<TakeoutEquipmentSummary />} />
                  <Route index element={<Navigate to={'summary'} />} />
                </Route>

              </Route>
            </Route>
          </Route>

        </Route>

        <Route path="/login" element={<Auth />} />
        <Route path="/password/edit" element={<ResetPassword />} />
        <Route path="/magic/:token" element={<Magic />} />
        <Route path="invitation">
          <Route path="accept" element={<InviteEdit />}>
          </Route>
        </Route>

        <Route index element={<Navigate to={'orgs'} />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
)