import { Button, ButtonGroup, ButtonGroupProps, ButtonProps, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { TbCalendar, TbChevronDown, TbTruck } from "react-icons/tb"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getEquipmentHistoriesKey } from "@/api/equipmentHistory"
import { actionOnEquipment, getEquipmentQuery } from "@/api/equipment"
import { RecordId } from "@/interfaces/id"
import { EquipmentActionMetadata, EquipmentActionModal } from "../Action/Modal"
import { EquipmentEvent } from "@/interfaces/equipment"
import { EVENT_LABELS } from "@/utils/states"


export interface EquipmentActionButtonProps extends ButtonGroupProps {
  equipmentIds: RecordId[],
  isLoading?: boolean,
  mainButtonProps?: ButtonProps
}

export const EquipmentActionButton = ({
  equipmentIds,
  isLoading = false,
  isDisabled,
  mainButtonProps,
  ...props
}: EquipmentActionButtonProps) => {

  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedAction, setSelectedAction] = useState<EquipmentEvent>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onActionSelected = (action: EquipmentEvent) => {
    setSelectedAction(action)
    onOpen()
  }

  const allowed_transitions: EquipmentEvent[] = [
    "move",
    "good",
    "to_review",
    "expire",
    "mark_reformed",
    "mark_bad",
    "review",
    "to_mission",
    "loose",
    "retreive",
    "return"
  ]

  const { data, mutate: mutateAction, isPending: actionPending } = useMutation({
    mutationFn: (payload: { equipmentId: RecordId, action: EquipmentEvent, meta: EquipmentActionMetadata }) => actionOnEquipment(payload.equipmentId, payload.action, payload.meta)
  })

  const onAction = async (action: EquipmentEvent, meta: EquipmentActionMetadata) => {
    console.log({ equipmentIds, meta })
    const updates = Promise.all(equipmentIds.map((equipmentId) => {
      return mutateAction({ equipmentId, action, meta }, {
        onSettled: async () => {
          const toInvalidate = [
            getEquipmentQuery(equipmentId).queryKey,
            [getEquipmentHistoriesKey, equipmentId],
          ]

          console.log("Invalidate !", toInvalidate)
          for await (const key of toInvalidate) {
            await queryClient.invalidateQueries({ queryKey: key })
          }
          return true
        }
      })

    }))

    try {
      await updates
      onClose()
      setSelectedAction(undefined)
      toast({ title: 'Action effectuée', status: 'success', isClosable: true, duration: 2000 })
    } catch (error) {
      console.log("onError", { error })
      toast({ title: "Erreur lors de l'action'", description: (error.message || error.error), status: 'error', isClosable: true, duration: 5000 })
    }
  }

  return (
    <ButtonGroup isAttached isDisabled={isDisabled || actionPending} variant='outline' {...props}>
      {selectedAction && <EquipmentActionModal action={selectedAction} onAction={onAction} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
      <Menu>
        <MenuButton {...mainButtonProps} isDisabled={isDisabled || mainButtonProps?.isDisabled || isLoading || actionPending} as={Button} aria-label="Actions" rightIcon={<TbChevronDown />}>
          Actions
        </MenuButton>
        <MenuList>
          {allowed_transitions.map(ac => {
            const { label, icon: OtherIcon, color } = EVENT_LABELS[ac]

            return (<MenuItem onClick={() => onActionSelected(ac)} key={ac} icon={<OtherIcon />}>{label}</MenuItem>)
          })}
        </MenuList>
      </Menu>
    </ButtonGroup>)
}