
import { Button, Stack, VStack, Text } from "@chakra-ui/react"
import { useNavigate, useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  const error = useRouteError() as any;
  console.error(error);

  const navigate = useNavigate();

  return error ? (
    <Stack justifyContent="center" height="50vh" p={6} alignContent={'center'}>
      <VStack justifyContent="center" p={6} alignContent={'center'}>
        <Text>{error?.message}</Text>
        <Text>{error?.error}</Text>
        <Button onClick={() => navigate(-1)}>← Retour</Button>
      </VStack>
    </Stack>
  ) : <></>
}


export default ErrorBoundary