import { mode, Styles } from "@chakra-ui/theme-tools"
import { theme as baseTheme } from '@chakra-ui/theme'

const styles: Styles = {
  ...baseTheme.styles,
  global: (props) => ({
    body: {
      // backgroundColor: mode('gray.50', 'gray.800'),
    },
    "*::placeholder": {
    },
    "*, *::before, &::after": {
    },
  }),
}

export default styles.global
