

import {
  TagProps,
  Tag,
} from '@chakra-ui/react'

import { parseISO } from 'date-fns';

export interface StatusTagForDatesProps extends TagProps {
  beginAt: string
  endAt?: string
}


const colorForStatus = (startInThePast: boolean, endInTheFuture: boolean) => {
  if (startInThePast && endInTheFuture) {
    return { color: 'blue', label: "En cours" }
  } else if (startInThePast && !endInTheFuture) {
    return { color: 'green', label: "Terminé" }
  } else {
    return { color: 'gray', label: "À venir" }
  }
}

export const StatusTagForDates = ({ beginAt, endAt, ...props }: StatusTagForDatesProps) => {
  const startInThePast = parseISO(beginAt) < new Date()
  const endInTheFuture = endAt && parseISO(endAt) > new Date() || false

  const { color, label } = colorForStatus(startInThePast, endInTheFuture)

  return <Tag colorScheme={color} {...props}>{label}</Tag>
}

