
import { deleteEquipmentHistory, getEquipmentHistoriesForStructure, getEquipmentHistoriesForStructureKey } from '@/api/equipmentHistory';
import { getStructure, getStructureKey } from '@/api/structure';
import { useNanuqQuery } from '@/hooks/query';
import { EquipmentHistoryAttributes } from '@/interfaces/equipmentHistory';
import { RecordId } from '@/interfaces/id';
import { EquipmentHistoryTable } from '@/screens/EquipmentHistoryTable/EquipmentHistoryTable';
import { StructureMapPreview } from '@/screens/StructureMapPreview/StructureMapPreview';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { Box, HStack, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { useParams } from 'react-router-dom';


export const StructureSummary = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const { data: structure } = useNanuqQuery([getStructureKey, structureId], () => structureId ? getStructure(structureId) : null)


  const historyQueryKey = [getEquipmentHistoriesForStructureKey, structureId, pageIndex]

  const { data: history, refetch: refetchHistory } = useNanuqQuery(
    historyQueryKey,
    () => structureId ? getEquipmentHistoriesForStructure(
      structureId,
      pageIndex + 1,
    ) : null
  )
  const deleteEquipmentHistoryMutation = useOptimisticDeleteMutation<ApiResourceResponse<EquipmentHistoryAttributes>, RecordId>(queryClient, deleteEquipmentHistory, historyQueryKey)

  const onEdit = async (equipmentId: RecordId) => {

  }

  const onDelete = async (equipmentHistoryId: RecordId) => {
    console.log("Delete !", { equipmentHistoryId })
    deleteEquipmentHistoryMutation.mutate(equipmentHistoryId, {
      onSettled: () => refetchHistory()
    })
  }

  if (!structure) {
    return <></>
  }

  return (
      <Box px={3} py={3}>
      <HStack alignItems={{ base: 'stretch', md: 'flex-start' }} flexDirection={{ base: 'column', md: 'row' }} flex={1} spacing={6}>
          <VStack alignItems={'flex-start'}>
            <Text>{structure?.data?.attributes?.street}</Text>
            <Text>{structure?.data?.attributes?.zip} {structure?.data?.attributes?.city}</Text>

          </VStack>
          <VStack alignItems={'flex-start'} flex={1}>
            <StructureMapPreview base={structure.data} />
          </VStack>
        </HStack>
        {history && (
          <Box bg="bg.surface">
            <Box px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
              <Stack spacing={3}>
                <Text color={'fg.muted'}>Activité</Text>
                <EquipmentHistoryTable
                  withEquipmentName
                  data={history}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onPaginationChange={setPagination}
                />
              </Stack>
            </Box>
        </Box>
        )}

      </Box>
  );
}

export default StructureSummary;