// import "instantsearch.css/themes/algolia-min.css";

import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';

import { TbFilter } from 'react-icons/tb';
import { useMemo } from 'react';
import { VehiculesListQueryParams } from './VehiculesList';
import ReservationSearchForm from '@/screens/ReservationSearchForm/ReservationSearchForm';
import { vehiculesRoute } from '@/constants/routes';
import QueryString from 'qs';
import { useNavigate, useParams } from 'react-router-dom';


export interface VehiculesListFiltersModalProps {
  params?: VehiculesListQueryParams
  title?: string
}
export const VehiculesListFiltersModal = ({
  params,
  title = "Filtrer"
}: VehiculesListFiltersModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { orgId } = useParams()

  const paramsLength = useMemo(() => params ? Object.keys(params).length : 0, [params])

  const navigate = useNavigate()

  const onSearch = (params: VehiculesListQueryParams) => {
    console.log(params)
    onClose()
    navigate(vehiculesRoute(orgId) + '?' + QueryString.stringify(params))
  }
  

  const onReset = () => {
    onClose()
    navigate(vehiculesRoute(orgId))
  }
  
  return (
    <>
      <Button size={'sm'} leftIcon={<TbFilter />} onClick={onOpen}>{title} {paramsLength ? `(${paramsLength})` : ''}</Button>

      <Modal size={{base: 'full', lg: 'lg'}} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReservationSearchForm params={params} onSearch={onSearch} onReset={onReset}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
