import { OnChangeFn, PaginationState } from "@tanstack/react-table"
import { ApiCollectionResponse } from "./api"


export function getPaginationHandler<T, Y, M>(
  data: ApiCollectionResponse<T, Y, M>,
  onPaginationChange?: (page: number) => void
) {

  const handlePagination: OnChangeFn<PaginationState> = (updater) => {
    let newValue: PaginationState = { pageIndex: data?.meta?.page ? data?.meta?.page - 1 : 0, pageSize: data?.meta?.items ?? 10 }
    if (typeof updater == 'function') {
      newValue = updater(newValue)
    } else {
      newValue = updater
    }
    console.log('[handlePagination]', { newValue, updater })
    onPaginationChange && onPaginationChange(newValue.pageIndex + 1)
  }

  return handlePagination
}