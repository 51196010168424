
import { Field, FieldProps, Form, Formik } from 'formik';


import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { useLogin, useUser } from '@/hooks/auth';
import { useNanuqQuery, useSearchParamsParser } from '@/hooks/query';
import { acceptInvitation, AcceptInvitationPayloadValues, getInvitation, getUserInfoKey } from '@/api/user';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';


export const InviteEdit = () => {
  const login = useLogin();
  const us = useUser()
  const navigate = useNavigate()

  const params = useSearchParamsParser()
  const token = params.get("invitation_token")

  const initialValues: AcceptInvitationPayloadValues = {
    password: '',
    first_name: '',
    last_name: '',
  }

  const { mutate, mutateAsync, isPending, isSuccess, data: responseData, reset } = useMutation(
    { mutationFn: (data: AcceptInvitationPayloadValues) => acceptInvitation(token || "", data) }
  )

  const {
    data,
  } = useNanuqQuery(
    [getUserInfoKey, token],
    () => (token ? getInvitation(token) : null),
    { refetchInterval: false, refetchOnMount: false, refetchOnWindowFocus: false }
  )

  const formPart = (<Formik
    initialValues={initialValues}
    onSubmit={async (values, { resetForm, setErrors }) => {
      // console.log({ values, responseData })
      try {
        if (responseData === undefined && data?.data.attributes.email) {
          await mutateAsync(values);

          await login.mutateAsync({ user: { email: data?.data.attributes.email, password: values.password } });
          const loggedUser = await us.refetch()

          // console.log({ loggedUser })
          resetForm()

          if (loggedUser) {
            navigate("/")
          }
        }
      } catch (err: unknown) {
        console.error(err)
        // setErrors();
      }
    }}
  >
    {(props) => (
      <Form>
        <Stack spacing="6">
          <Stack spacing="5">
            <Field name='first_name'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.first_name && form.touched.first_name ? true : false}>
                  <FormLabel htmlFor='first_name'>Prénom</FormLabel>
                  <Input {...field} id='first_name' type='text' placeholder='Prénom' />
                  <FormErrorMessage>{form.errors.first_name as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='last_name'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.last_name && form.touched.last_name ? true : false}>
                  <FormLabel htmlFor='last_name'>Nom</FormLabel>
                  <Input {...field} id='last_name' type='text' placeholder='Nom' />
                  <FormErrorMessage>{form.errors.last_name as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='password'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.password && form.touched.password ? true : false}>
                  <FormLabel htmlFor='password'>Mot de passe</FormLabel>
                  <Input {...field} id='password' type='password' placeholder='Mot de passe' />
                  <FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>

          <Stack spacing="5">
            <Button
              variant="primary"
              isLoading={props.isSubmitting}
              disabled={responseData !== undefined}
              type='submit'
            >
              Rejoindre {data?.data?.meta?.organization_name}
            </Button>
          </Stack>
        </Stack>
      </Form>
    )}
  </Formik>
  )

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
              Rejoindre {data?.data?.meta?.organization_name}
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">{data ? `Vous avec été invité à rejoindre ${data.data.meta.organization_name} par ${data.data.meta.invited_by}` : "Chargement..."}</Text>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
          boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          {formPart}
        </Box>
      </Stack>
    </Container>
  );
}

export default InviteEdit;