// import "instantsearch.css/themes/algolia-min.css";

import { HStack, Tag, Text, VStack, useColorModeValue } from '@chakra-ui/react';

import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResource } from '@/utils/api';
import { useLazyImage } from '@/hooks/useLazyImage';
import { HiWrench } from 'react-icons/hi2';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { TbEye, TbGauge, TbPin } from 'react-icons/tb';
import StateBadge from '@/components/StateBadge/StateBadge';
import ValueWithLabel from '@/components/ValueWithLabel/ValueWithLabel';


export interface VehiculeDetailsProps {
  data: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>
}

export const VehiculeDetails = ({ data }: VehiculeDetailsProps) => {

  const { attributes: vehicule, meta } = data

  const { imageUrl, isImageLoaded } = useLazyImage(vehicule.image_url, vehicule.image_thumb_url)
  const color = useColorModeValue('black', 'white')

  return (
    <VStack spacing={6} flex={1} justifyContent={'flex-start'} align={'stretch'}>

      <VStack flexWrap={'wrap'} flexDir={['column', 'column', 'row']} spacing={3} flex={1} justifyContent={'center'} alignItems={'center'}>
        {(vehicule.manufacturer || vehicule.model) && <Text>{vehicule.manufacturer} {vehicule.model}</Text>}
        {vehicule.serial && <Text>
          <Tag
            borderColor={color}
            borderWidth={1}
            bgColor={'transparent'}
            color={color}
          >{vehicule.serial}</Tag>
        </Text>}
        <HStack flex={1} alignItems={'stretch'} justifyContent={['center', 'center', 'flex-start']}>
          {vehicule.seats && <Tag>{vehicule.seats} places</Tag>}
          {meta?.equipment_type.name && <Tag>{meta?.equipment_type.name}</Tag>}
          <StateBadge minW={'100px'} borderRadius={4} variant={'solid'} size='lg' state={vehicule?.current_state} />
        </HStack>
      </VStack>
      <VStack spacing={3} flex={1} justifyContent={'flex-start'} alignItems={['center', 'center', 'flex-start']}>
        <HStack spacing={6} flexWrap={'wrap'}>
          <ValueWithLabel label="Emplacement" icon={<TbPin />}>
            <Text>{meta?.structure.short_name}</Text>
          </ValueWithLabel>
          <ValueWithLabel label="Kilométrage" icon={<TbGauge />}>
            <Text>{meta?.last_ride?.km_after ? `${meta?.last_ride?.km_after} km` : 'inconnu'}</Text>
          </ValueWithLabel>
          <ValueWithLabel label="Révision" icon={<HiWrench />}>
            <Text>Inconnue</Text>
          </ValueWithLabel>
          <ValueWithLabel label="Vérification" icon={<TbEye />}>
            <Text>{parseAndFormatDateShort(vehicule.checked_at, 'Inconnu')}</Text>
          </ValueWithLabel>
        </HStack>
      </VStack>
    </VStack>
  );

}
