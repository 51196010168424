import { RidesListQueryParams } from "@/interfaces/queryParams"
import QueryString from "qs"

export const RIDE_USABLE_LABEL = {
  'yes': 'Bon',
  'partially': 'Pas bon',
  'bad': 'Inutilisable',
}


export const parseRideQueryParams = (searchParams: URLSearchParams): RidesListQueryParams => {

  const parsed = QueryString.parse(searchParams.toString()) as RidesListQueryParams

  
  return parsed
}
