import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Switch,
  IconButton,
  HStack,
  Box,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateMissionAttributes, MissionAttributes } from '@/interfaces/mission';
import { AsyncSelect, Option } from '@/components/AsyncSelect/AsyncSelect';
import { useNanuqQuery } from '@/hooks/query';
import { createMission } from '@/api/mission';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { DatePicker } from '@/components/DatePicker/DatePicker';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import { getOrganizationEquipmentTypes, getOrganizationEquipmentTypesKey } from '@/api/equipmentType';
import { TbSettingsPlus, TbSettingsX } from 'react-icons/tb';
import { Select, SingleValue } from 'chakra-react-select';
import { MISSION_TYPES_SELECT } from '@/constants/mission';
import { toLocalDateTimeFormat } from '@/utils/datesFormat';
import { zonedTimeToUtc } from 'date-fns-tz';

export interface MissionFormProps {
  mission?: Partial<MissionAttributes>,
  orgId: RecordId,
  onSave: (newMission: ApiResourceResponse<MissionAttributes>) => Promise<any>
}

const NewMissionForm = ({ mission, orgId, onSave }: MissionFormProps) => {

  const initialValues: CreateMissionAttributes = {
    name: mission?.name || '',
    begin_at: toLocalDateTimeFormat(mission?.begin_at ? parseISO(mission.begin_at) : startOfDay(new Date())),
    end_at: toLocalDateTimeFormat(mission?.end_at ? parseISO(mission.end_at) : endOfDay(new Date())),
    mission_type: mission?.mission_type || '',
    structure_id: mission?.structure_id || -1
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (missionPayload: CreateMissionAttributes) => createMission(orgId || '', missionPayload)
  })

  const onSubmit = async (
    values: CreateMissionAttributes,
    actions: FormikHelpers<CreateMissionAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newValues: CreateMissionAttributes = {
          ...values,
          begin_at: formatISO(zonedTimeToUtc(values.begin_at, 'Europe/Paris')),
          end_at: formatISO(zonedTimeToUtc(values.end_at, 'Europe/Paris'))
        }
        const newData = await mutateAsync(newValues);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  const structureQuery = useNanuqQuery([getOrganizationStructuresKey, orgId], () => getOrganizationStructures(orgId))
  const equipmentQuery = useNanuqQuery([getOrganizationEquipmentTypesKey, orgId], () => getOrganizationEquipmentTypes(orgId))

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="mission"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        <VStack spacing="3" alignItems={'flex-start'}>
          <Field name='name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>
                <FormLabel htmlFor='name'>Nom</FormLabel>
                <Input {...field} id='name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='mission_type'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.mission_type && form.touched.mission_type ? true : false}>
                <FormLabel htmlFor='mission_type'>Type</FormLabel>
                <Select
                  defaultValue={MISSION_TYPES_SELECT.find(e => e.value === field.value)}
                  isInvalid={form.errors.mission_type ? true : false}
                  placeholder={`Sélectionner`}
                  name={field.name}
                  options={MISSION_TYPES_SELECT}
                  value={MISSION_TYPES_SELECT.find(e => e.value === field.value)}
                  onChange={(opt) => form.setFieldValue(field.name, opt?.value)}
                />
                <FormErrorMessage>{form.errors.mission_type as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <HStack justifyContent={'space-between'}>
            <Field name='begin_at'>
              {({ field, form, ...other }: FieldProps) => (
                <FormControl isInvalid={form.errors.begin_at && form.touched.begin_at ? true : false}>
                  <FormLabel htmlFor='begin_at'>Date de début</FormLabel>
                  <input {...field} aria-label="De" type="datetime-local" />
                  <FormErrorMessage>{form.errors.begin_at as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='end_at'>
              {({ field, form, ...other }: FieldProps) => (
                <FormControl isInvalid={form.errors.end_at && form.touched.end_at ? true : false}>
                  <FormLabel htmlFor='end_at'>Date de fin</FormLabel>
                  <input {...field} aria-label="A" min={form.values.begin_at} type="datetime-local" />
                  <FormErrorMessage>{form.errors.end_at as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </HStack>
          <Field name='structure_id'>
            {(formProps: FieldProps) => (
              <AsyncSelect
                query={structureQuery}
                label="Structure"
                {...formProps}
              />
            )}
          </Field>
          {/* <FieldArray name="mission_equipment_requirements_attributes">
            {({ insert, remove, push }) => (
              <div>
                {(values.mission_equipment_requirements_attributes?.length || 0) > 0 &&
                  values.mission_equipment_requirements_attributes?.map((mer, index) => (
                    <Box bg={'gray.50'} p={3} my={3} key={index}>
                      <HStack>

                        <Field name={`mission_equipment_requirements_attributes.${index}.equipment_type_id`}>
                          {(formProps: FieldProps) => (
                            <AsyncSelect
                              query={equipmentQuery}
                              label="Type de matériel"
                              {...formProps}
                            />
                          )}
                        </Field><Field name={`mission_equipment_requirements_attributes.${index}.required`}>
                          {({ field, ...other }: FieldProps) => (
                            <FormControl>
                              <FormLabel
                                htmlFor={`mission_equipment_requirements_attributes.${index}.required`}
                              >Obligatoire</FormLabel>
                              <Switch {...field} />
                            </FormControl>
                          )}
                        </Field>
                        <Field name={`mission_equipment_requirements_attributes.${index}.count`}>
                          {({ field, ...other }: FieldProps) => (
                            <FormControl>
                              <FormLabel htmlFor={`mission_equipment_requirements_attributes.${index}.count`}>Quantité</FormLabel>
                              <Input type='number' min={0} {...field} />
                              <ErrorMessage
                                name={`mission_equipment_requirements_attributes.${index}.count`}
                                component={FormErrorMessage}
                                className="field-error"
                              />
                            </FormControl>
                          )}
                        </Field>
                        <IconButton
                          aria-label='Supprimer'
                          type="button"
                          variant="secondary"
                          icon={<TbSettingsX />}
                          onClick={() => remove(index)}
                        />
                      </HStack>
                    </Box>
                  ))}
                <Button
                  type="button"
                  variant="secondary"
                  leftIcon={<TbSettingsPlus />}
                  onClick={() => push({ name: '', email: '' })}
                >
                  Ajouter du matériel requis
                </Button>
              </div>
            )}
          </FieldArray> */}


          <Button
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.dirty}
            type='submit'
          >
            Créer
          </Button>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default NewMissionForm