import { RecordId } from "@/interfaces/id";
import { HStack, StackProps, VStack } from "@chakra-ui/react";
import { Option, OptionList } from "../AsyncSelect/AsyncSelect";
import { Select, SingleValue } from "chakra-react-select";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "@/hooks/auth";
import styles from "./StructureSelectHeader.module.css";


export interface StructureSelectHeaderProps extends StackProps {
  onStructureChange: (structureId: RecordId | undefined) => void,
  structureId: string
}

export function StructureSelectHeader({
  onStructureChange,
  structureId,
  ...stackProps
}: StructureSelectHeaderProps) {

  const { data: user, isLoading } = useUser()

  const { orgId } = useParams()
  const [currentValue, setCurrentValue] = useState<Option>()

  const options: OptionList = useMemo(() => {
    return (user?.meta.user_structures || []).map(o => ({ label: o.structure.name, value: `${o.structure_id}` })) || []
  }, [user])

  const defaultValue = (options: OptionList, value: string) => {
    return options ? options.find(option => option.value === value?.toString()) : { label: `${value} ?`, value }
  }

  useEffect(() => {
    setCurrentValue(defaultValue(options, structureId))
  }, [structureId, setCurrentValue, options])

  return (
    <VStack justifyContent={'stretch'} alignContent={'stretch'} {...stackProps}>
      <Select
        className={styles.structureSelect}
        isLoading={isLoading}
        defaultValue={defaultValue(options, structureId)}
        placeholder={`Sélectionner`}
        options={options}
        value={currentValue}
        onChange={
          (option: SingleValue<Option>) => onStructureChange(option?.value)
        }
      />
    </VStack>
  )
}