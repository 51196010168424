
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, HStack, VStack, useBreakpointValue } from '@chakra-ui/react';
import { EquipmentCalendar } from '@/components/EquipmentCalendar/EquipmentCalendar';
import { getMissionEquipmentsPlanningForStructure, getMissionEquipmentsPlanningForStructureKey, removeEquipmentFromMission } from '@/api/missionEquipment';
import { addDays, addHours, endOfMonth, endOfWeek, format, formatISO, parseISO, startOfMonth, startOfWeek } from 'date-fns';
import { useMemo, useState } from 'react';
import { formatMissionEquipmentsforMissionCalendar, parseEquipmentQueryParams } from '@/utils/equipment';
import { StructureSelectHeader } from '@/components/StructureSelectHeader/StructureSelectHeader';
import QueryString from 'qs';
import { RecordId } from '@/interfaces/id';
import MissionDetailForEquipmentModal from '@/screens/MissionDetailForEquipmentModal/MissionDetailForEquipmentModal';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { VehiculesListQueryParams } from '../Vehicules/VehiculesList';
import { NewReservationModal } from './NewReservationModal';
import { CalendarEvent } from '@schedule-x/calendar';


export const ReservationsList = () => {

  const { orgId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedEvent, setSelectedEvent] = useState<{ id: RecordId, mission_ids: RecordId[], equipment_id: RecordId } | null>(null)
  const [selectedRange, setSelectedRange] = useState<VehiculesListQueryParams | null>(null)

  const queryParams = useMemo(() => parseEquipmentQueryParams(searchParams), [searchParams])

  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })

  const [currentRange, setCurrentRange] = useState([
    queryParams.available_between?.start_date ? parseISO(queryParams.available_between?.start_date) : (new Date()),
    queryParams.available_between?.end_date ? parseISO(queryParams.available_between?.end_date) : addDays(new Date(), 7),
  ])


  const queryClient = useQueryClient();

  const planningKey = [getMissionEquipmentsPlanningForStructureKey, currentRange, queryParams.structure_id]

  const planning = useInfiniteQuery({
    queryKey: [getMissionEquipmentsPlanningForStructureKey, currentRange, queryParams.structure_id, 'infinite'],
    queryFn: ({ pageParam }) => queryParams.structure_id ?
      getMissionEquipmentsPlanningForStructure(queryParams.structure_id, pageParam, { by_period: { started_at: addDays(currentRange[0], -7), ended_at: addDays(currentRange[1], 7) } }) :
      null,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage?.meta?.next
  })


  const { mutate: deleteMe, isSuccess, data: deletedMe, reset } = useMutation({
    mutationFn: (missionEquipmentId: RecordId) => removeEquipmentFromMission(missionEquipmentId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: planningKey })
      await planning.refetch()
    }
  })

  const onStructureChange = (structure_id: RecordId | undefined) => {
    setSearchParams(QueryString.stringify({ ...queryParams, structure_id }))
  }

  const onSelectSlot = (date: string) => {
    const start = parseISO(date)
    const params: VehiculesListQueryParams = {
      available_between: { start_date: formatISO(start), end_date: formatISO(addHours(start, 12)) },
    }
    console.log("Slot:", { event, params })
    setSelectedRange(params)
  }

  const onSelectEvent = (event: CalendarEvent) => {
    console.log("onSelectEvent", event)
    setSelectedEvent(event as any)
  }

  const onRangeChange = ({ start, end }: { start: string, end: string }) => {
    setCurrentRange([parseISO(start), parseISO(end)])
    console.log('onRangeChange', { start, end }, { currentRange })
  }


  const onCancelEvent = (missionEquipmentId: RecordId) => {
    console.log("onCancelEvent", { missionEquipmentId })
    if (missionEquipmentId) {
      deleteMe(missionEquipmentId)
    }
    setSelectedEvent(null)
  }

  return (
    <Box>
      <Box mx={{ base: '0', md: '6' }} px={{ base: '3', md: '0' }} py={3}>
        <HStack>
          <StructureSelectHeader
            flex={1}
            structureId={`${queryParams.structure_id}`}
            onStructureChange={onStructureChange}
          />
        </HStack>
      </Box>
      <BoxSection px={0} bg={'bg.surface'}>
          <VStack spacing={3} alignItems={'stretch'}>
            <EquipmentCalendar
              query={planning}
              formatEvents={formatMissionEquipmentsforMissionCalendar}
              selectedDate={format(currentRange[0], 'yyyy-MM-dd HH:mm')}
              callbacks={{
                onEventClick: onSelectEvent,
                onRangeUpdate: onRangeChange,
                onDoubleClickDate: onSelectSlot,
                onDoubleClickDateTime: onSelectSlot,
              }}
            />
          </VStack>
      </BoxSection>
      <MissionDetailForEquipmentModal
        onClose={() => setSelectedEvent(null)}
        isOpen={selectedEvent !== null}
        onCancel={onCancelEvent}
        orgId={orgId}
        size={'xl'}
        equipmentId={selectedEvent?.equipment_id}
        missionIds={selectedEvent?.mission_ids}
      />
      <NewReservationModal
        onClose={() => setSelectedRange(null)}
        isOpen={selectedRange !== null}
        params={selectedRange}
      />
    </Box>
  );
}

export default ReservationsList;