import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Box,
} from '@chakra-ui/react'
import { inviteOrganizationsUsers, getOrganizationsUsersKey } from '@/api/organization';
import { Field, FieldProps, Form, Formik } from 'formik';
import { ApiResource, ApiErrorResponse } from '@/utils/api';

import { OrganizationsUserAttributes, CreateOrganizationsUserAttributes } from '@/interfaces/organization';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface InviteOrganizationsUserModalProps {
  isOpen: boolean
  onClose: () => void
  orgId: string,
  organizationsUser?: ApiResource<OrganizationsUserAttributes>,
}

const InviteOrganizationsUserModal = ({ orgId, isOpen, onClose, organizationsUser }: InviteOrganizationsUserModalProps) => {


  const queryClient = useQueryClient()

  const { mutate, isPending, isSuccess, data, reset, error } = useMutation(
    {
      mutationFn: (organizationsUser: CreateOrganizationsUserAttributes) => inviteOrganizationsUsers(orgId, organizationsUser),
      onSuccess: async () => {
        await queryClient.refetchQueries({ queryKey: [getOrganizationsUsersKey, orgId], stale: true })
      }
    }
  )

  const handleClose = async () => {
    await queryClient.refetchQueries({ queryKey: [getOrganizationsUsersKey, orgId], stale: true })

    reset()
    onClose()
  }

  const initialValues = {
    email: ' '
  }

  const formPart = (<Formik
    initialValues={initialValues}
    onSubmit={async (values, { resetForm }) => {
      try {
        await mutate(values);
        resetForm()
      } catch (err: unknown) {
        console.error(err)
        // setError(err);
      }
    }}
  >
    {(props) => (
      <Form>
        <ModalHeader>Invite a new user</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="6">
            <Stack spacing="5">
              <Field name='email'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email ? true : false}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input {...field} id='email' type='text' placeholder='email' />
                    <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
            {error && error as ApiErrorResponse ? (
              <Alert status='error'>
                <AlertIcon />
                An error occured: {(error as ApiErrorResponse).message || (error as ApiErrorResponse).exception || (error as ApiErrorResponse).error}
              </Alert>
            ) : (<Box />)}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={isPending}
            type='submit'
          >
            {isSuccess ? "Invite another" : "Send invitation"}
          </Button>
          <Button variant='ghost' onClick={handleClose}>
            {isSuccess ? "Close" : "Cancel"}
          </Button>
        </ModalFooter>
      </Form>
    )}
  </Formik>
  )

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          {formPart}
        </ModalContent>
      </Modal>
    </>
  )
}

export default InviteOrganizationsUserModal