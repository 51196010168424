import {
  Box,
  Table as ChakraTable,
  TableProps,
  Tbody,
  Td,
  Tr,
  Stack,
  Text,
} from '@chakra-ui/react'

import {
  flexRender,
  Table,
} from '@tanstack/react-table'
import { TablePagination } from '@/components/TablePagination/TablePagination'

export interface CommentsTableProps<T> extends TableProps {
  table: Table<T>
}

export function CommentsTable<T>({ table, ...props }: CommentsTableProps<T>) {

  return (
    <Stack spacing="5">
      {table.getRowModel().rows.length > 0 ? (
        <>
          {table.getPageCount() > 1 && <TablePagination table={table} />}
          <Box overflowX="auto">
            <ChakraTable variant={'unstyled'} size={'sm'} {...props}>
              <Tbody>
                {table.getRowModel().rows.map(row => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <Td p={0} borderColor={'transparent'} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </ChakraTable>
          </Box>
        </>) : (
        <Text color='gray.600' textAlign={'center'} py={0}></Text>
      )}
    </Stack>
  )
}
