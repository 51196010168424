
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { missionsNewRoute } from '@/constants/routes';
import { Button } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { MissionsList } from './MissionsList';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { useOrganizations } from '@/contexts/useOrganizationsContext';


export const AllMissionsPage = () => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()

  return (
    <PageWithTitle
      title="Missions"
      actions={[
        ability?.can('create', 'mission') && <Button key="new" size={'sm'} as={Link} variant="primary" to={missionsNewRoute(orgId)}>Créer</Button>
      ]}
    >

      <BoxSection px={0} py={0}>
        <MissionsList />
      </BoxSection>
    </PageWithTitle>
  );
}

export default AllMissionsPage;