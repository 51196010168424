
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Button, HStack, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Stack, Text, Textarea, VStack, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useState } from 'react';
import { RideAttributes } from '@/interfaces/ride';


export interface StepFourVehiculeCommentProps {
  equipment: EquipmentAttributes,
  currentState: TakeoutState,
  ride?: RideAttributes | null,
  onNext: (state: TakeoutState) => void
}

export const StepFourVehiculeComment = ({
  equipment,
  ride,
  currentState,
  onNext,
}: StepFourVehiculeCommentProps) => {

  const { equipmentId, missionId } = useParams()
  const { data: user } = useUser();
  const { ability, selectedOrganizationId } = useOrganizations()
  const [comment, setComment] = useState<string | undefined>(currentState.comment ?? ride?.comment)

  const onSubmit = () => {
    console.log("validate", comment)
    const newState = {
      ...currentState,
      comment: comment
    }
    onNext(newState)
  }

  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={6}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius={4}
        borderColor={'bg.accent'}
        borderWidth={1}
        borderBottomRadius={4}
        textAlign={'left'}
      >
        <VStack spacing={3} flex={1} alignItems={'stretch'}>
          <Text>Avez vous un commentaire supplémentaire à ajouter ?</Text>
          <VStack flex={1} alignItems={'stretch'} justifyContent={'flex-start'}>
            <Textarea placeholder='Commentaire additionel' value={comment} onChange={(e) => setComment(e.target.value)}></Textarea>
          </VStack>
        </VStack>
      </Box>
      <Button colorScheme='brand' onClick={onSubmit}>Valider</Button>
    </VStack>
  );
}
