import {
  TableProps,
  Text,
  Avatar,
  HStack,
  VStack,
  Link,
  Tag,
} from '@chakra-ui/react'

import { EquipmentHistoryAttributes, EquipmentHistoryAttributesMeta } from '@/interfaces/equipmentHistory'
import { RecordId } from '@/interfaces/id'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { useOrganizations } from '@/contexts/useOrganizationsContext'
import { formatDateShort } from '@/utils/datesFormat'
import { parseISO } from 'date-fns'
import { FiTrash2 } from 'react-icons/fi'
import { Deletebutton } from '@/components/DeleteButton/DeleteButton'
import styles from './EquipmentHistoryRow.module.scss'
import { TbCirclePlus } from 'react-icons/tb'
import { ApiResource } from '@/utils/api'
import { equipmentRoute, equipmentSummaryRoute } from '@/constants/routes'


export interface EquipmentHistoryRowProps extends TableProps {
  history: ApiResource<EquipmentHistoryAttributes, EquipmentHistoryAttributesMeta>
  withEquipmentName?: boolean
  onDelete: (equipmentId: RecordId) => Promise<void>
  onEdit: (equipmentId: RecordId) => Promise<void>
}

export const EquipmentHistoryCommentRow = ({
  history,
  onDelete,
  onEdit,
  withEquipmentName = false
}: EquipmentHistoryRowProps) => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()
  const { attributes } = history


  return (<HStack spacing="3" alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'}>
    <VStack className={styles.historyTable} >

      {attributes.user ? (
        <Avatar className={styles.icon} name={attributes.user.full_name} src={attributes.user.image_url || ''} size={'xs'} />
      ) : <Text></Text>
      }
    </VStack>

    <VStack bg={'bg.subtle'} my={3} borderRadius={3} p={3} spacing="2" flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'}>
      <HStack spacing="1" flex={1} alignContent={'space-between'} justifyContent={'center'} alignItems={'center'}>
        <HStack spacing="1" flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'flex-start'}>
          <Text fontSize={'small'}><b>{attributes.user ? attributes.user.full_name : ''}</b>, </Text>
          <Text fontSize={'small'}>{formatDateShort(parseISO(attributes.updated_at))}</Text>
        </HStack>
        <HStack spacing="1" alignContent={'flex-end'} justifyContent={'flex-end'} alignItems={'flex-start'}>
          {/* {ability?.can('update', 'equipment_history', data) && (
            <IconButton variant={'link'} icon={<FiEdit2 />} onClick={() => onEdit(attributes.id)} aria-label="Edit" />
          )} */}
          {ability?.can('delete', 'equipment_history', attributes) && (
            <Deletebutton
              variant={'link'}
              leftIcon={<FiTrash2 />}
              onConfirm={() => onDelete(attributes.id)}
              title={`Supprimer la note`}
            ></Deletebutton>
          )}
        </HStack>
      </HStack>
      <HStack spacing="1" alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        {withEquipmentName && (
          <HStack spacing={1}>
            {/* <Text>{history.meta?.equipment_type?.name}</Text> */}
            <Link as={RouterLink} to={equipmentSummaryRoute(orgId, history.meta?.equipment?.id)}>
              <Tag size={'sm'}>{history.meta?.equipment_type?.name} {history.meta?.equipment?.name}</Tag>
            </Link>
          </HStack>
        )}

        <Text noOfLines={1}>{attributes.title || '-'}</Text>
        {attributes.description && <Text noOfLines={1} color="fg.muted">{' · '}{attributes.description}</Text>}
      </HStack>
    </VStack>
  </HStack>)
}

export const EquipmentHistoryEventRow = ({ history, onDelete, onEdit, withEquipmentName = false }: EquipmentHistoryRowProps) => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()
  const { attributes } = history


  return (<HStack spacing="3" alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'}>
    <VStack alignItems={'flex-end'} className={styles.historyTable}>
      <span className={styles.icon}>
        <TbCirclePlus />
      </span>
      {/* {attributes.user ? (
        <Avatar name={attributes.user.full_name} src={attributes.user.image_url || ''} size={'xs'} />
      ) : <Text></Text>} */}
    </VStack>

    <VStack borderRadius={3} my={2} px={3} spacing="2" flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'}>
      <HStack spacing="1" flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Text fontSize={'small'} color="fg.muted">{attributes.user ? attributes.user.full_name : ''}, </Text>
        <Text fontSize={'small'} color="fg.muted">{formatDateShort(parseISO(attributes.updated_at))}</Text>
      </HStack>
      <HStack spacing="1" flex={1} alignContent={'space-between'} justifyContent={'center'} alignItems={'center'}>
        <HStack spacing="1" flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'flex-start'}>
          {withEquipmentName && (
            <HStack spacing={1}>
              <Link as={RouterLink} to={equipmentSummaryRoute(orgId, history.meta?.equipment?.id)}>
                <Tag size={'sm'}><HStack><span>{history.meta?.equipment_type?.name}</span><b>{history.meta?.equipment?.name}</b></HStack></Tag>
              </Link>
            </HStack>
          )}
          <Text textTransform={'lowercase'} noOfLines={2}>{attributes.title || '-'}</Text>
          {attributes.description && <Text textTransform={'lowercase'} noOfLines={2} color="fg.muted">: {attributes.description}</Text>}
        </HStack>
      </HStack>
    </VStack>

  </HStack>)
}


export const EquipmentHistoryRow = ({ history, onDelete, onEdit, withEquipmentName = false }: EquipmentHistoryRowProps) => {
  const { attributes } = history

  switch (attributes?.event_type) {
    case 'user':
      return <EquipmentHistoryCommentRow withEquipmentName={withEquipmentName} history={history} onDelete={onDelete} onEdit={onEdit} />
    default:
      return <EquipmentHistoryEventRow withEquipmentName={withEquipmentName} history={history} onDelete={onDelete} onEdit={onEdit} />
  }
}
