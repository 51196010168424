import { Button, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { TbTruck } from "react-icons/tb"
import { EquipmentActionTakeoutModal } from "./Modal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getEquipmentHistoriesKey } from "@/api/equipmentHistory"
import { getEquipmentQuery } from "@/api/equipment"
import { RecordId } from "@/interfaces/id"
import { addEquipmentToMission } from "@/api/missionEquipment"
import { EquipmentAttributes } from "@/interfaces/equipment"


export interface EquipmentActionTakeoutButtonProps extends ButtonProps {
  equipmentId: RecordId,
  equipment?: EquipmentAttributes,
  isLoading?: boolean,
  onTakeoutSuccess?: (equipmentId: RecordId, missionId: RecordId) => void
}

export const EquipmentActionTakeoutButton = ({
  equipmentId,
  equipment,
  isLoading = false,
  isDisabled,
  onTakeoutSuccess,
  ...props
}: EquipmentActionTakeoutButtonProps) => {

  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedEquipments, setSelectedEquipments] = useState<RecordId>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onTakeoutSelected = (equipmentId: RecordId) => {
    setSelectedEquipments(equipmentId)
    onOpen()
  }

  const { data, mutateAsync: mutateTakeout, isPending: actionPending } = useMutation({
    mutationFn: (payload: { equipmentId: RecordId, missionId: RecordId }) => addEquipmentToMission(payload.equipmentId, payload.missionId)
  })

  const onTakeout = async (equipmentId: RecordId, missionId: RecordId) => {
    console.log({ equipmentId, missionId })

    try {
      await mutateTakeout({ equipmentId, missionId }, {
        onSettled: async () => {
          const toInvalidate = [
            getEquipmentQuery(equipmentId).queryKey,
            [getEquipmentHistoriesKey, equipmentId],
          ]

          console.log("Invalidate !", toInvalidate)
          for await (const key of toInvalidate) {
            await queryClient.invalidateQueries({ queryKey: key })
          }
          return true
        }
      })
      onClose()
      setSelectedEquipments(undefined)
      onTakeoutSuccess && onTakeoutSuccess(equipmentId, missionId)
      toast({ title: 'Réservation effectuée', status: 'success', isClosable: true, duration: 2000 })
    } catch (error) {
      console.log("onError", { error })
      let errorMessage = error.message || error.error
      if (!errorMessage) {
        errorMessage = [...Object.values(error)].join(", ")
      }
      toast({ title: "Erreur lors de la réservation", description: errorMessage, status: 'error', isClosable: true, duration: 5000 })
    }
  }

  return (<>
    {selectedEquipments && <EquipmentActionTakeoutModal equipment={equipment} equipmentId={selectedEquipments} onTakeout={onTakeout} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    <Button
      isDisabled={isDisabled || isLoading || actionPending}
      {...props}
      onClick={() => onTakeoutSelected(equipmentId)}
      leftIcon={<TbTruck />}
      data-button="reserve-and-go"
    >Réserver et partir</Button>
  </>)
}