
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams } from 'react-router-dom';
import { deleteEquipmentHistory, getEquipmentHistories, getEquipmentHistoriesKey } from '@/api/equipmentHistory';
import { EquipmentHistoryTable } from '@/screens/EquipmentHistoryTable/EquipmentHistoryTable';
import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import NewEquipmentHistoryForm from '@/screens/EquipmentHistoryForm/NewEquipmentHistoryForm';
import { EquipmentHistoryAttributes } from '@/interfaces/equipmentHistory';
import { EquipmentSidebar } from '@/screens/EquipmentSidebar/EquipmentSidebar';
import Loader from '@/components/Loader/Loader';
import { EquipmentInfoPanel } from '@/screens/EquipmentInfoPanel/EquipmentInfoPanel';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const EquipmentSummary = () => {

  const { orgId, equipmentId } = useParams()
  const queryClient = useQueryClient()

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { data: equipment } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  const historyQueryKey = [getEquipmentHistoriesKey, equipmentId, pageIndex]

  const { data: history, refetch: refetchHistory } = useNanuqQuery(
    historyQueryKey,
    () => equipmentId ? getEquipmentHistories(
      equipmentId,
      pageIndex + 1,
    ) : null
  )
  const deleteEquipmentHistoryMutation = useOptimisticDeleteMutation<ApiResourceResponse<EquipmentHistoryAttributes>, RecordId>(queryClient, deleteEquipmentHistory, historyQueryKey)


  const onComment = async (newEquipmentHistory: ApiResourceResponse<EquipmentHistoryAttributes>) => {
    refetchHistory()
  }

  const onEdit = async (equipmentId: RecordId) => {

  }

  const onDelete = async (equipmentHistoryId: RecordId) => {
    deleteEquipmentHistoryMutation.mutate(equipmentHistoryId, {
      onSettled: () => refetchHistory()
    })
  }

  if (!history) {
    return <></>
  }

  return (
    <Box px={{ base: 0, md: 3 }} py={3}>
      <HStack flexWrap={'wrap'} alignItems={'flex-start'} justifyContent={'space-between'} px={{ base: 3, md: 6 }} py={{ base: 3, md: 6 }}>
        {equipment?.data ? <EquipmentInfoPanel equipment={equipment.data} /> : <Loader />}
        <EquipmentSidebar equipment={equipment?.data} />
      </HStack>
      <Box bg="bg.surface">
        <Box px={{ base: 3, md: 6 }} py={{ base: '3', md: 6 }}>
          <Stack px={{ base: 3, md: 6 }} spacing={3}>
            <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Activité</Text>
            <EquipmentHistoryTable data={history} onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} />
            <NewEquipmentHistoryForm onSave={onComment} equipmentId={equipmentId!} />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default EquipmentSummary;