
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Box, Divider, HStack, Text, VStack, useBreakpointValue } from '@chakra-ui/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { TbCalendar, TbFlag } from 'react-icons/tb';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import { getMissionEquipmentsPlanningForEquipment, getMissionEquipmentsPlanningForEquipmentKey } from '@/api/missionEquipment';
import { MissionAsyncCard } from '@/screens/MissionAsyncCard/MissionAsyncCard';
import { VehiculeDetails } from '@/screens/VehiculeDetails/VehiculeDetails';
import ValueWithLabel from '@/components/ValueWithLabel/ValueWithLabel';
import { VehiculeReservationBoxButton } from '@/screens/EquipmentActionButton/VehiculeReservationBox/Button';
import EmptyList from '@/components/EmptyList/EmptyList';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const VehiculeSummary = () => {

  const { orgId, equipmentId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })

  const queryParams = parseEquipmentQueryParams(searchParams)
  const hasReservationDates = useMemo(() => queryParams.available_between?.start_date && queryParams.available_between?.end_date, [queryParams])

  const queryClient = useQueryClient()

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  const equipmentPlanningKey = [getMissionEquipmentsPlanningForEquipmentKey, equipmentId, queryParams]

  const { data: planning, refetch: refetchPlanning } = useNanuqQuery(
    equipmentPlanningKey,
    () => (equipmentId && hasReservationDates) ? getMissionEquipmentsPlanningForEquipment(
      equipmentId,
      pageIndex + 1,
      { by_period: { started_at: queryParams.available_between?.start_date, ended_at: queryParams.available_between?.end_date } }
    ) : null
  )

  const onReservation = () => {
    setSearchParams({})
  }

  const { data: equipment } = (data || {})
  const mobileBoxStyles = isMobile ? {
    m: 4,
    // mt: -12,
    spacing: 6,
    flex: 1,
    borderRadius: 9,
    justifyContent: 'center',
    border: '1px solid',
    borderColor: 'border.default',
    bg: 'bg.surface',
  } : {
  }

  return (
    <Box>
      <VStack
        textAlign={'center'}
        p={6}
        alignItems={'stretch'}
        {...mobileBoxStyles}
      >

        {equipment && <VehiculeDetails data={equipment} />}
      </VStack>

      {(hasReservationDates && equipment) && <VStack border={'1px solid'} bg={'bg.subtle'} borderColor={'border.default'} borderRadius={3} m={3} p={3} flex={1} alignItems={'stretch'}>
          <ValueWithLabel icon={<TbCalendar />} label="Votre demande de réservation">
            <VStack alignItems={'stretch'} width={'100%'}>
              <HStack spacing={1} flexWrap={'wrap'}>
                <Text>Du {parseAndFormatDateShort(queryParams.available_between?.start_date)}</Text>
                <Text>au {parseAndFormatDateShort(queryParams.available_between?.end_date)}</Text>
              </HStack>
              {planning?.data.length === 0 && (
                <HStack width={'100%'}>
                  <Text color='green'>Le véhicule est disponible !</Text>
                  <VehiculeReservationBoxButton
                    equipmentId={data?.data?.id || ''}
                    buttonLabel="Réserver le véhicule"
                    queryParams={queryParams}
                    equipment={data?.data.attributes}
                    variant={'outline'}
                    size={'sm'}
                    colorScheme='brand'
                    onReservationSuccess={onReservation}
                  />
                </HStack>
              )}
            {planning?.data.map(pla => <HStack width={'100%'}>
              <Text color='error'>Le véhicule est réservé !</Text>
            </HStack>)}
            </VStack>
          </ValueWithLabel>
        </VStack>}

      <Divider />
      <HStack
        flexWrap={'wrap'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        p={6}
      >
      {equipment?.meta && (
        <HStack flexDirection={['column', 'row']} spacing={6} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
          <ValueWithLabel icon={<TbFlag />} label="Mission en cours">
            <>
              {equipment.meta.active_missions.map(m => <MissionAsyncCard missionId={m.id} orgId={orgId} key={m.id} />)}
            </>

            <EmptyList list={equipment.meta.active_missions}>
              Aucune mission en cours
            </EmptyList>

          </ValueWithLabel>
          <ValueWithLabel icon={<TbFlag />} label="Prochaine mission">
            {equipment.meta.next_mission?.name ? (
              <MissionAsyncCard missionId={equipment.meta.next_mission.id} orgId={orgId} key={equipment.meta.next_mission.id} />
            ) : (
              <Text textAlign={'center'} color={'fg.muted'} fontSize='sm'>Aucune mission a venir</Text>
            )}
          </ValueWithLabel>
          </HStack>)}
      </HStack>
    </Box>
  );
}

export default VehiculeSummary;