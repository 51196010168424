import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton  } from '@chakra-ui/react'
import { getOrganizationsUsersKey } from '@/api/organization';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { RecordId } from '@/interfaces/id';
import { CreateMissionUserAttributes, MissionUserAttributes } from '@/interfaces/missionUser';
import { addUserToMission } from '@/api/missionUser';
import { getMissionKey } from '@/api/mission';
import { UserOrNewField } from '../UserOrNewField/UserOrNewField';
import { Form, Formik, FormikHelpers } from 'formik';

export interface AddMissionUserModalProps {
  isOpen: boolean
  onClose: () => void
  orgId: string,
  missionId: RecordId,
  missionUser?: MissionUserAttributes,
}

const AddMissionUserModal = ({ orgId, isOpen, onClose, missionId, missionUser }: AddMissionUserModalProps) => {


  const { ability } = useOrganizations()

  const queryClient = useQueryClient()

  const initialValues: CreateMissionUserAttributes = {
    mission_id: missionId || '',
    user_id: '',
  }

  const { mutate, isPending, isSuccess, data, reset, error } = useMutation(
    {
      mutationFn: (missionUser: CreateMissionUserAttributes) => addUserToMission(missionUser.user_id || '', missionId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [getMissionKey, missionId], stale: true })
      }
    }
  )

  const handleClose = async () => {
    await queryClient.refetchQueries({ queryKey: [getOrganizationsUsersKey, orgId], stale: true })

    reset()
    onClose()
  }

  // const onUserStructureUpdate = async (newUserStructure: ApiResourceResponse<UserStructureAttributes, unknown, unknown>) => {
  //   setTimeout(() => refetch(), 1000)
  //   await queryClient.invalidateQueries({ queryKey: [getUserUserStructuresKey, organizationsUser?.user_id], stale: true })

  // }

  const onSubmit = async (
    values: CreateMissionUserAttributes,
    actions: FormikHelpers<CreateMissionUserAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutate(values);
        actions.resetForm()
        handleClose()
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  return (
    <>
      {ability?.isOrgOwner() && <Modal size={'3xl'} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, ...props }) => (
              <Form>
                <ModalHeader>Ajouter un participant</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <UserOrNewField name={`user_id`} orgId={orgId} usedIds={[]} />
                </ModalBody>

                <ModalFooter>
                  <Button variant='primary' isDisabled={values.user_id == ''} type='submit'>Ajouter</Button>
                  <Button variant='ghost' onClick={handleClose}>Fermer</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>}
    </>
  )
}

export default AddMissionUserModal