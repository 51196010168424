
import { Badge, Button, HStack, Stack, StackDivider, Text } from '@chakra-ui/react';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import UserEditForm from '@/components/UserEditForm/UserEditForm';
import { useLogout, useUser } from '@/hooks/auth';
import { UserAttributes } from '@/interfaces/user';
import { RiLoginBoxLine } from 'react-icons/ri';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { accountRoute } from '@/constants/routes';
import { getStructureQuery } from '@/api/structure';
import { TbArrowLeft } from 'react-icons/tb';


export const UserEditPage = () => {
  const { data: user, refetch } = useUser();
  const logout = useLogout()
  const queryClient = useQueryClient();
  const navigate = useNavigate()
  const { orgId } = useParams()

  const onLogout = () => {
    logout.mutate({})
    navigate('/login')
  }

  // Then get the users messages
  const structures = useQueries({
    queries: user?.meta?.user_structures?.map(e => e.structure_id)
      ? user?.meta?.user_structures?.map(e => e.structure_id).map(getStructureQuery)
      : [], // if users is undefined, an empty array will be returned
  })

  const { mutateAsync, data } = useMutation({
    mutationFn: (userPayload: Partial<UserAttributes>) => updateUser(user?.attributes.id || '', userPayload),
  })

  const onUserChange = async (values: Partial<UserAttributes>) => {
    try {
      if (data === undefined) {
        await mutateAsync(values);
        await queryClient.invalidateQueries({ queryKey: ['authenticated-user'] })
        await refetch()
        navigate(accountRoute(orgId), { replace: true, state: "{updated: true}" })
      }
    } catch (err: unknown) {
      console.error(err)
      // setError(err);
    }
  }

  return (
    <PageWithTitle
      title="Modifier mes informations"
      actions={[
        // <Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem" />}>
        //     Download
        // </Button>,
        // <Button variant="primary" onClick={onCreateNewOpen}>Create</Button>
      ]}
    >

      <BoxSection py={6}>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Général
            </Text>
            <Text fontSize="sm" color="muted">
              Paramètres du compte
            </Text>
          </Stack>
          {user?.attributes && <UserEditForm user={user?.attributes} onSubmit={onUserChange} />}
          {/* <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Structures
            </Text>
            <Text fontSize="sm" color="muted">
              Vos droits par structure
            </Text>
          </Stack>

          <Stack spacing="1">

            {user?.meta.user_structures?.flatMap(us => {
              const structure = structures.find(e => e.data?.data?.id?.toString() === us.structure_id?.toString())?.data?.data
              return (
                <HStack key={`${us.structure_id}-${us.structure_function}-${us.role}`}>
                  <Text>{structure?.attributes.name}</Text>
                  <Text>{us?.structure_function}</Text>
                  <Text><Badge>{us?.role}</Badge></Text>
                </HStack>
              )
            })}
          </Stack> */}


          <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>

            <Button
              leftIcon={<TbArrowLeft />}
              variant="outline"
              w={'100%'}
              as={Link}
              to={accountRoute(orgId)}
            >
              Retour
            </Button>
          </HStack>

        </Stack>
      </BoxSection>
    </PageWithTitle>
  );
}

export default UserEditPage;