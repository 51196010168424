import { useEffect, useState } from "react"

export const useLazyImage = (src: string | null | undefined, placeholder: string | null = null) => {
  const [sourceLoaded, setSourceLoaded] = useState(placeholder)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (src) {
      const img = new Image()
      img.src = src
      img.onload = () => {
        setSourceLoaded(src)
        setTimeout(() => {
          setIsLoaded(true)
        }, 100)
      }
    }
  }, [src])

  return { imageUrl: sourceLoaded, isImageLoaded: isLoaded }
}
