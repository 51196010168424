import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Switch,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { EditEquipmentAttributes, EquipmentAttributes } from '@/interfaces/equipment';
import { AsyncSelect } from '@/components/AsyncSelect/AsyncSelect';
import { useNanuqQuery } from '@/hooks/query';
import { getOrganizationEquipmentTypes, getOrganizationEquipmentTypesKey } from '@/api/equipmentType';
import { createEquipment, updateEquipment, updateEquipmentFromFormData } from '@/api/equipment';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { ImageInput } from '@/components/ImageInput/ImageInput';

export interface EquipmentFormProps {
  equipment: EquipmentAttributes,
  orgId: RecordId,
  onSave: (newEquipment: ApiResourceResponse<EquipmentAttributes>) => Promise<any>
}

const EditEquipmentForm = ({ equipment, orgId, onSave }: EquipmentFormProps) => {

  const initialValues: EditEquipmentAttributes = {
    name: equipment?.name || '',
    identifier: equipment?.identifier || '',
    usable: equipment?.usable === undefined ? true : equipment?.usable,
    equipment_type_id: equipment?.equipment_type_id || 1,
    structure_id: equipment?.structure_id || -1,
    current_location_id: equipment?.current_location_id,
    current_location_type: equipment?.current_location_type,
    manufacturer: equipment?.manufacturer || '',
    model: equipment?.model || '',
    serial: equipment?.serial || '',
    seats: equipment?.seats || 0,
    height: equipment?.height || 0,
    width: equipment?.width || 0,
    constructed_at: equipment?.constructed_at || '',
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (equipmentPayload: EditEquipmentAttributes) => updateEquipmentFromFormData(equipment.id, equipmentPayload)
  })

  const onSubmit = async (
    values: EditEquipmentAttributes,
    actions: FormikHelpers<EditEquipmentAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  const equipmentQuery = useNanuqQuery([getOrganizationEquipmentTypesKey], () => getOrganizationEquipmentTypes(orgId))
  const structureQuery = useNanuqQuery([getOrganizationStructuresKey], () => getOrganizationStructures(orgId))

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {(props) => (
      <Form>
        <ErrorMessage
          name="equipment"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        <VStack spacing="3" alignItems={'flex-start'}>
          <Field name='name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>
                <FormLabel htmlFor='name'>Nom</FormLabel>
                <Input {...field} id='name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='identifier'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.identifier && form.touched.identifier ? true : false}>
                <FormLabel htmlFor='identifier'>Identifiant</FormLabel>
                <Input {...field} id='identifier' type='text' placeholder='Identifiant' />
                <FormErrorMessage>{form.errors.identifier as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='image'>
            {({ field, form, ...otherProps }: FieldProps) => (
              <FormControl isInvalid={form.errors.image && form.touched.image ? true : false}>
                <FormLabel htmlFor='image'>Image</FormLabel>
                <ImageInput {...otherProps} field={field} form={form} />
                {/* <Input {...field} id='image' capture="environment" accept="image/*" type='file' placeholder='Image' /> */}
                <FormErrorMessage>{form.errors.image as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='usable'>
            {({ field, form }: FieldProps) => {
              console.log(field)
              return (
              <FormControl isInvalid={form.errors.usable && form.touched.usable ? true : false}>
                <FormLabel htmlFor='usable'>Utilisable ?</FormLabel>
                <Switch {...field} isChecked={field.value ? true : undefined} id='usable' />
                <FormErrorMessage>{form.errors.usable as string}</FormErrorMessage>
              </FormControl>
            )}}
          </Field>
          <Field name='equipment_type_id'>
            {(formProps: FieldProps) => (
              <AsyncSelect
                query={equipmentQuery}
                label="Type de matériel"
                {...formProps}
              />
            )}
          </Field>

          <Field name='structure_id'>
            {(formProps: FieldProps) => (
              <AsyncSelect
                query={structureQuery}
                label="Structure"
                {...formProps}
              />
            )}
          </Field>
          <Field name='serial'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.serial && form.touched.serial ? true : false}>
                <FormLabel htmlFor='serial'>Immatriculation</FormLabel>
                <Input {...field} id='serial' type='text' placeholder='Immatriculation' />
                <FormErrorMessage>{form.errors.serial as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='height'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.height && form.touched.height ? true : false}>
                <FormLabel htmlFor='height'>Hauteur (m)</FormLabel>
                <Input {...field} id='height' type='number' placeholder='Hauteur (m)' />
                <FormErrorMessage>{form.errors.height as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='width'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.width && form.touched.width ? true : false}>
                <FormLabel htmlFor='width'>Largeur (m)</FormLabel>
                <Input {...field} id='width' type='number' placeholder='Largeur (m)' />
                <FormErrorMessage>{form.errors.width as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='seats'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.seats && form.touched.seats ? true : false}>
                <FormLabel htmlFor='seats'>Capacité (personnes)</FormLabel>
                <Input {...field} id='seats' type='number' placeholder='Capacité (personnes)' />
                <FormErrorMessage>{form.errors.seats as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='model'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.model && form.touched.model ? true : false}>
                <FormLabel htmlFor='model'>Modèle</FormLabel>
                <Input {...field} id='model' type='text' placeholder='Modèle' />
                <FormErrorMessage>{form.errors.model as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='manufacturer'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.manufacturer && form.touched.manufacturer ? true : false}>
                <FormLabel htmlFor='manufacturer'>Constructeur</FormLabel>
                <Input {...field} id='manufacturer' type='text' placeholder='Constructeur' />
                <FormErrorMessage>{form.errors.manufacturer as unknown as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>


          <Button
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.dirty}
            type='submit'
          >
            Mettre à jour
          </Button>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default EditEquipmentForm