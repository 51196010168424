import { ROUTES } from "../constants/routes";
import { StructureAttributes, CreateStructureAttributes, StructureAttributesMeta } from "../interfaces/structure";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import QueryString from "qs";


export const getOrganizationStructuresKey = "OrganizationStructures"
export const getStructureKey = "Structure"

export async function getOrganizationStructures(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<StructureAttributes, unknown, StructureAttributesMeta>> {
  const params = QueryString.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.structures.list(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getStructure(structureId: RecordId): Promise<ApiResourceResponse<StructureAttributes, unknown, StructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.structures.get(structureId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export function getStructureQuery(structureId: RecordId) {
  return {
    queryKey: [getStructureKey, structureId],
    queryFn: () => getStructure(structureId)
  }
}




export async function createStructure(organizationId: RecordId, structure: CreateStructureAttributes): Promise<ApiResourceResponse<StructureAttributes, unknown, StructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.structures.create(organizationId), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ structure }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateStructure(structureId: RecordId, structure: CreateStructureAttributes): Promise<ApiResourceResponse<StructureAttributes, unknown, StructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.structures.update(`${structureId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
      body: JSON.stringify({ structure }),
    }
  )
  return await handleApiResponse(response);
}

export async function deleteStructure(structureId: RecordId): Promise<ApiResourceResponse<StructureAttributes, unknown, StructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.structures.update(`${structureId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}

