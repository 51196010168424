import { MissionEquipmentAttributes } from "@/interfaces/missionEquipment"
import { STATES_LABELS, StateCategory, StateKey } from "./states"
import { sortBy } from "./object"
import QueryString from "qs"
import { addDays, formatISO, parseISO, startOfDay } from "date-fns"
import { MissionsListQueryParams } from "@/interfaces/queryParams"


export const countEquipmentsByStatus = (missionEquipments: MissionEquipmentAttributes[]) => {
  return missionEquipments.reduce<{ [key in StateKey]: number }>((prev, curr) => {
    const state = curr?.current_state

    if (state) {
      prev[state] = prev[state] || 0
      prev[state] += 1
    }
    return prev
  }, {} as { [key in StateKey]: number })
}


export const countEquipmentsByStatusCategory = (missionEquipments: MissionEquipmentAttributes[]) => {
  return missionEquipments.reduce<{ [key in StateCategory]: number }>((prev, curr) => {
    const state = curr?.current_state

    if (state) {
      const cat = STATES_LABELS[state].category

      prev[cat] = prev[cat] || 0
      prev[cat] += 1
    }
    return prev
  }, {} as { [key in StateCategory]: number })
}

export const sortTransitions = (allowed_transitions: StateKey[], current: StateKey): StateKey[] => {

  const sortedActions = allowed_transitions.map(tr => {
    let distance = STATES_LABELS[tr].order - STATES_LABELS[current].order
    if (distance < 0) {
      distance = Math.abs(distance) + 5
    }

    return { name: tr, distance }
  })

  return sortedActions.sort(sortBy('distance')).map(e => e.name)
}

export const colorForCode = (code, saturation = 100, lightness = 75) => {
  let hash = 0;
  const properCode = code && code.length > 0 ? code : 'unknown'

  const string = properCode.split('').reverse().join('')
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}

export const colorsForCode = (code) => {
  const bg = colorForCode(code, 60, 90)
  const border = colorForCode(code, 60, 50)
  const fg = colorForCode(code, 60, 20)
  const subtle = colorForCode(code, 60, 30)

  return {
    bg,
    border,
    fg,
    subtle,
  }
}

export const parseMissionQueryParams = (searchParams: URLSearchParams): MissionsListQueryParams => {

  const parsed = QueryString.parse(searchParams.toString()) as MissionsListQueryParams

  // parsed.future = (`${parsed.future}` === 'true' ? true : false)


  if (!parsed.active_between || (!parsed.active_between?.start_date && !parsed.active_between?.end_date)) {
    parsed.active_between = {
      start_date: formatISO(startOfDay(Date.now())),
      end_date: formatISO(addDays(startOfDay(Date.now()), 7))
    }
  }
  if (parsed.active_between?.start_date) {
    parsed.active_between.start_date = formatISO(parseISO(parsed.active_between.start_date))
  }
  if (parsed.active_between?.end_date) {
    parsed.active_between.end_date = formatISO(parseISO(parsed.active_between.end_date))
  }
  return parsed
}
