
import { Badge, Button, HStack, Stack, StackDivider, Table, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import UserEditForm from '@/components/UserEditForm/UserEditForm';
import { useLogout, useUser } from '@/hooks/auth';
import { UserAttributes } from '@/interfaces/user';
import { RiLoginBoxLine } from 'react-icons/ri';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { accountEditRoute, accountRoute } from '@/constants/routes';
import { getStructureQuery } from '@/api/structure';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { ROLES_LABELS } from '@/constants/roles';


export const UserShowPage = () => {
  const { data: user, refetch } = useUser();
  const logout = useLogout()
  const { orgId } = useParams();
  const navigate = useNavigate()
  const { ability } = useOrganizations()

  const onLogout = () => {
    logout.mutate({})
    navigate('/login')
  }

  // Then get the users messages
  const structures = useQueries({
    queries: user?.meta?.user_structures?.map(e => e.structure_id)
      ? user?.meta?.user_structures?.map(e => e.structure_id).map(getStructureQuery)
      : [], // if users is undefined, an empty array will be returned
  })

  return (
    <PageWithTitle
      title="Mon profil"
      actions={[
        // <Button variant="secondary" leftIcon={<FiDownloadCloud fontSize="1.25rem" />}>
        //     Download
        // </Button>,
        <Button key="edit" size={'sm'} as={Link} variant="primary" to={accountEditRoute(orgId)}>Éditer mes informations</Button>
      ]}
    >

      <BoxSection py={6}>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              {user?.attributes?.full_name}
            </Text>
          </Stack>

          <Stack spacing="5">
            {user?.meta?.organizations_users?.map(orgu => (
              <HStack key={[orgu.organization_id, orgu.role].join('-')} align={'baseline'}>
                <Text fontWeight="medium">
                  {user.meta.organization_name}
                </Text>
                {ability?.isOrgOwner() && <Badge>{ROLES_LABELS[orgu?.role]}</Badge>}
              </HStack>
            ))}
            <HStack align={'baseline'}>
              <Text fontSize="xl" fontWeight="medium">
                Structures
              </Text>
              <Text fontSize="sm" color="muted">
                Rôles par structure
              </Text>
            </HStack>
            <Stack spacing="1">

              <Table>
                <Tbody>
                  {user?.meta.user_structures?.flatMap(us => {
                    const structure = structures.find(e => e.data?.data?.id?.toString() === us.structure_id?.toString())?.data?.data
                    return (
                      <Tr key={`${us.structure_id}-${us.structure_function}-${us.role}`}>
                        <Td>
                          <VStack align={'flex-start'}>
                            <Text>{structure?.attributes.short_name}</Text>
                            <Text color={'fg.subtle'}>{structure?.attributes.name}</Text>
                          </VStack>
                        </Td>
                        <Td><Badge>{us?.structure_function}</Badge></Td>
                        {ability?.isOrgOwner() && <Td><Badge>{ROLES_LABELS[us?.role]}</Badge></Td>}
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </Stack>
          </Stack>


          <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>

            <Button
              leftIcon={<RiLoginBoxLine />}
              variant="outline"
              w={'100%'}
              onClick={onLogout}
            >
              Déconnexion
            </Button>
          </HStack>

        </Stack>
      </BoxSection>
    </PageWithTitle>
  );
}

export default UserShowPage;