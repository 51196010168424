import qs from "qs";
import { ROUTES } from "../constants/routes";
import { EquipmentTypeAttributes, CreateEquipmentTypeAttributes, EquipmentTypeAttributesMeta } from "../interfaces/equipmentType";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";


export const getOrganizationEquipmentTypesKey = "OrganizationEquipmentTypes"
export const getEquipmentTypeKey = "EquipmentType"


export async function getOrganizationEquipmentTypes(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentTypeAttributes, any, EquipmentTypeAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipmentTypes.list() + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getEquipmentType(equipmentTypeId: RecordId): Promise<ApiResourceResponse<EquipmentTypeAttributes>> {
  const response = await fetch(
    ROUTES.equipmentTypes.get(equipmentTypeId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}


export function getEquipmentTypeQuery(equipmentTypeId: RecordId) {
  return {
    queryKey: [getEquipmentTypeKey, equipmentTypeId],
    queryFn: () => getEquipmentType(equipmentTypeId)
  }
}

export async function createEquipmentType(organizationId: RecordId, equipmentType: CreateEquipmentTypeAttributes): Promise<ApiResourceResponse<EquipmentTypeAttributes>> {
  const response = await fetch(
    ROUTES.equipmentTypes.create(), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ equipment_type: equipmentType }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateEquipmentType(equipmentTypeId: RecordId, equipmentType: CreateEquipmentTypeAttributes): Promise<ApiResourceResponse<EquipmentTypeAttributes>> {

  const equipment_type = {
    ...equipmentType,
    configuration_template: Object.fromEntries((equipmentType.configuration_template || []).map(e => [e.name, e.type]))
  }

  const response = await fetch(
    ROUTES.equipmentTypes.update(`${equipmentTypeId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
      body: JSON.stringify({ equipment_type }),
    }
  )
  return await handleApiResponse(response);
}

export async function deleteEquipmentType(equipmentTypeId: RecordId): Promise<ApiResourceResponse<EquipmentTypeAttributes>> {
  const response = await fetch(
    ROUTES.equipmentTypes.update(`${equipmentTypeId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}
