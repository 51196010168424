import { MissionEquipmentPlanning } from "@/interfaces/missionEquipment"
import { ApiCollectionResponse, ApiResource } from "@/utils/api"
import { addDays, parseISO } from "date-fns";
import { useMemo } from "react";
import { GanttPlanning } from "./GanttPlanning";

export interface StructurePlanningGanttProps  {
  data: ApiCollectionResponse<MissionEquipmentPlanning, unknown, unknown>
  viewStart?: Date,
  viewEnd?: Date,
}

export function StructurePlanningGantt({
  data,
  viewStart = addDays(new Date(), -1),
  viewEnd = addDays(new Date(), 30),
}: StructurePlanningGanttProps) {
  const tasks: any[] = useMemo(() => {
    

    return data.data.map((elt: ApiResource<MissionEquipmentPlanning, unknown>) => {
      return ({
        start: parseISO(elt.attributes.begin_at),
        end: parseISO(elt.attributes.end_at),
        name: elt.attributes.equipment_name,
        id: `${elt.attributes.id}`,
        group: elt.attributes.mission_name,
      })
    })

  }, [data])

  return (
    <GanttPlanning
      tasks={tasks}
      viewStart={viewStart}
      viewEnd={viewEnd}
    />
  )
}
