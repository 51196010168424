import React, { useEffect, useMemo } from "react"
import { ApiCollectionResponse, ApiErrorResponse } from "../../utils/api"
import ErrorBox from "../ErrorBox/ErrorBox"
import Loader from "../Loader/Loader"
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult, QueryStatus } from "@tanstack/react-query"


// T = MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta

interface RemoteInfiniteAutoFetchContainerProps<T, Y = unknown, M = unknown> {
  error: ApiErrorResponse | null
  errorMessage?: string
  isLoading: boolean
  data: InfiniteData<ApiCollectionResponse<T, Y, M> | null> | undefined
  status: QueryStatus
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<InfiniteData<ApiCollectionResponse<T, Y, M> | null>, Error>>
  hasNextPage: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  render: (data: ApiCollectionResponse<T, Y, M>) => React.ReactElement
}

export function RemoteInfiniteAutoFetchContainer<T, Y = unknown, M = unknown>({
  error,
  errorMessage,
  data,
  status,
  render,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
}: Readonly<RemoteInfiniteAutoFetchContainerProps<T, Y, M>>) {

  const renderOnStatus = useMemo(() => {
    switch (status) {
      case 'error':
        return <ErrorBox error={error} message={errorMessage || error?.message || ''} />
      case 'pending':
        if (data) {
          return render({ data: data.pages.flatMap(e => e?.data ?? []) })
        } else {
          return <Loader />
        }
      case 'success':
        if (data) {
          return render({ data: data.pages.flatMap(e => e?.data ?? []) })
        } else {
          return <Loader />
        }
    }
  }, [error, errorMessage, data, status, render])

  useEffect(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage])

  return <>
    {renderOnStatus}
  </>
}