import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const BoxSection = (props: BoxProps) => (
  <Box
    px={{ base: '4', md: '6' }}
    py={{ base: '0', md: '6' }}
    mx={{ base: '0', md: '6' }}
    bg={useColorModeValue('white', 'gray.800')}
    borderRadius={{ base: '0', md: 'md' }}
    borderWidth={{ base: '0', md: '1px' }}
    // boxShadow={useColorModeValue('sm', 'sm-dark')}
    {...props}
  />
)