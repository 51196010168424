
import { Outlet } from 'react-router-dom';


export const OrganizationPage = () => {

  return (
    <>
      <Outlet />
    </>
  );
}

export default OrganizationPage;