import { useNanuqQuery } from "@/hooks/query";
import { MissionAttributes } from "@/interfaces/mission";
import { ApiCollectionResponse, ApiErrorResponse, ApiNamedResource, ApiResource } from "@/utils/api";
import { formatDateShort } from "@/utils/datesFormat";
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import { Select, SelectComponent, SingleValue } from "chakra-react-select";
import { parseISO } from "date-fns";
import { ErrorMessage, FieldProps } from "formik";
import { useEffect, useMemo, useState } from "react";

export interface Option {
  value: string
  label: string;
}

export type OptionList = Array<Option & Partial<MissionAttributes>>;

interface AsyncFieldProps<M> extends FieldProps {
  query: UseQueryResult<ApiCollectionResponse<M>, ApiErrorResponse>
  label: string
  dataLabel?: string
  dataValue?: string
  helperText?: string
  additionalOptions?: OptionList
  selectOptions?: any
}

export function MissionAsyncSelect<T>({
  field: { name, value },
  form: { setFieldValue, errors, setFieldError, touched },
  query,
  label,
  helperText,
  dataLabel = 'name',
  dataValue = 'id',
  additionalOptions = [],
  selectOptions
}: AsyncFieldProps<T>) {

  const {
    isLoading,
    data,
    error
  } = query

  const [currentValue, setCurrentValue] = useState<Option>()

  const options: OptionList = useMemo(() => {
    const baseOptions = data?.data?.map(o => ({ label: o.attributes[dataLabel], value: `${o[dataValue]}`, ...o.attributes })) || []

    return [...baseOptions, ...(additionalOptions || [])]

  }, [data, dataValue, dataLabel, additionalOptions])

  const defaultValue = (options: OptionList, value: string) => {
    return options ? options.find(option => option.value === value?.toString()) : { label: `${value} ?`, value }
  }

  useEffect(() => {
    const columnName = name.replace("_id", "")
    if (errors[columnName]) {
      setFieldError(name, (errors[columnName] as string[]).join(', '))
    }
  }, [errors, name, setFieldError])

  useEffect(() => {
    setCurrentValue(defaultValue(options, value))
  }, [value, setCurrentValue, data, options])

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      {isLoading ? (
        <div>Chargement</div>
      ) : error ? (
        <div>Erreur: {error.message}</div>
      ) : data ? (
        <Box>
          <Select
            defaultValue={defaultValue(options, value)}
            isInvalid={!!(errors[name] && touched[name])}
            placeholder={`Sélectionner`}
            options={options}
            selectedOptionColorScheme={'green'}
            getOptionLabel={(option: any) => (<HStack spacing={1}>
              <Text>{option.label}</Text>
              {option.begin_at && <Text color={'fg.subtle'} fontSize={'sm'}>{formatDateShort(parseISO(option.begin_at || ''))} - {formatDateShort(parseISO(option.end_at || ''))}</Text>}
            </HStack>)}

            value={currentValue}
            onChange={
              (option: SingleValue<Option>) => setFieldValue(name, option?.value)
            }
            {...selectOptions}
          />
        </Box>
      ) : null}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errors[name] as string}</FormErrorMessage>
    </FormControl>
  )
}