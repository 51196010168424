import * as React from 'react'
import { Avatar, Box, HStack, Text } from '@chakra-ui/react'
import { truncate } from '@/utils/string'

export interface UserProfileProps {
  full_name?: string
  email?: string
}

export const UserProfile = ({ full_name, email }: UserProfileProps) => {

  const image = "image"
  
  return (
    <HStack maxW={'100%'} spacing="3" ps="2">
      <Avatar name={full_name} src={image} boxSize="10" />
      <Box>
        {full_name ? (
          <Text noOfLines={1} title={full_name} textAlign="left" fontWeight="medium" fontSize="sm">{truncate(full_name, 12)}</Text>
        ) : (
          <Text noOfLines={1} title={email} textAlign="left" fontWeight="medium" fontSize="sm">{truncate(email, 12)}</Text>
        )}
      </Box>
    </HStack>
  )
}