
import { getOrganizationUsersKey } from '@/api/user';
import { useNanuqQuery } from '@/hooks/query';
import { useParams } from 'react-router-dom';
import { PaginationState } from '@tanstack/react-table';
import { UserStructuresTable } from '@/screens/UserStructuresTable/UserStructuresTable';
import { useState } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { AddUserToStructureModal } from '@/screens/AddUserToStructureModal/AddUserToStructureModal';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { deleteUserStructure, getStructureUserStructures, getStructureUserStructuresKey } from '@/api/userStructure';
import { useQueryClient } from '@tanstack/react-query';
import { UserStructureAttributes } from '@/interfaces/userStructure';
import { RecordId } from '@/interfaces/id';


export const UsersList = () => {

  const { orgId, structureId } = useParams()

  const queryClient = useQueryClient()

  const [{ pageIndex }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const queryKey = [getStructureUserStructuresKey, orgId, structureId, pageIndex]
  const {
    data,
    refetch
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getStructureUserStructures(structureId || '', pageIndex + 1) : null
  )

  const onUserSelect = async (users: ApiResourceResponse<UserStructureAttributes>) => {
    console.log("TODO", users)
    refetch()
  }

  const deleteUserStructureMutation = useOptimisticDeleteMutation<ApiResourceResponse<UserStructureAttributes>, RecordId>(queryClient, deleteUserStructure, queryKey)

  const onEdit = async () => {

  }

  const onDelete = async (missionId: RecordId) => {
    // console.log("Delete !", { missionId })
    deleteUserStructureMutation.mutate(missionId)
  }

  return (
    <>
    <Stack direction='column' px={6} py={3} alignContent={'center'} justify="space-between">
      <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
        <Text textStyle="lg" fontWeight="medium">
          {data?.meta?.count || 'Aucun'} membre(s)
        </Text>

        <AddUserToStructureModal onSave={onUserSelect} structureId={structureId || ''} />

        </Stack>
      </Stack>
    {data?.data && <UserStructuresTable onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} data={data} />}
    </>
  );
}
