// import "instantsearch.css/themes/algolia-min.css";

import { Box, Text, VStack } from '@chakra-ui/react';
import EquipementRefinementList from "@/screens/EquipmentRefinementList/EquipementRefinementList";

import { HierarchicalMenu } from '@/components/HierarchicalMenu/HiercharchicalMenu';
import EquipmentStatusRefinementList from '@/screens/EquipmentRefinementList/EquipmentStatusRefinementList';


export interface EquipmentsListFiltersProps {
  refinementList?: {[attribute: string]: string[]}
}
export const EquipmentsListFilters = ({ refinementList }: EquipmentsListFiltersProps) => {

  return (
    <Box px={3} minWidth={{ base: '100%', md: '200px' }} maxWidth={{ base: '100%', md: '200px' }} borderRightWidth={{ base: '0px', md: '1px' }} borderRightColor={'bg.muted'}>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Type d'équipement</Text>
        <HierarchicalMenu
          attributes={[
            'equipment_type.lvl0',
            'equipment_type.lvl1',
          ]}
        />
      </VStack>
      {/* <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Structure</Text>
        <EquipementRefinementList lockedItems={refinementList?.structure_short_name} attribute="structure_short_name" />
      </VStack> */}
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Emplacement</Text>
        <EquipementRefinementList attribute="current_location_short_name" />
      </VStack>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>État</Text>
        <EquipmentStatusRefinementList attribute="current_state" />
      </VStack>
      <VStack alignItems={'flex-start'} py={3}>
        <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Disponibilité</Text>
        <EquipmentStatusRefinementList attribute="current_availability" />
      </VStack>
    </Box>
  );
}
