import {
  HStack,
  IconButton,
  TableProps,
  Link,
  Tag
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  PaginationState,
  OnChangeFn,
  getSortedRowModel,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { StructureAttributes, StructureAttributesMeta } from '@/interfaces/structure'
import { RecordId } from '@/interfaces/id'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { structureRoute, structureSummaryRoute } from '@/constants/routes'
import { CollectionTable } from '@/components/CollectionTable/CollectionTable'
import { useOrganizations } from '@/contexts/useOrganizationsContext'


const columnHelper = createColumnHelper<ApiResource<StructureAttributes, StructureAttributesMeta>>()


export interface StructureTableProps extends TableProps {
  data: ApiCollectionResponse<StructureAttributes, unknown, StructureAttributesMeta>
  onDelete: (structureId: RecordId) => Promise<void>
  onEdit: (structureId: RecordId) => Promise<void>
  onPaginationChange?: OnChangeFn<PaginationState> | undefined
  userStructures?: { structure_function: string, structure_id: number, role: string }[]
}

export const StructureTable = ({ userStructures, data, onDelete, onEdit, onPaginationChange, ...props }: StructureTableProps) => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()
  // const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])

  const columns = [
    columnHelper.accessor(row => row.attributes.name, {
      id: 'name',
      cell: info => <Link as={RouterLink} to={structureSummaryRoute(orgId, info.row.original.attributes?.slug)}>{info.getValue()}</Link>,
      header: () => 'Nom',
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.city', {
      header: 'Ville',
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'role',
      header: 'Fonction',
      cell: (props) => {
        const r = userStructures?.find(e => e.structure_id.toString() === props.row.original.id.toString())
        if (r) {
          return <Tag>{r.structure_function}</Tag>
        }
      },
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.zip', {
      header: 'Code Postal',
      footer: info => info.column.id,
    }),

    columnHelper.display({
      id: 'actions',
      cell: (props) => (<HStack spacing="1">
        { ability?.can('update', 'structure', props.row.original?.attributes) && (
          <IconButton variant={'link'} icon={<FiEdit2 />} onClick={() => onEdit(props.row.original.id)} aria-label="Edit" />
        )}
        { ability?.can('delete', 'structure', props.row.original?.attributes) && (
          <IconButton variant={'link'} icon={<FiTrash2 />} onClick={() => onDelete(props.row.original.id)} aria-label="Delete" />
        )}
      </HStack>),
      footer: info => info.column.id,
    })
  ]

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data.meta?.pages ?? -1,
    state: {
      pagination: {
        pageIndex: data?.meta?.page ?? 0,
        pageSize: data?.meta?.items ?? 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    onPaginationChange,
  })

  return (<CollectionTable table={table} {...props} />)
}
