import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  VStack,
  HStack,
  Text,
  Divider,
  Box,
  Button,
  Heading,
  Tag,
} from '@chakra-ui/react'

import { RecordId } from '@/interfaces/id';
import { useNanuqQuery } from '@/hooks/query';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import { getEquipment, getEquipmentKey } from '@/api/equipment';
import { VehiculeDetails } from '../VehiculeDetails/VehiculeDetails';
import { TbCalendar, TbTruck } from 'react-icons/tb';
import MissionQueryBox from './MissionQueryBox';
import { Link } from 'react-router-dom';
import { vehiculeCalendarRoute } from '@/constants/routes';
import Marker from '@/components/Marker/Marker';
import { VehiculeCard } from '../VehiculeCard/VehiculeCard';

export interface MissionDetailForEquipmentModalProps extends Partial<ModalProps> {
  isOpen: boolean
  onClose: () => void
  onCancel: (missionEquipmentId: RecordId) => void
  orgId?: RecordId | undefined
  missionIds?: RecordId[] | undefined
  equipmentId?: RecordId | undefined
}

const MissionDetailForEquipmentModal = ({ isOpen, onClose, onCancel, orgId, missionIds, equipmentId, ...props }: MissionDetailForEquipmentModalProps) => {

  const equipmentQuery = useNanuqQuery([getEquipmentKey, equipmentId], () => equipmentId ? getEquipment(equipmentId) : null)

  return (
    <Modal {...props} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <RemoteFetchContainer {...equipmentQuery} render={(equipment) => (
          <ModalContent>
            <ModalHeader>{equipment?.data.attributes.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3} alignItems={'stretch'}>
                <Heading size={'2xs'}>
                  <HStack alignItems={'center'} py={3}>
                    <Text color='brand.600'><TbTruck /></Text>
                    <Text fontSize={'lg'}><b>Véhicule</b></Text>
                  </HStack>
                </Heading>
                {equipment && equipment.data.meta?.equipment_type.category == "vehicule" && (<>
                  <VehiculeCard orgId={orgId ?? ''} queryParams={{}} data={equipment.data} key={equipment.data.id} />
                  <Box>
                    <Button as={Link} w={'100%'} variant={'outline'} leftIcon={<TbCalendar />} to={vehiculeCalendarRoute(orgId || '', equipmentId)}>Voir les réservations de {equipment.data.attributes.name}</Button>
                  </Box>
                </>
                )}
                {/* {equipment && equipment.data.meta?.equipment_type.category == "vehicule" && (<>

                <HStack spacing={3} flexWrap={'wrap'}>
                  <Heading size={'3xs'}>
                    <Marker color='yellow'>{equipment?.data.attributes.name}</Marker>
                  </Heading>
                  {(equipment?.data.attributes.manufacturer || equipment?.data.attributes.model) && <Text>{equipment?.data.attributes.manufacturer} {equipment?.data.attributes.model}</Text>}
                  {equipment?.data.attributes.serial && <Text><Tag>{equipment?.data.attributes.serial}</Tag></Text>}
                </HStack>

                  <Box>
                    <Button as={Link} w={'100%'} variant={'outline'} leftIcon={<TbCalendar />} to={vehiculeCalendarRoute(orgId || '', equipmentId)}>Voir les réservations de {equipment.data.attributes.name}</Button>
                  </Box>
                </>)} */}
                <Divider />
              <VStack flex={1} pb={3} alignItems={'stretch'}>
                  <Heading size={'2xs'}>
                    <HStack alignItems={'center'} py={3}>
                      <Text color='brand.600'><TbCalendar /></Text>
                      <Text fontSize={'lg'}><b>Réservation</b></Text>
                    </HStack>
                  </Heading>
                  <VStack divider={<Divider />} spacing={3} flex={1} alignItems={'stretch'}>
                    {(missionIds || []).map(missionId => <MissionQueryBox key={missionId} equipmentId={equipmentId} missionId={missionId} onCancel={onCancel} />)}
                  </VStack>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        )} />
      </Modal>
  )
}

export default MissionDetailForEquipmentModal