import { FormControl, Icon, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch';

export function SearchBox(props: UseSearchBoxProps) {
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <FormControl>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color="muted" boxSize="5" />
            </InputLeftElement>
            <Input
              ref={inputRef}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Rechercher..."
              spellCheck={false}
              maxLength={512}
              type="search"
              value={inputValue}
              onChange={(event) => {
                setQuery(event.currentTarget.value);
              }}
              autoFocus
            />
          </InputGroup>
        </FormControl>
{/* 
        <button type="submit">Submit</button> */}
      </form>
  );
}
