import { Select } from '@chakra-ui/react';
import React from 'react';
import { TbSortAscending, TbSortAZ, TbSortDescending } from 'react-icons/tb';
import {
  useSortBy,
  UseSortByProps,
} from 'react-instantsearch';

export interface SortByCustomProps extends UseSortByProps  {
  placeholder?: string
}

export function SortBy({ placeholder = "Trier par...", ...props }: SortByCustomProps) {
  const { currentRefinement, options, refine } = useSortBy(props);

  return <Select variant='outline' icon={<TbSortDescending />} value={currentRefinement} placeholder={placeholder} onChange={(e) => refine(e.target.value)}>
    {options.map(opt => (
      <option key={opt.label} value={opt.value}>{opt.label}</option>
    ))}
  </Select>;
}
