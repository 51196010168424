
import { getEquipment, getEquipmentKey, getEquipmentQuery } from '@/api/equipment';
import Loader from '@/components/Loader/Loader';
import { equipmentRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributesMeta, EquipmentAttributes } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Box } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import EditEquipmentForm from '@/screens/EquipmentForm/EditEquipmentForm';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    }
    return data
  }
)


export const EquipmentEdit = () => {
  const { orgId, equipmentId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const {
    data,
  } = useNanuqQuery([getEquipmentKey, equipmentId], () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  const navigate = useNavigate()

  const onSave = async (data: ApiResourceResponse<EquipmentAttributes>) => {
    navigate(equipmentRoute(orgId, data.data?.id))
  }

  return (
    <Box
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
    >
      {data?.data?.attributes ?
        <EditEquipmentForm equipment={data?.data?.attributes} orgId={`${orgId}`} onSave={onSave} /> :
        <Loader />
      }
    </Box>
  );
}

export default EquipmentEdit;