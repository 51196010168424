import {
  Button,
  ModalProps,
  VStack,
  HStack,
  Text,
  Heading,
} from '@chakra-ui/react'

import { RecordId } from '@/interfaces/id';
import { useNanuqQuery } from '@/hooks/query';
import { getMission, getMissionKey } from '@/api/mission';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { missionEditRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import Marker from '@/components/Marker/Marker';

export interface MissionQueryBoxProps extends Partial<ModalProps> {
  missionId: RecordId | undefined
  equipmentId: RecordId | undefined
  onCancel: (missionEquipmentId: RecordId) => void
}

const MissionQueryBox = ({ missionId, equipmentId, onCancel }: MissionQueryBoxProps) => {

  const { orgId } = useParams()
  const missionQuery = useNanuqQuery([getMissionKey, missionId], () => missionId ? getMission(missionId) : null)

  const { ability } = useOrganizations()
  const canUpdate = ability?.can('update', 'mission', missionQuery.data?.data.attributes)

  const onCancelClick = () => {
    if (missionQuery.data) {
      const me = missionQuery.data.data.attributes.mission_equipments.filter(e => e.equipment_id.toString() === equipmentId?.toString())[0]
      if (me){
        onCancel(me.id)
      }
    }
  }
  return (
    <RemoteFetchContainer key={missionQuery.data?.data?.id} {...missionQuery} render={(mission) => {
      return (<>
        <HStack spacing={1} flexWrap={'wrap'}>
          <Heading size={'3xs'}>
          <Marker color='yellow'>{mission?.data.attributes.name}</Marker>
          </Heading>
        </HStack>
        <HStack spacing={1} flexWrap={'wrap'}>
          <Text>Du {parseAndFormatDateShort(mission?.data.attributes.begin_at)}</Text>
          <Text>au {parseAndFormatDateShort(mission?.data.attributes.end_at)}</Text>
        </HStack>
        <HStack spacing={1} flexWrap={'wrap'}>
          <Text color={'fg.subtle'}>Par {mission?.data.meta?.user.full_name}</Text>
        </HStack>
        <VStack pt={4} flex={1} alignItems={'stretch'}>
          <Button colorScheme='brand' isDisabled={missionQuery.isLoading || !canUpdate} as={RouterLink} to={canUpdate ? missionEditRoute(orgId, mission?.data.id) : ''}>Modifier la réservation</Button>
          <Button isDisabled={missionQuery.isLoading || !canUpdate} variant={'outline'} onClick={onCancelClick}>Annuler la réservation</Button>
        </VStack>
      </>)
    }} />
  )
}

export default MissionQueryBox