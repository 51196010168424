import { ROUTES } from "../constants/routes";
import { RecordId } from "../interfaces/id";
import { ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import { MissionUserAttributes } from "@/interfaces/missionUser";




export async function addUserToMission(userId: RecordId, missionId: RecordId): Promise<ApiResourceResponse<MissionUserAttributes>> {
  const response = await fetch(
    ROUTES.mission_users.create(missionId), {
    headers: defaultAuthHeaders(),
    method: 'POST',
    body: JSON.stringify({ mission_user: { user_id: userId } })
  })

  return await handleApiResponse(response);
}

export async function removeUserFromMission(missionUserId: RecordId): Promise<ApiResourceResponse<MissionUserAttributes>> {
  const response = await fetch(
    ROUTES.mission_users.delete(missionUserId), {
    headers: defaultAuthHeaders(),
    method: 'DELETE'
  })

  return await handleApiResponse(response);
}

