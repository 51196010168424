
import { useEquipmentReservation } from '@/hooks/useEquipmentReservation';
import { EquipmentAttributesMeta } from '@/interfaces/equipment';
import { User } from '@/interfaces/user';
import { Alert, AlertDescription, AlertTitle, Badge, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { TbCalendar, TbCalendarCancel, TbTruck } from 'react-icons/tb';
import { formatDateShort, formatDistanceShort } from '@/utils/datesFormat';
import { parseISO } from 'date-fns';
import { goEquipmentSetupMissionRoute } from '@/constants/routes';
import { RecordId } from '@/interfaces/id';
import { Link } from 'react-router-dom';
import ButtonWithAlert from '@/components/ButtonWithAlert/ButtonWithAlert';



export interface BoxForNextMissionStatusProps {
  equipmentId: RecordId,
  orgId: RecordId,
  meta?: EquipmentAttributesMeta | undefined,
  user?: User | undefined,
  onCancel: (missionEquipmentId: RecordId) => void
  onClose: (equipmentId: RecordId, missionId: RecordId) => void
}


export const BoxForNextMissionStatus = ({ equipmentId, orgId, meta, user, onClose, onCancel }: BoxForNextMissionStatusProps) => {

  const {
    activeMissions,
    nextMission,
    hasActiveMission,
    hasActiveRide,
    hasActiveMissionForUser,
    hasActiveRideForUser,
    currentActiveMissionForUser,
    currentActiveRideForUser,
    hasActiveMissionForOtherUser,
    hasActiveRideForOtherUser,
    upcomingMissionForOtherUser,
    upcomingMissionForUser,
  } = useEquipmentReservation(user, meta)

  return (<>
    <HStack>
      <Text fontSize={'large'} justifyContent={'center'} textAlign={'center'} fontWeight={'bold'}>
        <HStack>
          <Text color={'red.600'}><TbCalendar /></Text>
          <Text>Prochaine réservation</Text>
        </HStack>
      </Text>
    </HStack>
    {!nextMission && (<>
      <Text color={'fg.subtle'}>Aucune réservation à venir</Text>
    </>)}
    {nextMission && (<>
      <Alert status={upcomingMissionForOtherUser ? 'warning' : 'success'} borderRadius={4}>
        <VStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
          <AlertTitle><Text>{nextMission.name}</Text><Text fontSize={'sm'}>{formatDateShort(parseISO(nextMission.begin_at || ''))} - {formatDateShort(parseISO(nextMission.end_at || ''))}</Text></AlertTitle>
          <AlertDescription>
            <Text>Réservé par {nextMission.user_full_name} {nextMission.user_id === user?.attributes.id && <Badge size={'sm'}>C'est vous</Badge>}</Text>
          </AlertDescription>
          {!hasActiveRide && (
            <VStack spacing={6} alignItems={'stretch'} justifyContent={'space-between'}>
              <Text>Départ {formatDistanceShort(parseISO(nextMission.begin_at))}</Text>
              {upcomingMissionForUser && <ButtonWithAlert
                onAccept={() => onCancel(nextMission.mission_equipment_id)}
                buttonText='Annuler la réservation'
                variant={'link'}
                dialogText="La réservation sera annulée, et le véhicule réservé sera de nouveau disponible"
                color={'green.800'}
                data-button="cancel-ride"
                leftIcon={<TbCalendarCancel />}
              />}
            </VStack>
          )}

        </VStack>
      </Alert>
    </>
    )}
  </>);
}
