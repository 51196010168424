
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import StateBadge from '@/components/StateBadge/StateBadge';
import { TabNav } from '@/components/TabNav/TabNav';
import { TabNavButton } from '@/components/TabNavButton/NavButton';
import { ShowEquipmentRideQRCodeModal } from '@/screens/ShowEquipmentRideQRCodeModal/ShowEquipmentRideQRCodeModal';
import { equipmentCalendarRoute, equipmentEditRoute, equipmentRoute, equipmentSummaryRoute, equipmentsForStructureRoute, structureRoute, structuresRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { QueryClient } from '@tanstack/react-query';
import { TbArrowLoopRight2, TbCalendar, TbEdit } from 'react-icons/tb';
import { Link, LoaderFunction, NavLink, Outlet, useLoaderData, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { useOrganizations } from '@/contexts/useOrganizationsContext';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const EquipmentPage = () => {

  const { orgId, equipmentId } = useParams()
  const { ability } = useOrganizations()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { isLoading, data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  return (
    <PageWithTitle
      isLoading={isLoading}
      breadcrumbs={[
        { label: 'Structures', url: structuresRoute(orgId) },
        { label: data?.data?.meta?.structure?.name, url: structureRoute(orgId, data?.data?.attributes.structure_id) },
        { label: 'Equipements', url: equipmentsForStructureRoute(orgId, data?.data?.attributes.structure_id) },
        { label: data?.data?.attributes?.name, url: equipmentRoute(orgId, equipmentId) },
      ]}
      title={data?.data?.attributes?.name}
      subtitle={data?.data?.attributes && `${data?.data?.meta?.equipment_type?.name} - ${data?.data?.attributes?.identifier}`}
      actions={[
        <ShowEquipmentRideQRCodeModal key="qr" size={'sm'} equipment={data?.data?.attributes} />,
        ability?.can('update', 'equipment', data?.data?.attributes) && <Button key="edit" leftIcon={<TbEdit />} size={'sm'} as={Link} variant='secondary' to={equipmentEditRoute(orgId, equipmentId)}>Modifier</Button>
      ]}
      stackProps={{px: 0}}
    >
      <BoxSection px={0} py={0}>

        <TabNav>
          <TabNavButton icon={TbArrowLoopRight2} label='Résumé' as={NavLink} to={equipmentSummaryRoute(orgId, equipmentId)} />
          <TabNavButton icon={TbCalendar} label='Réservations' as={NavLink} to={equipmentCalendarRoute(orgId, equipmentId)} />
        </TabNav>
        <Outlet />
      </BoxSection>
    </PageWithTitle>
  );
}

export default EquipmentPage;