import * as React from 'react'
import { Avatar, Box, HStack, Text, VStack } from '@chakra-ui/react'
import { truncate } from '@/utils/string'

export interface MissionUserLineProfileProps {
  role?: string
  full_name?: string
  email?: string
}

export const MissionUserLineProfile = ({ role, full_name, email }: MissionUserLineProfileProps) => {

  const image = "image"
  
  return (
    <HStack maxW={'100%'} spacing="3" ps="2">
      <Avatar name={full_name} src={image} boxSize="8" />
      <VStack alignItems={'flex-start'}>
        {full_name ? (
          <Text noOfLines={1} title={full_name} textAlign="left" fontWeight="medium" fontSize="sm">{truncate(full_name, 20)}</Text>
        ) : (
          <Text noOfLines={1} title={email} textAlign="left" fontWeight="medium" fontSize="sm">{truncate(email, 20)}</Text>
        )}
        {/* <Text noOfLines={1} title={email} color={'fg.subtle'} textAlign="left" fontSize="sm">{truncate(role, 20)}</Text> */}
      </VStack>
    </HStack>
  )
}