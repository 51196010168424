import { theme as baseTheme } from '@chakra-ui/pro-theme'
import { theme as orgTheme } from '@chakra-ui/theme'

const colorsOverrides = {
  "red": {
    "50": "#FEE6E8",
    "100": "#FDB9BD",
    "200": "#FC8D93",
    "300": "#FB6069",
    "400": "#FA333F",
    "500": "#F80715",
    "600": "#C70511",
    "700": "#95040C",
    "800": "#630308",
    "900": "#320104"
  },
  "blue": {
    "50": "#E8F7FD",
    "100": "#BEE8F9",
    "200": "#94D9F5",
    "300": "#6ACAF1",
    "400": "#40BBED",
    "500": "#16ACE9",
    "600": "#1289BA",
    "700": "#0D678C",
    "800": "#09455D",
    "900": "#04222F"
  },
  "yellow": {
    "50": "#FFFDE5",
    "100": "#FFFAB8",
    "200": "#FFF78A",
    "300": "#FFF35C",
    "400": "#FFF02E",
    "500": "#FFED00",
    "600": "#CCBE00",
    "700": "#998E00",
    "800": "#665F00",
    "900": "#332F00"
  },
  "purple": {
    "50": "#F2EEF6",
    "100": "#DAD0E7",
    "200": "#C1B2D7",
    "300": "#A994C7",
    "400": "#9176B7",
    "500": "#7957A8",
    "600": "#614686",
    "700": "#493465",
    "800": "#312343",
    "900": "#181122"
  },
  "green": {
    "50": "#EAFAF5",
    "100": "#C5F1E2",
    "200": "#A0E9D0",
    "300": "#7BE0BE",
    "400": "#56D7AB",
    "500": "#31CE99",
    "600": "#27A57A",
    "700": "#1D7C5C",
    "800": "#13533D",
    "900": "#0A291F"
  },
  "teal": {
    "50": "#EDF8F8",
    "100": "#CBEAEB",
    "200": "#AADDDE",
    "300": "#89CFD2",
    "400": "#68C2C5",
    "500": "#47B5B8",
    "600": "#399093",
    "700": "#2A6C6F",
    "800": "#1C484A",
    "900": "#0E2425"
  },
  "orange": {
    "50": "#FEF4E6",
    "100": "#FDE1B9",
    "200": "#FCCE8C",
    "300": "#FBBB60",
    "400": "#FAA733",
    "500": "#F99406",
    "600": "#C77605",
    "700": "#955904",
    "800": "#643B02",
    "900": "#321E01"
  },
  "gray": {
    "50": "#faf9f9",
    "100": "#f3f1f0",
    "200": "#eae7e4",
    "300": "#d8d3ce",
    "400": "#b4aba2",
    "500": "#8a7d6f",
    "600": "#64513f",
    "700": "#433424",
    "800": "#271e15",
    "900": "#1f1811"
  }
}
const colors = {
  ...(baseTheme.colors as object),
  ...colorsOverrides,
  brand: colorsOverrides.red,
  secondary: colorsOverrides.orange,
}

export default colors