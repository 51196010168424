import { ROUTES } from "../constants/routes";
import { OrganizationsUserAttributes, OrganizationAttributes, CreateOrganizationsUserAttributes } from "../interfaces/organization";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";


export const getUserOrganizationsKey = "organizations"
export const getOrganizationsUsersKey = "organizations_users"

export async function getUserOrganizations(): Promise<ApiCollectionResponse<OrganizationAttributes>> {
  const response = await fetch(
      ROUTES.organizations.list, { headers: defaultAuthHeaders() }
    )
  return await handleApiResponse(response);
}

export async function updateOrganization(organizationId: RecordId, organization: Partial<OrganizationAttributes>): Promise<ApiResourceResponse<OrganizationAttributes>> {
  const response = await fetch(
    ROUTES.organizations.update(organizationId), {
      headers: defaultAuthHeaders(),
      method: 'PUT',
      body: JSON.stringify({ organization }),
    }
  )
  return await handleApiResponse(response);
}

export async function getOrganizationsUsers(organizationId: RecordId): Promise<ApiCollectionResponse<OrganizationsUserAttributes>> {
  const response = await fetch(
      ROUTES.organizationsUsers.list(organizationId), { headers: defaultAuthHeaders() }
    )
  return await handleApiResponse(response);
}

// export async function createOrganizationsUsers(organizationId: RecordId, organizations_user: CreateOrganizationsUserAttributes): Promise<ApiResourceResponse<OrganizationsUserAttributes>> {
//   const response = await fetch(
//     ROUTES.organizationsUsers.create(organizationId), {
//       headers: defaultAuthHeaders(),
//       method: 'POST',
//       body: JSON.stringify({ organizations_user }),
//     }
//   )
//   return await handleApiResponse(response);
// }

export async function inviteOrganizationsUsers(organizationId: RecordId, organizations_user: CreateOrganizationsUserAttributes): Promise<ApiResourceResponse<OrganizationsUserAttributes>> {
  const response = await fetch(
    ROUTES.organizationsUsers.invite(organizationId), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ organizations_user }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateOrganizationsUsers(organizations_user_id: RecordId, organizations_user: CreateOrganizationsUserAttributes): Promise<ApiResourceResponse<OrganizationsUserAttributes>> {
  const response = await fetch(
    ROUTES.organizationsUsers.update(organizations_user_id), {
      headers: defaultAuthHeaders(),
      method: 'PUT',
      body: JSON.stringify({ organizations_user }),
    }
  )
  return await handleApiResponse(response);
}

export async function deleteOrganizationsUsers(organizations_user_id: RecordId): Promise<ApiResourceResponse<OrganizationsUserAttributes>> {
  const response = await fetch(
    ROUTES.organizationsUsers.delete(organizations_user_id), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}
