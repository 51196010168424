import React, { useCallback, useEffect, useState } from "react"
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Ability } from "../abilities/abilities";
import { getUserOrganizations, getUserOrganizationsKey } from "../api/organization";
import { useUser } from "../hooks/auth";
import { useNanuqQuery } from "../hooks/query";
import { OrganizationAttributes } from "../interfaces/organization";
import { ApiCollectionResponse, ApiErrorResponse, ApiResource } from "../utils/api";
import { equipmentsForStructureRoute } from "@/constants/routes";


export type OrganizationsType = {
  organizations: ApiResource<OrganizationAttributes>[] | undefined
  selectedOrganizationId: string | undefined
  isLoading: boolean | undefined
  ability: Ability | undefined,
  error: ApiErrorResponse | null | unknown
  refetch: undefined | ((options?: RefetchOptions | undefined) => Promise<QueryObserverResult<ApiCollectionResponse<OrganizationAttributes, any, any>, ApiErrorResponse>>)
  setSelectedOrganization: (organizationId: string) => void
  getSelectedOrganization: () => ApiResource<OrganizationAttributes> | undefined
};

const OrganizationsContext = React.createContext<OrganizationsType>({
  organizations: [],
  selectedOrganizationId: undefined,
  isLoading: false,
  ability: undefined,
  error: null,
  refetch: undefined,
  setSelectedOrganization: (organizationId: string) => {},
  getSelectedOrganization: () => (undefined)
})

export function OrganizationsProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { data: user } = useUser();

  
  const { orgId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  
  const [selectedOrganizationId, setSelectedOrganization] = useState<string | undefined>()
  const [ability, setAbility] = useState<Ability | undefined>()
  
  useEffect(() => {
    if (user && selectedOrganizationId) {
      const ability = new Ability(user, selectedOrganizationId)
      console.log("Ability refreshed:", ability)
      setAbility(ability)
    }
  }, [user, selectedOrganizationId])

  const {
    isLoading,
    data,
    error,
    refetch,
  } = useNanuqQuery([getUserOrganizationsKey], () => getUserOrganizations(), { refetchInterval: 30_000 })

  const getSelectedOrganization = useCallback(() => {
    return data?.data.find(c => `${c.id}` === `${selectedOrganizationId}`)
  }, [selectedOrganizationId, data])

  if (orgId && selectedOrganizationId !== orgId) {
    setSelectedOrganization(orgId)
  } else if (!orgId && user?.attributes?.organization_id) {
    if (pathname.startsWith('/takeout')) {
      navigate(`/takeout/orgs/${user?.attributes?.organization_id}`)
    } else {
      navigate(`/orgs/${user?.attributes?.organization_id}`)
    }
    // if ((user.meta?.user_structures?.length || 0) > 0) {
    //   console.log(equipmentsForStructureRoute(user?.attributes?.organization_id, user.meta?.user_structures![0].structure_id))
    //   navigate(equipmentsForStructureRoute(user?.attributes?.organization_id, user.meta?.user_structures![0].structure_id))
    // } else {
    // }
  }

  const organizations = data?.data

  return (
    <OrganizationsContext.Provider value={{ selectedOrganizationId, ability, getSelectedOrganization, setSelectedOrganization, organizations, isLoading, error, refetch }}>
      {children}
    </OrganizationsContext.Provider>
  )
}

export const useOrganizations = (): OrganizationsType => React.useContext(OrganizationsContext)