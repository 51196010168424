import {
  Box,
  Button,
  HStack,
  IconButton,
  TableProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import {
  Table,
} from '@tanstack/react-table'
import { TbChevronLeft, TbChevronRight, TbChevronsLeft, TbChevronsRight } from 'react-icons/tb'
import { usePagination } from 'react-instantsearch';



export function HitsPagination(props) {

  const { isFirstPage, pages, refine, currentRefinement, nbPages, isLastPage } = usePagination(props);
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box px={{ base: '4', md: '6' }} pb="5">
      <HStack spacing="3" justify="space-between">
        <HStack spacing="3">
          <IconButton
              variant="secondary"
              aria-label='Premiere page'
              icon={<TbChevronsLeft />}
              onClick={() => refine(0)}
              isDisabled={isFirstPage}
            />

        </HStack>
        <HStack spacing="3">

          {pages.map(p => (
            <Button
              key={p}
              variant="secondary"
              aria-label={`page ${p + 1}`}
              onClick={() => refine(p)}
              isDisabled={currentRefinement === p}
            >{p + 1}</Button>
          ))}

        </HStack>
        <HStack spacing="3">
          <IconButton
            variant="secondary"
            aria-label='Derniere page'
            icon={<TbChevronsRight />}
            onClick={() => refine(nbPages - 1)}
            isDisabled={isLastPage}
          />

        </HStack>
      </HStack>

      </Box>
  )
}
