
import { MissionsList } from './MissionsList';


export const StructureMissionsPage = () => {

  return (
    <MissionsList />
  );
}

export default StructureMissionsPage;