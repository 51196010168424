import { Outlet, ScrollRestoration } from 'react-router-dom';
import {
  Box,
  Container,
  Flex,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { OrganizationsProvider } from '@/contexts/useOrganizationsContext';
import FeedbackBubble from '../FeedbackBubble/FeedbackBubble';


export const MobileLayout = () => {

  return (
    <>

      <OrganizationsProvider>

        <Flex
          as="section"
          direction={{ base: 'column', lg: 'row' }}
          height="100vh"
          bg="bg-canvas"
          overflowY="auto"
        >

          <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            flex="1"
            overflowY="auto"
            py={0}
          >
            <Container py="0" px={0} flex="1">
              <Stack py={0} spacing={{ base: '4', lg: '6' }}>
                <Outlet />
                <FeedbackBubble />
              </Stack>
            </Container>
          </Box>

        </Flex>
        <ScrollRestoration />
      </OrganizationsProvider>
    </>
  );
}
