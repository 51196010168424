import { Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { BiSolidCommentError } from "react-icons/bi"

const FeedbackBubble = () => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton
        isRound={true}
        zIndex={20}
        variant='solid'
        colorScheme='red'
        aria-label='Done'
        onClick={onOpen}
        fontSize='20px'
        position={'absolute'}
        left={'5px'}
        bottom={'5px'}
        icon={<BiSolidCommentError />}
      />
      <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSf03hc-NaMOgsQmfP2yxcfEXKcO1LKLHaNOCpyZ5lUywDlYiw/viewform?embedded=true"
            width="100%"
            height="720"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}>Chargement…</iframe>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )

}


export default FeedbackBubble