import { MutationFunction, QueryClient, QueryKey, useMutation } from "@tanstack/react-query"
import { BASE_API_URL } from "../constants/routes"
import { RecordId } from "../interfaces/id"
import { storage } from "./storage"
import { useToast } from "@chakra-ui/react"
import { OnChangeFn, SortingState } from "@tanstack/react-table"
import { PaginationState } from "@meilisearch/instant-meilisearch"


export interface ApiErrorResponse {
  status?: number
  message?: string,
  error?: string,
  exception?: string,
}

export interface ApiPaginationLinks {
  first: string
  last: string
  next: string
  prev: string
  self: string
}

export interface ApiPaginationMeta {
  count: number
  from: number
  items: number
  last: number
  next: number
  page: number
  pages: number
  to: number
}

export interface ApiNamedResource {
  name: string
}

export interface ApiResource<T, M = unknown> {
    attributes: T
    relationships?: unknown
    meta?: M
    id: RecordId,
    type: string
}

export interface ApiCollectionResponse<T, Y = unknown, M = unknown> {
  data: ApiResource<T, M>[]
  links?: ApiPaginationLinks,
  meta?: ApiPaginationMeta,
  included?: Y[]
}

export interface ApiResourceResponse<T, Y = unknown, M = unknown> {
  data: ApiResource<T, M>,
  included?: Y[]
}

export const endpoint = (path: string) => {
  return [
    BASE_API_URL,
    path.replace(/^\//, '')
  ].join('/')
}

export const apiEndpoint = (path: string, version = 'v1') => {
  return [
    BASE_API_URL,
    'api',
    version,
    path.replace(/^\//, '')
  ].join('/')
}

export const defaultHeaders = {
  "Content-Type": "application/json",
  "Accept": "application/json"
}

export const defaultAuthHeaders = () => {
  return {
    Authorization: storage.getToken(),
    "Content-Type": "application/json",
    "Accept": "application/json"
  }
}

export const formDataHeaders = () => {
  return {
    Authorization: storage.getToken()
  }
}

export const COMPONENT_ROUTE_MAP: {[key: string]: string} = {
  "Pod": "pods",
  "Node": "nodes",
  "Container": "containers",
  "Service": "services",
  "Replicaset": "replicasets",
  "Deployment": "deployments",
}

export const routeForComponent = (componentType: string, componentId: RecordId) => {
  if (componentType in COMPONENT_ROUTE_MAP) {
    return `${COMPONENT_ROUTE_MAP[componentType]}/${componentId}`
  } else {
    throw new Error("Unknown component type: " + componentType);
  }
}

export async function handleApiResponse<T>(response: Response): Promise<T> {
  const data = response.status === 204 ? null : await response.json();

  if (response.ok) {
    return data;
  } else {
    return Promise.reject(data);
  }
}

export function optimisticRecordDeletion<T>(deletedId: RecordId): ((old: ApiCollectionResponse<T>) => ApiCollectionResponse<T>) {

  return (old: ApiCollectionResponse<T>) => {
    if (!old || !old.data) {
      return old
    }
    const data = old.data.filter((item) => {
      return item.id !== deletedId
    })
    return {
      ...old,
      data
    }
  }
}

export function useOptimisticDeleteMutation<T, M extends RecordId>(queryClient: QueryClient, method: MutationFunction<T, M>, queryKey: QueryKey) {
  const toast = useToast()

  return useMutation({
    mutationFn: method,
    onMutate: async (deletedId: M) => {
      await queryClient.cancelQueries({ queryKey: queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      
      queryClient.setQueryData(
        queryKey,
        optimisticRecordDeletion(deletedId)
      )
      return { previousData }
    },
    onError: (err, newTodo, context) => {
      console.log("onError", { err, newTodo, context })
      queryClient.setQueryData(queryKey, context?.previousData)
      toast({ title: 'Erreur lors de la suppression', description: err.message, status: 'error', isClosable: true, duration: 2000 })
    },
    // onSettled: (data, error, variables) => {
    //   console.log("onSettled", { data, error, variables })
    //   queryClient.setQueryData(
    //     queryKey,
    //     optimisticRecordDeletion(variables)
    //   )
    // },
  })
}

export const formatSort = (sort: SortingState) => {
  if (sort && sort.at(0)) {
    return sort.map(({ desc, id }) => `${desc ? '-' : ''}${id}`).join(',')
  }
}
