import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react"
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { AsyncSelect, OptionList } from "@/components/AsyncSelect/AsyncSelect"
import { useNanuqQuery } from "@/hooks/query"
import { ApiResourceResponse } from "@/utils/api";
import { RecordId } from "@/interfaces/id";
import { getOrganizationUsers, getOrganizationUsersKey } from "@/api/user";
import { ShortUserAttributes } from "@/interfaces/user";
import NewInviteUserForm from "../InviteUserForm/InviteUserForm";



export interface UserOrNewFieldProps {
  name: string,
  orgId: RecordId,
  usedIds?: RecordId[],
  onUserSelect?: (userId: RecordId) => void
}

export const UserOrNewField = (props: UserOrNewFieldProps) => {
  const { name, onUserSelect, orgId, usedIds } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  const userQuery = useNanuqQuery([getOrganizationUsersKey, orgId], () => getOrganizationUsers(orgId))


  const [field, meta] = useField(props);

  const form = useFormikContext<{ user_id: RecordId }>();
  const [additional, setAdditional] = useState<OptionList>([{ label: 'Nouvel utilisateur', value: 'new' }])

  const {
    values,
    touched,
    setFieldValue,
    getFieldProps,
    setValues,
  } = form

  useEffect(() => {
    const fieldProps = getFieldProps(name)
    if (
      fieldProps && fieldProps.value === 'new' && !isOpen
    ) {
      onOpen()
    }
  }, [name, values, getFieldProps, onOpen, isOpen]);

  const onUserSave = async (newUser: ApiResourceResponse<ShortUserAttributes, unknown, unknown>) => {
    console.log("New user saved: ", newUser)

    setAdditional([
      { label: 'Inviter un utilisateur', value: 'new' },
      { label: newUser.data.attributes.email, value: `${newUser.data.id}` },
    ])

    await setFieldValue(name, `${newUser.data.id}`)
    // await setValues({ user_id: `${newUser.data.id}` })

    onClose()
    if (onUserSelect) {
      onUserSelect(newUser.data.id)
    }
  }

  const onModalClose = () => {
    setFieldValue(name, null)
    onClose()
  }

  const filterFn = (v: string) => {
    return v === field?.value?.toString() || !usedIds?.includes(v.toString())
  }


  return (<>
    <AsyncSelect
      query={userQuery}
      label="Utilisateur"
      dataLabel="email"
      additionalOptions={additional}
      field={field}
      form={form}
      meta={meta}
      filterFn={filterFn}
    />
    <Modal size='xl' isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Inviter un nouvel utilisateur</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NewInviteUserForm
            orgId={`${orgId}`}
            onSave={onUserSave}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
  );
};
