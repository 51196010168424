
import {
  Text,
  TextProps,
  useColorModeValue,
  useToken,
} from '@chakra-ui/react'

export interface MarkerProps extends TextProps {
  children: React.ReactNode,
  color: string
}

const Marker = ({ children, color, ...props }: MarkerProps) => {
  const [colorOne, colorTwo, colorThree] = useToken(
    // the key within the theme, in this case `theme.colors`
    'colors',
    // the subkey(s), resolving to `theme.colors.red.100`
    [
      useColorModeValue(`${color}.100`, `${color}.600`),
      useColorModeValue(`${color}.200`, `${color}.700`),
      useColorModeValue(`${color}.300`, `${color}.800`)
    ],
    // a single fallback or fallback array matching the length of the previous arg
  )

  const bgImage = `
  linear-gradient(
    to right,
    ${colorOne},
    ${colorTwo} 4%,
    ${colorThree}
  );

  `

  return (
    <Text
      margin={'-3px -6px'}
      padding={'.1em .6em'}
      borderRadius={'0.8em 0.4em 0.6em 0.5em'}
      // transform={'rotate(10deg)'}
      boxDecorationBreak={'clone'}
      display={'inline'}
      backgroundImage={bgImage}
      background={`url(https://s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=${colorThree.replace('#', '')});`}
      {...props}
    >
      {children}
    </Text>
  )

  // return (
  //   <Text
  //     margin={'-2px -6px'}
  //     padding={'2px 6px'}
  //     display={'inline'}
  //     background={`url(https://s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=${colorRaw.replace('#', '')});`}
  //     {...props}
  //   >
  //     {children}
  //   </Text>
  // )
}

export default Marker