import { Button, ButtonGroupProps, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { TbCalendar } from "react-icons/tb"
import { EquipmentActionReservationModal } from "./Modal"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getEquipmentHistoriesKey } from "@/api/equipmentHistory"
import { getEquipmentQuery } from "@/api/equipment"
import { RecordId } from "@/interfaces/id"
import { addEquipmentToMission } from "@/api/missionEquipment"


export interface EquipmentActionReservationButtonProps extends ButtonProps {
  equipmentIds: RecordId[],
  isLoading?: boolean,
}

export const EquipmentActionReservationButton = ({
  equipmentIds,
  isLoading = false,
  isDisabled,
  ...props
}: EquipmentActionReservationButtonProps) => {

  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedEquipments, setSelectedEquipments] = useState<RecordId[]>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onReservationSelected = (equipmentIds: RecordId[]) => {
    setSelectedEquipments(equipmentIds)
    onOpen()
  }

  const { data, mutateAsync: mutateReservation, isPending: actionPending } = useMutation({
    mutationFn: (payload: { equipmentId: RecordId, missionId: RecordId }) => addEquipmentToMission(payload.equipmentId, payload.missionId)
  })

  const onReservation = async (equipmentIds: RecordId[], missionId: RecordId) => {
    console.log({ equipmentIds, missionId })
    const updates = Promise.all(equipmentIds.map((equipmentId) => {
      return mutateReservation({ equipmentId, missionId }, {
        onSettled: async () => {
          const toInvalidate = [
            getEquipmentQuery(equipmentId).queryKey,
            [getEquipmentHistoriesKey, equipmentId],
          ]

          console.log("Invalidate !", toInvalidate)
          for await (const key of toInvalidate) {
            await queryClient.invalidateQueries({ queryKey: key })
          }
          return true
        }
      })

    }))

    try {
      await updates
      onClose()
      setSelectedEquipments(undefined)
      toast({ title: 'Reservation effectuée', status: 'success', isClosable: true, duration: 2000 })
    } catch (error) {
      console.log("onError", { error })
      toast({ title: "Erreur lors de la réservation", description: (error.message || error.error), status: 'error', isClosable: true, duration: 5000 })
    }
  }

  return (<>
    {selectedEquipments && <EquipmentActionReservationModal equipmentIds={selectedEquipments} onReservation={onReservation} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    <Button isDisabled={isDisabled || isLoading || actionPending} {...props} onClick={() => onReservationSelected(equipmentIds)} leftIcon={<TbCalendar />}>Réserver</Button>
  </>)
}