import ButtonWithAlert from "@/components/ButtonWithAlert/ButtonWithAlert";
import { UserProfile } from "@/components/UserProfile/UserProfile";
import { useOrganizations } from "@/contexts/useOrganizationsContext";
import { OrganizationsUserAttributes } from "@/interfaces/organization";
import { formatDateShort } from "@/utils/datesFormat";
import { truncate } from "@/utils/string";
import { CloseIcon } from "@chakra-ui/icons";
import { Avatar, Box, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { parseISO } from "date-fns";

interface OrganizationsUsersItemProps {
  organizationsUser: OrganizationsUserAttributes,
  onDelete?: (organizationsUser: OrganizationsUserAttributes) => void
  onSelect?: (organizationsUser: OrganizationsUserAttributes) => void
}

export const OrganizationsUsersItem = ({ organizationsUser, onDelete, onSelect }: OrganizationsUsersItemProps) => {

  const { id, first_name, email, last_name, full_name, role, invitation_pending, last_sign_in_at } = organizationsUser
  const { ability } = useOrganizations()

  return (
    <HStack key={id} justify="space-between" fontSize="sm" px="0" spacing="4">
      <Box onClick={() => onSelect && onSelect(organizationsUser)} flex={1}>
        <HStack maxW={'100%'} spacing="3" ps="2">
          <Avatar name={full_name} src={undefined} boxSize="10" />
          <VStack spacing="0" alignItems={'flex-start'} justifyContent={'flex-start'}>
            {first_name ? (
              <Text noOfLines={1} title={full_name} textAlign="left" fontWeight="medium" fontSize="sm">{truncate(full_name, 20)}</Text>
            ) : (
              <Text noOfLines={1} textAlign="left" fontWeight="medium" fontSize="sm"></Text>
            )}
            <Text noOfLines={1} textColor={'fg.subtle'} textAlign="left" fontWeight="medium" fontSize="sm">{email}</Text>
          </VStack>
        </HStack>
      </Box>
      <Box flexBasis={"100px"}>
        <Text>{last_sign_in_at ? formatDateShort(parseISO(last_sign_in_at)) : 'Jamais connecté'}</Text>
      </Box>
      <Box flexBasis={"100px"}>
        <Tag textTransform={"capitalize"}>{role || 'Inconnu'}</Tag>
      </Box>
      <Box flexBasis={"100px"}>
        <Tag colorScheme={invitation_pending ? 'muted' : 'teal'}>{invitation_pending ? 'Invitation envoyée' : 'Actif'}</Tag>
      </Box>
      <Box flexBasis={"150px"}>
        {ability?.can('delete', 'organizationsUser', organizationsUser) && (
          <ButtonWithAlert
            variant="outline"
            leftIcon={<CloseIcon />}
            size={'sm'}
            buttonText="Remove"
            onAccept={() => onDelete && onDelete(organizationsUser)}
          />
        )}
      </Box>

    </HStack>
  );
}