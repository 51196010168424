import {
  TableProps,
} from '@chakra-ui/react'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  PaginationState,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { EquipmentHistoryAttributes, EquipmentHistoryAttributesMeta } from '@/interfaces/equipmentHistory'
import { RecordId } from '@/interfaces/id'
import { CommentsTable } from '@/screens/CommentsTable/CommentsTable'
import { EquipmentHistoryRow } from './EquipmentHistoryRow'


const columnHelper = createColumnHelper<ApiResource<EquipmentHistoryAttributes, EquipmentHistoryAttributesMeta>>()


export interface EquipmentHistoryTableProps extends TableProps {
  data: ApiCollectionResponse<EquipmentHistoryAttributes, unknown, EquipmentHistoryAttributesMeta>
  withEquipmentName?: boolean
  onDelete: (equipmentId: RecordId) => Promise<void>
  onEdit: (equipmentId: RecordId) => Promise<void>
  onPaginationChange?: OnChangeFn<PaginationState> | undefined
  onFilterChange?: (q: string) => Promise<void> | undefined
  onSortingChange?: OnChangeFn<SortingState> | undefined
}

export const EquipmentHistoryTable = ({
  data,
  onDelete,
  onEdit,
  onPaginationChange,
  onSortingChange,
  withEquipmentName = false,
  ...props
}: EquipmentHistoryTableProps) => {

  const columns = [
    columnHelper.accessor(row => row.attributes.title, {
      id: 'title',
      cell: info => (<EquipmentHistoryRow withEquipmentName={withEquipmentName} onDelete={onDelete} onEdit={onEdit} history={info.row.original} />),
      footer: info => info.column.id,
    })
  ]

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data.meta?.pages ?? -1,
    state: {
      pagination: {
        pageIndex: data?.meta?.page ?? 0,
        pageSize: data?.meta?.items ?? 10
      },
      // sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    getSortedRowModel: undefined,
    manualSorting: false,
    // onSortingChange: handleSortingChange,
    onPaginationChange,
  })

  return (<CommentsTable table={table} {...props} />)
}
