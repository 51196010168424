import { CreateEquipmentHistoryAttributes, EquipmentHistoryAttributes, EquipmentHistoryAttributesMeta } from "@/interfaces/equipmentHistory";
import { ROUTES } from "../constants/routes";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import qs from "qs";


export const getEquipmentHistoriesKey = "EquipmentHistories"
export const getEquipmentHistoriesForStructureKey = "EquipmentHistoriesForStructure"
export const getEquipmentHistoryKey = "EquipmentHistory"

export async function getEquipmentHistories(equipmentId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentHistoryAttributes, null, EquipmentHistoryAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipments.history(equipmentId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getEquipmentHistoriesForStructure(equipmentId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentHistoryAttributes, null, EquipmentHistoryAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.structures.equipmentHistory(equipmentId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}


export async function updateEquipmentHistory(equipmentHistoryId: RecordId, history: CreateEquipmentHistoryAttributes): Promise<ApiResourceResponse<EquipmentHistoryAttributes, unknown, EquipmentHistoryAttributesMeta>> {
  const response = await fetch(
    ROUTES.equipmentHistories.update(`${equipmentHistoryId}`), {
    headers: defaultAuthHeaders(),
    method: 'PATCH',
    body: JSON.stringify({ equipment_history: history }),
  }
  )
  return await handleApiResponse(response);
}

export async function deleteEquipmentHistory(equipmentHistoryId: RecordId): Promise<ApiResourceResponse<EquipmentHistoryAttributes, unknown, EquipmentHistoryAttributesMeta>> {

  const response = await fetch(
    ROUTES.equipmentHistories.delete(`${equipmentHistoryId}`), {
    headers: defaultAuthHeaders(),
    method: 'DELETE',
  }
  )
  return await handleApiResponse(response);
}

export async function addHistoryToEquipment(equipmentId: RecordId, history: CreateEquipmentHistoryAttributes): Promise<ApiResourceResponse<EquipmentHistoryAttributes>> {
  const response = await fetch(
    ROUTES.equipmentHistories.create(equipmentId), {
    headers: defaultAuthHeaders(),
    method: 'POST',
    body: JSON.stringify({ equipment_history: history })
  })

  return await handleApiResponse(response);
}
