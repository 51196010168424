import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, ButtonProps, ComponentWithAs, useDisclosure } from "@chakra-ui/react"
import React, { useRef } from "react"

export interface DeleteButtonProps extends ButtonProps {
  title?: string
  content?: string
  action?: string
  children?: React.ReactNode
  onConfirm?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const Deletebutton = ({
  title = "Supprimer",
  content = "Cette action est irréversible. Êtes vous sur ?",
  action = "Supprimer",
  onConfirm,
  children,
  ...props
}: DeleteButtonProps) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<any>()

  return (
    <>
      <Button onClick={onOpen} {...props}>
        {children}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {content}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme='red' onClick={onConfirm} ml={3}>
                {action}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}