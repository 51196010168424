import qs from "qs";
import { ROUTES } from "../constants/routes";
import { StructureFunctionAttributes, CreateStructureFunctionAttributes, StructureFunctionAttributesMeta } from "../interfaces/structureFunction";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";


export const getOrganizationStructureFunctionsKey = "OrganizationStructureFunctions"
export const getStructureFunctionKey = "StructureFunction"


export async function getOrganizationStructureFunctions(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<StructureFunctionAttributes, any, StructureFunctionAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.structureFunctions.list(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getStructureFunction(structureFunctionId: RecordId): Promise<ApiResourceResponse<StructureFunctionAttributes>> {
  const response = await fetch(
    ROUTES.structureFunctions.get(structureFunctionId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}


export function getStructureFunctionQuery(structureFunctionId: RecordId) {
  return {
    queryKey: [getStructureFunctionKey, structureFunctionId],
    queryFn: () => getStructureFunction(structureFunctionId)
  }
}

export async function createStructureFunction(organizationId: RecordId, structureFunction: CreateStructureFunctionAttributes): Promise<ApiResourceResponse<StructureFunctionAttributes>> {
  const response = await fetch(
    ROUTES.structureFunctions.create(organizationId), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ equipment_type: structureFunction }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateStructureFunction(structureFunctionId: RecordId, structureFunction: CreateStructureFunctionAttributes): Promise<ApiResourceResponse<StructureFunctionAttributes>> {
  const response = await fetch(
    ROUTES.structureFunctions.update(`${structureFunctionId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
      body: JSON.stringify({ equipment_type: structureFunction }),
    }
  )
  return await handleApiResponse(response);
}

export async function deleteStructureFunction(structureFunctionId: RecordId): Promise<ApiResourceResponse<StructureFunctionAttributes>> {
  const response = await fetch(
    ROUTES.structureFunctions.update(`${structureFunctionId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}
