// import "instantsearch.css/themes/algolia-min.css";

import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, TagLabel, TagLeftIcon, useBreakpointValue, useDisclosure } from '@chakra-ui/react';

import { TbFilter } from 'react-icons/tb';
import { useMemo } from 'react';
import { VehiculesListQueryParams } from '../Vehicules/VehiculesList';
import ReservationSearchForm from '@/screens/ReservationSearchForm/ReservationSearchForm';
import { vehiculesRoute } from '@/constants/routes';
import QueryString from 'qs';
import { useNavigate, useParams } from 'react-router-dom';


export interface NewReservationModalProps {
  params?: VehiculesListQueryParams | null
  title?: string
  onClose: () => void,
  onOpen?: () => void,
  isOpen: boolean,
}

export const NewReservationModal = ({
  params,
  title = "Nouvelle réservation",
  onClose,
  onOpen,
  isOpen,
}: NewReservationModalProps) => {
  const { orgId } = useParams()

  const paramsLength = useMemo(() => params ? Object.keys(params).length : 0, [params])

  const navigate = useNavigate()
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const onSearch = (params: VehiculesListQueryParams) => {
    console.log(params)
    onClose()
    navigate(vehiculesRoute(orgId) + '?' + QueryString.stringify(params))
  }
  

  const onReset = () => {
    onClose()
    navigate(vehiculesRoute(orgId))
  }
  
  return (
    <>
      <Modal size={{base: 'full', lg: 'lg'}} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {params && <ReservationSearchForm params={params} onSearch={onSearch} onReset={onReset}/>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
