import {
  Button,
  VStack,
  HStack,
  Box,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateUserStructureAttributes, UserStructureAttributes } from '@/interfaces/userStructure';
import { AsyncSelect } from '@/components/AsyncSelect/AsyncSelect';
import { useNanuqQuery } from '@/hooks/query';
import { createUserStructure } from '@/api/userStructure';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { getOrganizationUsers, getOrganizationUsersKey } from '@/api/user';
import { getOrganizationStructureFunctions, getOrganizationStructureFunctionsKey } from '@/api/structureFunction';

export interface UserStructureFormProps {
  orgId: RecordId,
  structureId: RecordId,
  onSave: (newUserStructure: ApiResourceResponse<UserStructureAttributes>) => Promise<any>
}

const CreateUserStructureForm = ({ orgId, structureId, onSave }: UserStructureFormProps) => {

  const initialValues: CreateUserStructureAttributes = {
    structure_id: structureId,
    // user_id: userStructure?.user_id || -1,
    // structure_function_id: userStructure?.structure_function_id || -1,
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (userStructurePayload: CreateUserStructureAttributes) => createUserStructure(structureId, userStructurePayload)
  })

  const onSubmit = async (
    values: CreateUserStructureAttributes,
    actions: FormikHelpers<CreateUserStructureAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  const structureQuery = useNanuqQuery([getOrganizationStructuresKey], () => getOrganizationStructures(orgId))
  const userQuery = useNanuqQuery([getOrganizationUsersKey], () => getOrganizationUsers(orgId))
  const structureFunctionQuery = useNanuqQuery([getOrganizationStructureFunctionsKey], () => getOrganizationStructureFunctions(orgId))

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="userStructure"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        <VStack spacing="3" alignItems={'stretch'}>
          <Box>
            <HStack>

              <Field name='structure_id'>
                {(formProps: FieldProps) => (
                  <AsyncSelect
                    query={structureQuery}
                    label="Structure"
                    {...formProps}
                  />
                )}
              </Field>
              <Field name='user_id'>
                {(formProps: FieldProps) => (
                  <AsyncSelect
                    query={userQuery}
                    label="Utilisateur"
                    dataLabel='full_name'
                    {...formProps}
                  />
                )}
              </Field>
              <Field name='structure_function_id'>
                {(formProps: FieldProps) => (
                  <AsyncSelect
                    query={structureFunctionQuery}
                    label="Fonction"
                    {...formProps}
                  />
                )}
              </Field>
            </HStack>
          </Box>

          <Button mt={6}
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.dirty}
            type='submit'
          >
            Mettre à jour
          </Button>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default CreateUserStructureForm