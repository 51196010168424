// export default {
//   heading: 'Arvo, -apple-system, system-ui, sans-serif',
//   body: '"Roboto", -apple-system, system-ui, sans-serif',
// }
export default {
  heading: 'Arvo, -apple-system, system-ui, sans-serif',
  body: '"Lato", -apple-system, system-ui, sans-serif',
}
// export default {
//   heading: 'Arvo, -apple-system, system-ui, sans-serif',
//   body: 'Poppins, -apple-system, system-ui, sans-serif',
// }
