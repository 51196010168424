import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, HStack } from "@chakra-ui/react"
import { Form, Formik, FormikHelpers } from "formik"
import { RecordId } from "@/interfaces/id"
import { NewMissionField } from "./NewMissionField"

export interface EquipmentReservationFormParams { 
  mission_id: RecordId
}

export interface EquipmentActionReservationModalProps {
  equipmentIds: RecordId[],
  onClose: () => void,
  onOpen: () => void,
  isOpen: boolean,
  onReservation: (equipmentIds: RecordId[], missionId: RecordId) => Promise<void>
}

export const EquipmentActionReservationModal = ({
  equipmentIds,
  onReservation,
  isOpen,
  onOpen,
  onClose
}: EquipmentActionReservationModalProps) => {


  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réserver</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik<EquipmentReservationFormParams>
              initialValues={{
                mission_id: ''
              }}
              onSubmit={(
                values: EquipmentReservationFormParams,
                { setSubmitting }: FormikHelpers<EquipmentReservationFormParams>
              ) => {
                console.log("On submit: ", values)
                if (values.mission_id) {
                  onReservation(equipmentIds, values.mission_id)
                }
                setSubmitting(false);
              }}
            >
              <Form>
                <VStack spacing={6} alignItems={'stretch'} justifyContent={'stretch'} flex={1}>
                  <NewMissionField name="mission_id" />
                  <HStack justifyContent={'space-between'} flex={1} alignContent={'center'}>
                    <Button onClick={onClose} variant='ghost'>Annuler</Button>
                    <Button colorScheme='brand' mr={3} type="submit">Réserver</Button>
                  </HStack>
                </VStack>
              </Form>
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}