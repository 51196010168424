
import { getEquipmentType, getEquipmentTypeKey, getEquipmentTypeQuery } from '@/api/equipmentType';
import Loader from '@/components/Loader/Loader';
import EditEquipmentTypeForm from '@/screens/EquipmentTypeForm/EditEquipmentTypeForm';
import { equipmentTypeRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentTypeAttributes, EquipmentTypeAttributesMeta } from '@/interfaces/equipmentType';
import { ApiResourceResponse } from '@/utils/api';
import { Box } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useNavigate, useParams } from 'react-router-dom';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentTypeQuery(params.equipmentTypeId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentTypeQuery(params.equipmentTypeId as RecordId))
    }
    return data
  }
)


export const EquipmentTypeEdit = () => {
  const { orgId, equipmentTypeId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentTypeAttributes, unknown, EquipmentTypeAttributesMeta>;

  const {
    data,
  } = useNanuqQuery([getEquipmentTypeKey, equipmentTypeId], () => equipmentTypeId ? getEquipmentType(equipmentTypeId) : null, { initialData })

  const navigate = useNavigate()

  const onSave = async (data: ApiResourceResponse<EquipmentTypeAttributes>) => {
    navigate(equipmentTypeRoute(orgId, data.data?.id))
  }

  return (
    <Box
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
    >
      {data?.data?.attributes ?
        <EditEquipmentTypeForm equipmentType={data?.data?.attributes} orgId={`${orgId}`} onSave={onSave} /> :
        <Loader />
      }
    </Box>
  );
}

export default EquipmentTypeEdit;