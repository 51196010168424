// import "instantsearch.css/themes/algolia-min.css";

import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, HStack, Kbd, Link, Tag, Text, VStack, useBreakpointValue } from '@chakra-ui/react';

import {
  Highlight
} from "react-instantsearch";
import { equipmentSummaryRoute, missionRoute, vehiculeSummaryRoute } from "@/constants/routes";
import { STATES_LABELS } from '@/utils/states';
import { MdOutlineLocationOn } from 'react-icons/md';
import { truncate } from '@/utils/string';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { RecordId } from '@/interfaces/id';
import { ApiResource } from '@/utils/api';
import { useLazyImage } from '@/hooks/useLazyImage';
import { VehiculesListQueryParams } from '@/pages/Vehicules/VehiculesList';
import QueryString from 'qs';
import { VehiculeReservationBoxButton } from '../EquipmentActionButton/VehiculeReservationBox/Button';
import { TbEye } from 'react-icons/tb';


export interface VehiculeCardProps {
  orgId: RecordId
  queryParams?: VehiculesListQueryParams
  data: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>
}

export const VehiculeCard = ({ orgId, data, queryParams }: VehiculeCardProps) => {

  const { attributes: vehicule, meta } = data

  const navigate = useNavigate()
  const { imageUrl, isImageLoaded } = useLazyImage(vehicule.image_url, vehicule.image_thumb_url)
  // const [searchParams, setSearchParams] = useSearchParams();

  const onReservation = (equipmentId: RecordId, missionId: RecordId) => {
    navigate(missionRoute(orgId, missionId))
  }

  return (
    <Box bg={'bg.subtle'}
      borderRadius={3}
      p={0}
      flex={1}
      alignContent={'flex-start'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      key={vehicule.id}
    >
      <HStack alignItems={'stretch'}>
        <Box bg={'bg.subtle'}
          borderRadius={3}
          bgImage={`url(${imageUrl})`}
          backgroundPosition="center center"
          backgroundSize="cover"
          p={0}
          flex={'1 0 150px'}
          maxW={'140px'}
          minW={{ base: '30%', md: '200px' }}
          alignContent={'flex-start'}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
        >
          {/* <Tag lineHeight={0} m={1} textTransform={'uppercase'} size='sm' fontSize={'xs'} colorScheme={STATES_LABELS[vehicule.current_availability].color}>{STATES_LABELS[vehicule.current_availability].label}</Tag> */}
        </Box>
        <VStack spacing={1} flex={1} alignItems={'stretch'} p={3}>
          <HStack alignContent={'flex-start'} justifyContent={'space-between'}>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}><Kbd noOfLines={1} color='fg.subtle'>{truncate(vehicule.serial, 20)}</Kbd></Text>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{meta?.equipment_type?.name}</Text>
          </HStack>
          <HStack
            flexWrap={'wrap'}
            align={'flex-start'}
            spacing={{ base: 1, md: 3 }}
            flexDirection={{ base: 'column', md: 'row'}}
            alignContent={'flex-start'}
            justifyContent={'space-between'}
          >
            <HStack
              spacing={3}
              alignContent={'flex-start'}
              as={RouterLink}
              to={vehiculeSummaryRoute(orgId, vehicule.slug) + '?' + QueryString.stringify(queryParams)}
            >
              <Text noOfLines={2} fontSize={'lg'}>{vehicule.name}</Text>
              <Text noOfLines={1} color={'fg.subtle'} fontSize={'md'}>{vehicule.manufacturer} {vehicule.model}</Text>
            </HStack>
            <HStack spacing={3} alignContent={'flex-start'}>
              {queryParams?.available_between && (<VehiculeReservationBoxButton
                equipmentId={vehicule?.id || ''}
                buttonLabel="Réserver"
                queryParams={queryParams}
                equipment={vehicule}
                variant={'outline'}
                textTransform={'uppercase'}
                size={'sm'}
                fontWeight={'bold'}
                colorScheme='brand'
                onReservationSuccess={onReservation}
              />)}
            </HStack>
          </HStack>
          <HStack spacing={3} alignContent={'flex-start'}>
            <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='outline' colorScheme={STATES_LABELS[vehicule.current_state].color}>{STATES_LABELS[vehicule.current_state].label}</Tag>

            <HStack spacing={1}>
              <Text color='fg.subtle'><MdOutlineLocationOn /></Text>
              <Text noOfLines={1} color='fg.subtle'>{meta?.current_location?.short_name}</Text>
            </HStack>
{/* 
            {queryParams?.available_between && (<VehiculeReservationBoxButton
              equipmentId={vehicule?.id || ''}
              buttonLabel="Réserver"
              queryParams={queryParams}
              equipment={vehicule}
              variant={'outline'}
              textTransform={'uppercase'}
              size={'sm'}
              fontWeight={'bold'}
              colorScheme='brand'
              onReservationSuccess={onReservation}
            />)} */}
          </HStack>
          {/* <HStack spacing={3} alignContent={'flex-start'}>
            <Button
              leftIcon={<TbEye />}
              as={RouterLink}
              size={'sm'}
              variant={'secondary'}
              textTransform={'uppercase'}
              to={vehiculeSummaryRoute(orgId, vehicule.slug) + '?' + QueryString.stringify(queryParams)}
            >
              Détails
            </Button>
          </HStack> */}
        </VStack>
      </HStack>
    </Box>
  );

}
