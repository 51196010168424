
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { useNanuqQuery } from '@/hooks/query';
import { useParams } from 'react-router-dom';
import { StructuresList } from './StructuresList';


export const StructuresPage = () => {

  const { orgId } = useParams()

  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery([getOrganizationStructuresKey, orgId], () => orgId ? getOrganizationStructures(orgId) : null)

  return (
    <PageWithTitle
      title="Structures"
      actions={[
        // <Button size={'sm'} as={Link} variant="primary" to={equipmentsNewRoute(orgId)}>Nouveau</Button>
      ]}
    >

      <BoxSection px={0}>
        <StructuresList />
      </BoxSection>
    </PageWithTitle>
  );
}

export default StructuresPage;