
import { BoxSection } from '@/components/BoxSection/BoxSection';
import NewMissionForm from '@/screens/MissionForm/NewMissionForm';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { missionsNewRoute, missionsRoute } from '@/constants/routes';
import { MissionAttributes } from '@/interfaces/mission';
import { ApiResourceResponse } from '@/utils/api';
import { useNavigate, useParams } from 'react-router-dom';


export const NewMission = () => {

  const { orgId } = useParams()
  const navigate = useNavigate()

  const onSave = async (data: ApiResourceResponse<MissionAttributes>) => {
    navigate(missionsRoute(orgId))
  }

  return (
    <PageWithTitle
      title="Nouvelle Mission"
      breadcrumbs={[
        {label: 'Missions', url: missionsRoute(orgId)},
        {label: 'Nouveau', url: missionsNewRoute(orgId)},
      ]}
      actions={[
      ]}
    >

      <BoxSection>
        <NewMissionForm orgId={`${orgId}`} onSave={onSave}/>
      </BoxSection>
    </PageWithTitle>
  );
}

export default NewMission;