import { STATES_LABELS } from "@/utils/states";
import { Badge, Button, Checkbox, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { useRefinementList } from "react-instantsearch";

export default function EquipmentStatusRefinementList(props) {
  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList(props);

  return (
    <>
      <VStack alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        {items.map((item) => (
          <label key={item.label}>
            <HStack>
            <Checkbox
              isChecked={item.isRefined}
              onChange={() => refine(item.value)}
            />
            {/* <Text noOfLines={1}>{item.label}</Text> */}
            <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} colorScheme={STATES_LABELS[item.value].color}>{STATES_LABELS[item.value].label}</Tag>
            <Badge size='sm'>{item.count}</Badge>
            </HStack>
          </label>
        ))}
      </VStack>
      <Button size={'sm'} onClick={toggleShowMore} hidden={!canToggleShowMore} isDisabled={!canToggleShowMore}>
        {isShowingMore ? 'Voir moins' : 'Voir plus'}
      </Button>
    </>
  );
}

