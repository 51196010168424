


export const ROLES_LABELS = {
  viewer: "Lecteur",
  member: "Gestionnaire",
  owner: "Administrateur",
}

export const ROLES_TYPES_SELECT = Object.keys(ROLES_LABELS).map(t => ({ label: ROLES_LABELS[t], value: t }))

