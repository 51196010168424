import { Button } from "@chakra-ui/react";
import React from "react";
import { useClearRefinements, ClearRefinementsProps } from "react-instantsearch";

export interface CustomClearRefinementsProps extends ClearRefinementsProps {
  text?: string
}

export function ClearRefinements({
  text = "Effacer les filtres",
  ...props
}: CustomClearRefinementsProps) {
  const { canRefine, refine } = useClearRefinements(props);

  return (
    <Button size={'sm'} variant='link' isDisabled={!canRefine} onClick={refine}>
      {text}
    </Button>
  );
}

