import { EquipmentAttributes, EquipmentAttributesMeta } from "@/interfaces/equipment"
import { ButtonGroupProps, HStack, Text, VStack } from "@chakra-ui/react"
import { ApiResource } from "@/utils/api"
import StateBadge from "../../components/StateBadge/StateBadge"
import { useOrganizations } from "@/contexts/useOrganizationsContext"
import { formatDateShort } from "@/utils/datesFormat"
import { parseISO } from "date-fns"
import { MissionAsyncCard } from "../MissionAsyncCard/MissionAsyncCard"
import { EquipmentActionReservationButton } from "../EquipmentActionButton/Reservation/Button"
import { EquipmentActionButtonForEquipment } from "../EquipmentActionButton/Action/ButtonForEquipment"


export interface EquipmentSidebarProps extends ButtonGroupProps {
  equipment?: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>,
}

export const EquipmentSidebar = ({
  equipment,
  ...props
}: EquipmentSidebarProps) => {

  const { ability, selectedOrganizationId } = useOrganizations()

  return (
    <VStack
      borderRadius={6}
      // borderColor={'bg.accent'}
      // borderWidth={1}
      px={6}
      py={3}
      flexGrow={1}
      flexShrink={0}
      flexBasis={{ base: '100%', md: '250px' }}
      spacing={6}
      alignItems={'stretch'}
      // bg={'bg.subtle'}
    >
        {equipment?.meta && (
        <VStack spacing={6} flex={1} alignItems={'stretch'}>
          
          <VStack flex={1} alignItems={'stretch'}>
            <HStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
              <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>État</Text>
              <StateBadge minW={'100px'} borderRadius={3} variant={'solid'} size='lg' state={equipment.attributes?.current_state} />
            </HStack>
            {equipment.meta?.checker?.full_name && equipment.attributes?.checked_at ? (
                <Text color='fg.muted'>Vérifié le {formatDateShort(parseISO(equipment.attributes?.checked_at || ''))} par {equipment.meta?.checker?.full_name}</Text>
              ) : (
                <Text color='fg.muted'>Jamais vérifié</Text>
              )
            }
          </VStack>
          <hr />

          <VStack flex={1} alignItems={'stretch'}>
            <HStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
              <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Disponibilité</Text>
              <StateBadge minW={'100px'} borderRadius={3} variant={'solid'} size='lg' state={equipment.attributes?.current_availability} />
            </HStack>
            <VStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
              {equipment.meta.active_missions.length > 0 && (<>
                <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Mission en cours</Text>
                {equipment.meta.active_missions.map(m => <MissionAsyncCard missionId={m.id} orgId={selectedOrganizationId} key={m.id}/>)}
              </>)}
            </VStack>
            <VStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
                <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Prochaine mission</Text>
                {equipment.meta.next_mission?.name ? (
                  <MissionAsyncCard missionId={equipment.meta.next_mission.id} orgId={selectedOrganizationId} key={equipment.meta.next_mission.id}/>
                ) : (
                  <Text textAlign={'center'} color={'fg.muted'} fontSize='sm'>Aucune mission prévue</Text>
                )}
            </VStack>
          </VStack>

          <hr />
          
          {ability?.can('update', 'equipment', equipment.attributes) && <VStack flex={1} alignItems={'stretch'}>
            <Text pb={1} color={'fg.muted'} fontSize='sm' textTransform={'uppercase'}>Actions</Text>
            <HStack flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
              <EquipmentActionReservationButton
                isDisabled={ability?.can('update', 'equipment', equipment.attributes) !== true}
                equipmentIds={[equipment.id]}
                flex={1}
                colorScheme='brand'
              />
              <EquipmentActionButtonForEquipment
                isDisabled={ability?.can('update', 'equipment', equipment.attributes) !== true}
                equipment={equipment.attributes}
                flex={2}
                allowed_transitions={[...equipment.meta.state_events, ...equipment.meta.availability_events]}
              />
            </HStack>
          </VStack>}
        </VStack>
      )}
    </VStack>
  )
}