import React, { useMemo } from "react"
import { ApiErrorResponse } from "../../utils/api"
import ErrorBox from "../ErrorBox/ErrorBox"
import Loader from "../Loader/Loader"
import { QueryStatus } from "@tanstack/react-query"


interface RemoteFetchContainerProps<T> {
  error: ApiErrorResponse | null
  errorMessage?: string
  isLoading: boolean
  data: T | undefined
  status: QueryStatus
  render: (data: T) => React.ReactElement
}

export function RemoteFetchContainer<T>({
  error,
  errorMessage,
  data,
  status,
  render
}: Readonly<RemoteFetchContainerProps<T>>) {

  const renderOnStatus = useMemo(() => {
    switch (status) {
      case 'error':
        return <ErrorBox error={error} message={errorMessage || error?.message || ''} />
      case 'pending':
        if (data) {
          return render(data as T)
        } else {
          return <Loader />
        }
      case 'success':
        if (data) {
          return render(data as T)
        } else {
          return <Loader />
        }
    }
  }, [error, errorMessage, data, status, render])

  return <>
    {/* <pre>
      <div>error={JSON.stringify(error)}</div>
      <div>errorMessage={JSON.stringify(errorMessage)}</div>
      <div>isLoading={JSON.stringify(isLoading)}</div>
      <div>data={JSON.stringify(data)}</div>
      <div>status={JSON.stringify(status)}</div>
    </pre> */}
    {renderOnStatus}
  </>
}