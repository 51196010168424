import { format, formatDistanceStrict, formatDistanceToNowStrict, getDayOfYear, parseISO } from 'date-fns'
import locale from 'date-fns/locale/fr'


const formatDistanceLocale: {[key: string]: string} = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
}

function formatDistance(token: any, count: string, options: any) {
  options = options || {}

  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}

export const formatDistanceShort = (date: number | Date, opts: any = {}) => formatDistanceToNowStrict(date, {
  addSuffix: true,
  locale: {
    ...locale
  },
  ...opts,
})


export const formatDistanceBetweenShort = (dateFrom: number | Date, dateTo: number | Date, opts: any = {}) => formatDistanceStrict(dateFrom, dateTo , {
  addSuffix: false,
  locale: {
    ...locale
  },
  ...opts,
})


export const formatDateShortWithoutYear = (date: number | Date, opts = {}, pattern = 'd LLL') => format(
  date,
  pattern,
  {
  ...opts,
  locale: {
    ...locale,
    formatDistance,
  },
})

export const formatDateShortWithYear = (date: number | Date, opts = {}) => format(
  date,
  'd LLL yy',
  {
  ...opts,
  locale: {
    ...locale,
    formatDistance,
  },
})

export const formatDateShort = (date: Date, opts = {}, pattern = 'd LLL HH:mm') => {
  const curr = new Date()
  if (curr.getFullYear() === date.getFullYear() && getDayOfYear(curr) - getDayOfYear(date) < 30) {
    return formatDateShortWithoutYear(date, opts, pattern)
  } else if (curr.getFullYear() === date.getFullYear()) {
    return formatDateShortWithoutYear(date, opts, pattern)
  } else {
    return formatDateShortWithYear(date, opts)
  }
}

export const parseAndFormatDateShort = (dateString: string | null | undefined, fallback = ' - ', opts = {}) => {
  const curr = new Date()
  if (!dateString) {
    return fallback
  }
  const date = parseISO(dateString)
  if (curr.getFullYear() === date.getFullYear() && getDayOfYear(curr) - getDayOfYear(date) < 30) {
    return formatDateShortWithoutYear(date, opts, 'd LLL HH:mm')
  } else if (curr.getFullYear() === date.getFullYear()) {
    return formatDateShortWithoutYear(date, opts)
  } else {
    return formatDateShortWithYear(date, opts)
  }
}


export const toLocalDateTimeFormat = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm")
}
