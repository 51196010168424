import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { CreateUserAttributes, ShortUserAttributes } from '@/interfaces/user';
import { createFromEmail } from '@/api/user';

export interface InviteUserFormProps {
  user?: Partial<CreateUserAttributes>,
  orgId: RecordId,
  onSave: (newUser: ApiResourceResponse<ShortUserAttributes>) => Promise<any>
}

const NewInviteUserForm = ({ user, orgId, onSave }: InviteUserFormProps) => {


  const initialValues: CreateUserAttributes = {
    email: user?.email || '',
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (userPayload: CreateUserAttributes) => createFromEmail(userPayload)
  })

  const onSubmit = async (
    values: CreateUserAttributes,
    actions: FormikHelpers<CreateUserAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="user"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />

        <VStack spacing="6" alignItems={'flex-start'}>
          <Field name='email'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.email && form.touched.email ? true : false}>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input {...field} isRequired id='email' type='text' placeholder='Email' />
                <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='first_name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.first_name && form.touched.first_name ? true : false}>
                <FormLabel htmlFor='first_name'>Prénom</FormLabel>
                <Input {...field} isRequired id='first_name' type='text' placeholder='Prénom' />
                <FormErrorMessage>{form.errors.first_name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='last_name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.last_name && form.touched.last_name ? true : false}>
                <FormLabel htmlFor='last_name'>Nom</FormLabel>
                <Input {...field} isRequired id='last_name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.last_name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <HStack w={'100%'} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
            <Button
              variant="primary"
              isLoading={props.isSubmitting}
              isDisabled={!props.dirty || !props.isValid}
              type='submit'
              w={'100%'}
            >
              Créer
            </Button>
          </HStack>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default NewInviteUserForm