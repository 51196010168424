import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react"
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { AsyncSelect, OptionList } from "@/components/AsyncSelect/AsyncSelect"
import { useNanuqQuery } from "@/hooks/query"
import { getOrganizationMissions, getOrganizationMissionsKey } from "@/api/mission"
import { EquipmentReservationFormParams } from "./Modal";
import NewMissionForm from "@/screens/MissionForm/NewMissionForm";
import { MissionAttributes } from "@/interfaces/mission";
import { ApiResourceResponse } from "@/utils/api";
import { useUser } from "@/hooks/auth";


export const NewMissionField = (props: { name: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { orgId } = useParams()
  const missionQuery = useNanuqQuery([getOrganizationMissionsKey], () => getOrganizationMissions(orgId || ''))
  const [field, meta] = useField(props);

  const form = useFormikContext<EquipmentReservationFormParams>();
  const [additional, setAdditional] = useState<OptionList>([{ label: 'Nouvelle mission', value: 'new' }])
  const { data: user } = useUser()
  
  const {
    values: { mission_id },
    touched,
    setFieldValue,
    setValues,
  } = form

  useEffect(() => {
    console.log("Mission id = ", { mission_id, touched: touched.mission_id, isOpen })
    if (
      mission_id === 'new' && !isOpen
    ) {
      onOpen()
    }
  }, [mission_id, touched.mission_id, onOpen, isOpen]);

  const onMissionSave = async (newMission: ApiResourceResponse<MissionAttributes, unknown, unknown>) => {
    console.log("New mission saved: ", newMission)

    setAdditional([
      { label: 'Nouvelle mission', value: 'new' },
      { label: newMission.data.attributes.name, value: `${newMission.data.id}` },
    ])

    await setFieldValue('mission_id', `${newMission.data.id}`)
    await setValues({ mission_id: `${newMission.data.id}` })

    onClose()
  }

  const onModalClose = () => {
    setFieldValue('mission_id', null)
    onClose()
  }

  const initialMission: Partial<MissionAttributes> = {
    name: `Réservation de ${user?.attributes?.full_name}`,
    mission_type: 'Autre',
    structure_id: (user?.meta?.user_structures && user?.meta?.user_structures?.length > 0 ? user?.meta?.user_structures[0].structure_id : undefined)
  }

  return (<>
      <AsyncSelect
        query={missionQuery}
        label="Mission"
        additionalOptions={additional}
        field={field}
        form={form}
        meta={meta}
      />
      <Modal size='xl' isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Créer une nouvelle mission</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <NewMissionForm orgId={`${orgId}`} onSave={onMissionSave} mission={initialMission} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
