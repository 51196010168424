import { RideAttributes, RideAttributesMeta } from "@/interfaces/ride";
import { ROUTES } from "../constants/routes";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, formDataHeaders, handleApiResponse } from "../utils/api";
import QueryString from "qs";
import axios, { AxiosProgressEvent } from "axios";


export const getOrganizationRidesKey = "OrganizationRides"
export const getRideKey = "Ride"

export async function getOrganizationRides(organizationId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<RideAttributes, unknown, RideAttributesMeta>> {
  const params = QueryString.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.rides.list(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getRide(rideId: RecordId): Promise<ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>> {
  const response = await fetch(
    ROUTES.rides.get(rideId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export function getRideQuery(rideId: RecordId) {
  return {
    queryKey: [getRideKey, rideId],
    queryFn: () => getRide(rideId)
  }
}

// export async function createRide(organizationId: RecordId, mission: CreateRideAttributes): Promise<ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>> {
//   const response = await fetch(
//     ROUTES.rides.create(organizationId), {
//     headers: defaultAuthHeaders(),
//     method: 'POST',
//     body: JSON.stringify({ mission }),
//   }
//   )
//   return await handleApiResponse(response);
// }

// export async function updateRide(rideId: RecordId, mission: CreateRideAttributes): Promise<ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>> {
//   const response = await fetch(
//     ROUTES.rides.update(`${rideId}`), {
//     headers: defaultAuthHeaders(),
//     method: 'PATCH',
//     body: JSON.stringify({ mission }),
//   }
//   )
//   return await handleApiResponse(response);
// }

export async function deleteRide(rideId: RecordId): Promise<ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>> {
  const response = await fetch(
    ROUTES.rides.update(`${rideId}`), {
    headers: defaultAuthHeaders(),
    method: 'DELETE',
  }
  )
  return await handleApiResponse(response);
}


export async function createRideFromFormData(
  equipmentId: RecordId,
  body: FormData,
  onUploadProgress: (axiosProgressEvent: AxiosProgressEvent) => void
): Promise<ApiResourceResponse<RideAttributes>> {

  const response = await axios.post(ROUTES.rides.createForEquipment(equipmentId), body, {
    headers: formDataHeaders(),
    onUploadProgress,
  });  

  return response.data;
}

export async function finishRideFromFormData(
  rideId: RecordId,
  body: FormData,
  onUploadProgress: (axiosProgressEvent: AxiosProgressEvent) => void
): Promise<ApiResourceResponse<RideAttributes>> {
  const response = await axios.patch(ROUTES.rides.finish(rideId), body, {
    headers: formDataHeaders(),
    onUploadProgress,
  });

  return response.data;
}

export async function forceFinishRide(rideId: RecordId): Promise<ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>> {
  const response = await fetch(
    ROUTES.rides.force_finish(`${rideId}`), {
    headers: defaultAuthHeaders(),
    method: 'PATCH',
  }
  )
  return await handleApiResponse(response);
}

export async function exportRides(orgId: RecordId): Promise<ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>> {
  const response = await fetch(
    ROUTES.rides.export(`${orgId}`), {
    headers: defaultAuthHeaders(),
    method: 'POST',
  }
  )
  return await handleApiResponse(response);
}
