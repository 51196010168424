
import {
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { Logo } from '@/components/Logo/Logo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@/hooks/auth';
import { LOCALSTORAGE_REDIRECT_KEY, organizationsRoute } from '@/constants/routes';
import { ResetPasswordForm } from './ResetPasswordForm';


export function ResetPassword() {
  const navigate = useNavigate()
  const { data } = useUser()


  const onLogin = () => {
    const wantedRedirect = localStorage.getItem(LOCALSTORAGE_REDIRECT_KEY)
    if (wantedRedirect && wantedRedirect.length > 0) {
      console.log(`Removing ${wantedRedirect} for redirect`)
      localStorage.removeItem(LOCALSTORAGE_REDIRECT_KEY)
      navigate(wantedRedirect)
    } else {
      navigate(organizationsRoute(data?.attributes?.organization_id))
    }
  }

  // useEffect(() => {

  //   if (data?.attributes?.email) {
  //     onLogin()
  //   }
  // }, [data, onLogin])

  const [searchParams, setSearchParams] = useSearchParams();
  const reset_password_token = searchParams.get("reset_password_token")
  const email = searchParams.get("email")

  console.log({ reset_password_token, email })

  return (
    <Flex
      as="section"
      direction={{ base: 'column', lg: 'row' }}
      height="100vh"
      bg={useColorModeValue('gray.50', 'gray.900')}
      overflowY="auto"
    >
      <Container py="8" px={0} flex="1">
        <Stack spacing={{ base: '8', lg: '6' }}>
          <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
              <Stack spacing="6">
                <VStack justifyContent="center">
                  <Logo withIcon maxW='8em' />
                </VStack>
                <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                  <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                    Nouveau mot de passe
                  </Heading>
                </Stack>
              </Stack>
              <Box
                py={{ base: '4', sm: '8' }}
                px={{ base: '4', sm: '10' }}
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius={{ base: 'xl', sm: 'xl' }}
              >
                {reset_password_token?.length && email ?.length && (
                  <ResetPasswordForm onLogin={onLogin} email={email} reset_password_token={reset_password_token} /> || <p>Une erreur s'est produite</p>
                )}
              </Box>
            </Stack>
          </Container>
        </Stack>
      </Container>
    </Flex>

  )
}
