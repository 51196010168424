
import { BoxSection } from '@/components/BoxSection/BoxSection';
import NewEquipmentTypeForm from '@/screens/EquipmentTypeForm/NewEquipmentTypeForm';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { equipmentTypesNewRoute, equipmentTypesRoute, settingsOrganizationRoute } from '@/constants/routes';
import { EquipmentTypeAttributes } from '@/interfaces/equipmentType';
import { ApiResourceResponse } from '@/utils/api';
import { useNavigate, useParams } from 'react-router-dom';


export const NewEquipmentType = () => {

  const { orgId } = useParams()
  const navigate = useNavigate()

  const onSave = async (data: ApiResourceResponse<EquipmentTypeAttributes>) => {
    navigate(equipmentTypesRoute(orgId))
  }

  return (
    <PageWithTitle
      title="Nouveau type de matériel"
      breadcrumbs={[
        {label: 'Paramètres', url: settingsOrganizationRoute(orgId)},
        {label: 'Types de matériel', url: equipmentTypesRoute(orgId)},
        {label: 'Nouveau', url: equipmentTypesNewRoute(orgId)},
      ]}
      actions={[
      ]}
    >

      <BoxSection>
        <NewEquipmentTypeForm orgId={`${orgId}`} onSave={onSave}/>
      </BoxSection>
    </PageWithTitle>
  );
}

export default NewEquipmentType;