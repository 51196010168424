
import { getStructure, getStructureKey, getStructureQuery } from '@/api/structure';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { TabNav } from '@/components/TabNav/TabNav';
import { TabNavButton } from '@/components/TabNavButton/NavButton';
import { equipmentsForStructureRoute, missionsForStructureRoute, planningForStructureRoute, structureRoute, structureSummaryRoute, structuresRoute, usersForStructureRoute, usersRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { StructureAttributes, StructureAttributesMeta } from '@/interfaces/structure';
import { ApiResourceResponse } from '@/utils/api';
import { QueryClient } from '@tanstack/react-query';
import { TbArrowLoopRight2, TbBoxSeam, TbBuildingWarehouse, TbCalendar, TbFriends, TbTruck, TbUsersGroup } from 'react-icons/tb';
import { LoaderFunction, NavLink, Outlet, useLoaderData, useParams } from 'react-router-dom';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getStructureQuery(params.structureId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getStructureQuery(params.structureId as RecordId))
    }
    return data
  }
)

export const StructurePage = () => {

  const { orgId, structureId } = useParams()
  const { ability } = useOrganizations()
  const initialData = useLoaderData() as ApiResourceResponse<StructureAttributes, unknown, StructureAttributesMeta>;

  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery([getStructureKey, structureId], () => structureId ? getStructure(structureId) : null)

  return (
    <PageWithTitle
      isLoading={isLoading}
      breadcrumbs={[
        { label: 'Structures', url: structuresRoute(orgId) },
        { label: data?.data?.attributes?.name, url: structureRoute(orgId, structureId) },
      ]}
      title={data?.data?.attributes?.name}
      // subtitle={data?.data?.attributes?.name}
      actions={[
      ]}
    >

      <BoxSection px={0} py={0}>
        <TabNav>
          {ability?.can('show', 'structure') && (
            <TabNavButton icon={TbBuildingWarehouse} label='Résumé' as={NavLink} to={structureSummaryRoute(orgId, structureId)} />
          )}
          {ability?.can('reserve', 'structure', data?.data.attributes) && (
            <TabNavButton icon={TbCalendar} label='Planning' as={NavLink} to={planningForStructureRoute(orgId, structureId)} />
          )}
          {ability?.can('list', 'equipment') && (
            <TabNavButton icon={TbTruck} label='Véhicules' as={NavLink} to={equipmentsForStructureRoute(orgId, structureId)} />
          )}
          {ability?.can('list', 'mission') && (
            <TabNavButton icon={TbArrowLoopRight2} label='Missions' as={NavLink} to={missionsForStructureRoute(orgId, structureId)} />
          )}
          {ability?.can('list', 'user') && (
            <TabNavButton icon={TbUsersGroup} label='Équipe' as={NavLink} to={usersForStructureRoute(orgId, structureId)} />
          )}
        </TabNav>

        <Outlet />
      </BoxSection>
    </PageWithTitle>
  );
}

export default StructurePage;