
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Button, FormHelperText, HStack, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useState } from 'react';
import { RideAttributes } from '@/interfaces/ride';
import { TbGasStation } from 'react-icons/tb';


export interface StepVehiculeGasProps {
  equipment: EquipmentAttributes,
  ride?: RideAttributes | null,
  currentState: TakeoutState,
  onNext: (state: TakeoutState) => void
}

export const StepVehiculeGas = ({
  equipment,
  ride,
  currentState,
  onNext,
}: StepVehiculeGasProps) => {

  const { equipmentId, missionId } = useParams()
  const { data: user } = useUser();
  const { ability, selectedOrganizationId } = useOrganizations()
  const [gasLevel, setGasLevel] = useState<number>(currentState.current_gas_level || 0)

  const onSubmit = () => {
    console.log("validate", gasLevel)
    currentState = {
      ...currentState,
      current_gas_level: gasLevel
    }
    onNext(currentState)
  }

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={6}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius={4}
        borderColor={'bg.accent'}
        borderWidth={1}
        borderBottomRadius={4}
        textAlign={'left'}
      >
          <VStack spacing={3} flex={1} alignItems={'stretch'}>
            <Text>Veuillez renseigner le niveau de carburant approximatif</Text>
            <VStack flex={1} alignItems={'stretch'} justifyContent={'flex-start'}>

            <Box pb={6}>
              <Slider
                height={'10px'}
                size={'lg'}
                aria-label='slider-ex-6'
                onChange={(val) => setGasLevel(val)}
                max={100}
                step={20}
                colorScheme={'brand'}
              >
                <SliderMark
                  value={gasLevel}
                  textAlign='center'
                  // bg='red.500'
                  // color='white'
                  fontSize={'lg'}
                  mt='4'
                  ml='-7'
                  w='16'
                >
                  {gasLevel}%
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb bg={'brand'} boxSize={8} border={'3px solid #fff'} bgColor={'accent'}>
                  <Box color='fg.inverted'>
                    <TbGasStation />
                  </Box>
                </SliderThumb>
              </Slider>
            </Box>
            </VStack>
          </VStack>
      </Box>
      <Button colorScheme='brand' onClick={onSubmit}>Valider</Button>
    </VStack>
  );
}
