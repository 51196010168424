import { As, Badge, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react'
import * as React from 'react'

interface NavSubButtonProps extends ButtonProps {
  icon?: As
  label: string
  counter?: string | number | null
  to?: string
}

export const NavSubButton = (props: NavSubButtonProps) => {
  const { icon, label, counter, to, ...buttonProps } = props
  return (
    <Button
      borderLeft={"2px solid rgba(0, 0, 0, .1)"}
      _activeLink={{
        borderLeftColor: 'red.900',
        color: 'red.900',
      }}
      borderRadius={0}
      pl={10}
      py={2}
      ml={'2px'}
      variant="ghost"
      to={to}
      w={"100%"}
      justifyContent="start"
      {...buttonProps}
      >
      <HStack spacing="3">
        <HStack spacing="3">
          {icon  && <Icon as={icon} boxSize="6" color="subtle" />}
          <Text fontSize={'small'}>{label}</Text>
        </HStack>
        {counter && <Badge alignSelf={"flex-end"}>{counter}</Badge>}
      </HStack>
    </Button>
  )
}