import {
  Button,
  VStack,
  HStack,
  Box,
  FormControl,
  Input,
  FormErrorMessage,
  FormLabel,
  IconButton,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { EditUserStructureAttributes, UserStructureAttributes } from '@/interfaces/userStructure';
import { AsyncSelect } from '@/components/AsyncSelect/AsyncSelect';
import { useNanuqQuery } from '@/hooks/query';
import { createUserStructure, getUserUserStructuresKey, updateUserStructure } from '@/api/userStructure';
import { RecordId } from '@/interfaces/id';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { getOrganizationUsers, getOrganizationUsersKey } from '@/api/user';
import { getOrganizationStructureFunctions, getOrganizationStructureFunctionsKey } from '@/api/structureFunction';
import { MdSave } from 'react-icons/md';
import { ROLES_TYPES_SELECT } from '@/constants/roles';
import { Select } from 'chakra-react-select';

export interface UserStructureFormProps {
  orgId: RecordId,
  initialUserStructure: UserStructureAttributes,
  onSave: (newUserStructure: ApiResourceResponse<UserStructureAttributes>) => Promise<any>
}

const EditUserStructureForm = ({ initialUserStructure, onSave, orgId }: UserStructureFormProps) => {

  const queryClient = useQueryClient()

  const initialValues: EditUserStructureAttributes = {
    structure_id: initialUserStructure.structure_id,
    user_id: initialUserStructure.user_id,
    role: initialUserStructure.role,
    structure_function_id: initialUserStructure.structure_function_id,
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (userStructurePayload: EditUserStructureAttributes) => updateUserStructure(initialUserStructure.id, userStructurePayload),
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({ queryKey: [getUserUserStructuresKey, initialUserStructure?.user_id] })
      queryClient.invalidateQueries({ queryKey: [getOrganizationStructureFunctionsKey] })
      queryClient.invalidateQueries({ queryKey: [getOrganizationStructuresKey] })
    }
  })

  const onSubmit = async (
    values: EditUserStructureAttributes,
    actions: FormikHelpers<EditUserStructureAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  const structureQuery = useNanuqQuery([getOrganizationStructuresKey], () => getOrganizationStructures(orgId))
  const userQuery = useNanuqQuery([getOrganizationUsersKey], () => getOrganizationUsers(orgId))
  const structureFunctionQuery = useNanuqQuery([getOrganizationStructureFunctionsKey], () => getOrganizationStructureFunctions(orgId))

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="userStructure"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        <VStack spacing="3" alignItems={'stretch'}>
          <Box>
            <HStack>

              <Field name='structure_id'>
                {(formProps: FieldProps) => (
                  <AsyncSelect
                    query={structureQuery}
                    dataLabel='short_name'
                    label="Structure"
                    {...formProps}
                  />
                )}
              </Field>

              <Field name='role'>
                {({ field, form }: FieldProps) => (
                  <FormControl maxW={'150px'} isInvalid={form.errors.role && form.touched.role ? true : false}>
                    <FormLabel htmlFor='role'>Role</FormLabel>
                    <Select
                      isRequired
                      defaultValue={ROLES_TYPES_SELECT.find(e => e.value === field.value)}
                      isInvalid={form.errors.mission_type ? true : false}
                      placeholder={`Sélectionner`}
                      name={field.name}
                      options={ROLES_TYPES_SELECT}
                      value={ROLES_TYPES_SELECT.find(e => e.value === field.value)}
                      onChange={(opt) => form.setFieldValue(field.name, opt?.value)}
                    />
                    <FormErrorMessage>{form.errors.role as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='structure_function_id'>

                {(formProps: FieldProps) => (
                  <FormControl maxW={'180px'} isInvalid={formProps.form.errors.role && formProps.form.touched.role ? true : false}>

                  <AsyncSelect
                    query={structureFunctionQuery}
                    label="Fonction"
                    {...formProps}
                    />
                  </FormControl>
                  )}
              </Field>
              <IconButton mt={6}
                variant="primary"
                isLoading={props.isSubmitting}
                disabled={!props.dirty}
                type='submit'
                aria-label='save'
                icon={<MdSave />}
              >
              </IconButton>
            </HStack>
          </Box>

          
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default EditUserStructureForm