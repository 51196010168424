

export const MISSION_TYPES = {
  DPS: 'DPS',
  Autre: 'Autre'
}

export const MISSION_CODES = {
  A204a: "A204 Poste de secours",
  A203b: "A203 Situation d'exception et actions d'urgence",
  A313: "A313 SAMU Social et équipes mobiles",
  A101: "A101 Jeunesse, éducation, diffusion des principes et valeurs",
  A102: "A102 Coordination des UL et délégation",
  A104: "A104 Pole Santé",
  A105: "A105 Disposition de soutien psycho social aux bénévoles",
  Entretien: "Entretien, Garage, CT",
  Liaison: "Liaison",
  A201: "A201 Formation Urgence et Secourisme grand public",
  A202: "A202 Formation Urgence et Secourisme interne",
  A203a: "A203 Recrutement soutiens et formation des bénévoles",
  A204b: "A204 Action Logisitique",
  A205: "A205 Réseau de secours",
  A301: "A301 Aide alimentaire",
  A302: "A302 Aide textile",
  A303: "A303 Bric à brac",
  A304: "A304 Croix rouge sur roues",
  A305: "A305 Transport social et action de mobilité",
  A306: "A306 Accès aux microcrédit et à l'éducation budgétaire",
  A307: "A307 Chèques d'accompagnements personnalisés",
  A308: "A308 Aides financières",
  A309: "A309 Action de soutien à la parentalité",
  A310: "A310 accès aux soins et à l'hygiène",
  A311: "A311 Programme d'éducation pour la santé",
  A312: "A312 Accueil de jour et de nuit",
  A314: "A314 Domiciliation",
  A315: "A315 Apprentissage de la langue et savoirs de base",
  A316: "A316 Actions spécifiques auprès des migrants",
  A317: "A317 Action Prison - Justice",
  A318: "A318 Action spécifiques auprès de personnes âgées",
  A319: "A319 aide au départ en vacances",
  A320: "A320 Autres accès à la culture et aux loisirs",
  A321: "A321 Plateforme téléphonie sociale",
  A322: "A322 Action de rétablissement des liens familiaux",
  A325: "A325 Formations action sociale internes",
  A326: "A326 Braderie et boutique éphémères",
  A327: "A327 Dispositif d'intervention en campements et bidonvilles",
  A328: "A328 Actions auprès de personnes en situation de handicap",
  '004': "004 Charges et produits commun à répartir",
}

export const MISSION_TYPES_SELECT = Object.keys(MISSION_CODES).map(t => ({ label: MISSION_CODES[t], value: t }))

