import { deleteOrganizationsUsers, getOrganizationsUsers, getOrganizationsUsersKey } from "@/api/organization"
import { useNanuqQuery } from "@/hooks/query"
import { OrganizationsUserAttributes } from "@/interfaces/organization"
import { Stack } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { OrganizationsUsersItem } from "./OrganizationsUsersItem"
import UserDetailModal from "@/screens/UserDetailModal/UserDetailModal"
import { useState } from "react"
import { ApiResource } from "@/utils/api"

export const OrganizationsUsersList = ({
  orgId
}: {
  orgId: string
}) => {

  const {
    isLoading,
    data,
    error,
    refetch,
  } = useNanuqQuery(
    [getOrganizationsUsersKey, orgId],
    () => getOrganizationsUsers(orgId)
  )

  const queryClient = useQueryClient();

  const { mutate: deleteCu, isSuccess, data: deletedCu, reset } = useMutation({
    mutationFn: (organizationsUser: OrganizationsUserAttributes) => deleteOrganizationsUsers(organizationsUser.id),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: [getOrganizationsUsersKey, orgId] })
      await refetch()
    }
  })

  const [selectedUser, setSelectedUser] = useState<OrganizationsUserAttributes | null>(null)


  return (
    <Stack spacing="4" overflowX={'auto'} maxW={'100%'}>
      {data && data.data.map((cu) => (
        <OrganizationsUsersItem onSelect={(ou) => setSelectedUser(ou)} key={cu.id} organizationsUser={cu.attributes} onDelete={deleteCu} />
      ))}

      <UserDetailModal
        orgId={orgId}
        isOpen={selectedUser !== null}
        onClose={() => setSelectedUser(null)}
        organizationsUser={selectedUser || undefined}
      />
    </Stack>
  );
}
