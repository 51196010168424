import { StructureAttributes, StructureAttributesMeta } from "@/interfaces/structure"
import { HStack, Image, Kbd, Link, Text, VStack } from "@chakra-ui/react"
import { ApiResource } from "@/utils/api"
import { useOrganizations } from "@/contexts/useOrganizationsContext"
import { Link as RouterLink, useParams } from 'react-router-dom'
import { structureRoute, structureSummaryRoute } from "@/constants/routes"
import { useNanuqQuery } from "@/hooks/query"
import { EquipmentAttributes, EquipmentAttributesMeta } from "@/interfaces/equipment"
import { getStructure, getStructureQuery } from "@/api/structure"
import { StructureMapPreview } from "../StructureMapPreview/StructureMapPreview"
import { MdLocationCity, MdOutlineLocationOn } from "react-icons/md"
import { TbArrowsHorizontal, TbArrowsVertical, TbCreditCard, TbCrop, TbHome, TbLocationFilled, TbLocationPin, TbPin, TbUsersGroup } from "react-icons/tb"
import { RecordId } from "@/interfaces/id"


export interface EquipmentInfoPanelProps {
  equipment: ApiResource<EquipmentAttributes, EquipmentAttributesMeta>,
}

export const EquipmentInfoPanel = ({
  equipment
}: EquipmentInfoPanelProps) => {

  const { ability } = useOrganizations()
  const { orgId } = useParams()
  const { data: currentStructure } = useNanuqQuery(getStructureQuery(equipment.meta?.current_location?.id as RecordId).queryKey, () => equipment.meta?.current_location?.id ? getStructure(equipment.meta?.current_location?.id) : null, { enabled: equipment.meta?.current_location?.id !== undefined })

  return (
    <HStack
      borderRadius={6}
      // borderColor={'bg.accent'}
      // borderWidth={1}
      px={{ base: '0', md: '6' }}
      py={3}
      // minW={'300px'}
      spacing={6}
      flexGrow={1}
      flexBasis={'300px'}
      alignItems={'stretch'}
      justifyContent={'space-between'}
      // bg={'bg.subtle'}
    >
        <VStack spacing={6} flex={1} alignItems={'stretch'} justifyContent={'space-between'}>
          <HStack px={{base: 0, md: 3}} alignItems={'flex-start'}>
          <VStack flex={1} alignItems={'stretch'}>
            <HStack>
              <TbCreditCard />
              <Text color={'fg.muted'}>{equipment.attributes.manufacturer} {equipment.attributes.model}</Text>
              <Text color={'fg.muted'}><Kbd>{equipment.attributes.serial}</Kbd></Text>
            </HStack>
              {equipment.attributes.width && (<HStack>
                <TbArrowsHorizontal />
                <Text color={'fg.muted'}>Largeur: {equipment.attributes.width}m</Text>
              </HStack>)}
              {equipment.attributes.height && (<HStack>
                <TbArrowsVertical />
                <Text color={'fg.muted'}>Hauteur: {equipment.attributes.height}m</Text>
              </HStack>)}
              {equipment.attributes.seats && (<HStack>
                <TbUsersGroup />
                <Text color={'fg.muted'}>Capacité: {equipment.attributes.seats} personnes</Text>
              </HStack>)}
            {currentStructure?.data && (<HStack>
              <TbPin />
              <Text color={'fg.muted'}>Actuellement à</Text>
                <Link as={RouterLink} to={structureSummaryRoute(orgId, currentStructure.data.id)}>
                  <b>{currentStructure.data.attributes.name}</b>
                </Link>
              </HStack>)}
            </VStack>
            
          </HStack>
          <Image maxW={300} borderRadius={3} src={equipment.attributes.image_url || ''} alt={equipment.attributes.name} />
        </VStack>
    </HStack>
  )
}