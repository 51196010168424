// import "instantsearch.css/themes/algolia-min.css";

import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Checkbox, HStack, Kbd, Link, Stack, Tag, Text, VStack, useBreakpointValue, useCheckboxGroup } from '@chakra-ui/react';
import EquipementRefinementList from "@/screens/EquipmentRefinementList/EquipementRefinementList";

import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import {
  InstantSearch,
  Highlight} from "react-instantsearch";
import { equipmentSummaryRoute } from "@/constants/routes";
import { Hits } from '@/components/Hits/Hits';
import { HitsPagination } from '@/components/HitsPagination/HitsPagination';
import { STATES_LABELS } from '@/utils/states';
import { MdHome, MdOutlineLocationOn } from 'react-icons/md';
import { HierarchicalMenu } from '@/components/HierarchicalMenu/HiercharchicalMenu';
import EquipmentStatusRefinementList from '@/screens/EquipmentRefinementList/EquipmentStatusRefinementList';
import { useState } from 'react';
import { EquipmentsListToolbar } from './EquipmentsListToolbar';
import { truncate } from '@/utils/string';
import { EquipmentsListFilters } from './EquipmentsListFilters';
import { EquipmentsListFiltersModal } from './EquipmentsListFiltersModal';


export interface EquipmentsListProps {
  refinementList?: {[attribute: string]: string[]}
}
export const EquipmentsList = ({ refinementList }: EquipmentsListProps) => {

  const { orgId } = useParams()
  const [selectionEnabled, setSelectionEnabled] = useState(false)
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { value: checkedItems, getCheckboxProps, setValue: setCheckedItems, onChange } = useCheckboxGroup({
    defaultValue: [],
  })

  console.log({ refinementList })
  const { searchClient } = instantMeiliSearch(
    import.meta.env.VITE_APP_MEILI_HOST,
    import.meta.env.VITE_APP_MEILI_KEY,
  )

  const Hit = ({ hit }) => (
    <Box bg={'bg.subtle'}
      borderRadius={3}
      bgImage={`url(${hit.image_url})`}
      p={3}
      flex={1}
      alignContent={'flex-start'}
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      key={hit.id}
    >
      <VStack alignItems={'stretch'}>
        {selectionEnabled ? (
          <HStack alignContent={'flex-start'} justifyContent={'space-between'}>
            <Checkbox {...getCheckboxProps({ value: hit.id })}>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}><Kbd noOfLines={1} color='fg.subtle'>{truncate(hit.serial, 20)}</Kbd></Text>
            </Checkbox>
              <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{hit.equipment_type}</Text>
            </HStack>
        ): (
          <HStack alignContent = { 'flex-start' } justifyContent = { 'space-between' }>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}><Kbd noOfLines={1} color='fg.subtle'>{truncate(hit.serial, 20)}</Kbd></Text>
            <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{hit.equipment_type}</Text>
          </HStack>
        )}
        {selectionEnabled ? (
          <Link noOfLines={1} onClick={() => onChange(hit.id)} fontSize={'lg'}><Highlight attribute="name" hit={hit} /></Link>
        ) : (
          <Link noOfLines={1} fontSize={'lg'} as={RouterLink} to={equipmentSummaryRoute(orgId, hit.id)}><Highlight attribute="name" hit={hit} /></Link>
        )}
        <HStack>
          <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='outline' colorScheme={STATES_LABELS[hit.current_state].color}>{STATES_LABELS[hit.current_state].label}</Tag>
          <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} colorScheme={STATES_LABELS[hit.current_availability].color}>{STATES_LABELS[hit.current_availability].label}</Tag>
        </HStack>
        <HStack spacing={3}>
          {/* <HStack spacing={1}>
            <Text color='fg.subtle'><MdHome /></Text>
            <Text noOfLines={1} color='fg.subtle'>{hit.structure_short_name}</Text>
          </HStack> */}
          <HStack spacing={1}>
            <Text color='fg.subtle'><MdOutlineLocationOn /></Text>
            <Text noOfLines={1} color='fg.subtle'>{hit.current_location_short_name}</Text>
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );

  return (
    <Stack spacing="5" p={3}>
      <InstantSearch
        indexName="vehicules"
        searchClient={searchClient}
        routing
        future={{preserveSharedStateOnUnmount: true}}
        initialUiState={refinementList && { Equipment: { refinementList }}}
      >
        <HStack flexDirection={{ base: 'column', md: 'row' }} spacing={0} alignItems={'stretch'}>
          {isDesktop ? <EquipmentsListFilters refinementList={refinementList} /> : <EquipmentsListFiltersModal refinementList={refinementList} />}
          <Box flex={1}>
            <VStack spacing={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
              <EquipmentsListToolbar
                refinementList={refinementList}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                setSelectionEnabled={setSelectionEnabled}
                selectionEnabled={selectionEnabled}
              />

              <Box p={3} borderBottomWidth={'1px'} borderBottomColor={'bg.muted'} alignItems={'stretch'} justifyContent={'stretch'}>
                <Hits hitComponent={Hit} />
              </Box>
              <HitsPagination />
            </VStack>
          </Box>
        </HStack>
      </InstantSearch>
    </Stack>
  );
}
