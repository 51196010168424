
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Button, HStack, Radio, RadioGroup, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useState } from 'react';


export interface StepOneVehiculeStateProps {
  equipment: EquipmentAttributes,
  currentState: TakeoutState,
  onNext: (state: TakeoutState) => void
}

export const StepOneVehiculeState = ({
  equipment,
  currentState,
  onNext,
}: StepOneVehiculeStateProps) => {

  const { equipmentId, missionId } = useParams()
  const { data: user } = useUser();
  const { ability, selectedOrganizationId } = useOrganizations()
  const [selected, setSelected] = useState<string | undefined>(currentState.usable)

  const onSubmit = () => {
    console.log("validate", selected)
    currentState.usable = (selected as any)
    onNext(currentState)
  }

  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={6}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius={4}
        borderColor={'bg.accent'}
        borderWidth={1}
        borderBottomRadius={4}
        textAlign={'left'}
      >
        <VStack spacing={3} flex={1} alignItems={'stretch'}>
          <Text>Le véhicule est-il fonctionel ?</Text>
          <HStack flex={1} alignItems={'stretch'} justifyContent={'flex-start'}>
            <RadioGroup onChange={(e) => setSelected(e)} defaultValue={selected}>
              <Stack>
                <Radio value='yes'>Oui</Radio>
                <Radio value='partially'>Partiellement</Radio>
                <Radio value='no'>Non</Radio>
              </Stack>
            </RadioGroup>
          </HStack>
        </VStack>
      </Box>
      <Button colorScheme='brand' isDisabled={!selected} onClick={onSubmit}>Valider</Button>
    </VStack>
  );
}
