import * as React from "react"
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
  Text,
  MenuItem,
} from "@chakra-ui/react"
import { FaMoon, FaSun } from "react-icons/fa"

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue("sombre", "clair")
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <MenuItem
      onClick={toggleColorMode}
      minH='40px'
      icon={<SwitchIcon />}
    >
      Mode {text}
    </MenuItem>
  )
}
