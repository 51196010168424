// import "instantsearch.css/themes/algolia-min.css";

import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, TagLabel, TagLeftIcon, useDisclosure } from '@chakra-ui/react';

import { TbFilter } from 'react-icons/tb';
import { EquipmentsListFilters } from './EquipmentsListFilters';


export interface EquipmentsListFiltersModalProps {
  refinementList?: {[attribute: string]: string[]}
}
export const EquipmentsListFiltersModal = ({ refinementList }: EquipmentsListFiltersModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button leftIcon={<TbFilter />} onClick={onOpen}>Filtrer</Button>

      <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filtrer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EquipmentsListFilters refinementList={refinementList} />
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Filtrer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box display={'none'}>
        <EquipmentsListFilters refinementList={refinementList} />
      </Box>
    </>
  );
}
