
import { deleteEquipmentType, getOrganizationEquipmentTypes, getOrganizationEquipmentTypesKey } from '@/api/equipmentType';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { EquipmentTypeAttributes } from '@/interfaces/equipmentType';
import { EquipmentTypeTable } from '@/screens/EquipmentTypeTable/EquipmentTypeTable';
import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { Box, Icon, Input, InputGroup, InputLeftElement, Stack, Text, VStack } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { useDebounce } from "use-debounce";


export const EquipmentTypesList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()

  const [{ pageIndex, pageSize }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const [searchValue, setSearchValue] = useState("");
  const [search] = useDebounce(searchValue, 500);

  const queryKey = [getOrganizationEquipmentTypesKey, orgId, structureId, pageIndex, search]
  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationEquipmentTypes(
      orgId,
      pageIndex + 1,
      {
        structure: [structureId],
        search
      }
    ) : null
  )

  const deleteEquipmentTypeMutation = useOptimisticDeleteMutation<ApiResourceResponse<EquipmentTypeAttributes>, RecordId>(queryClient, deleteEquipmentType, queryKey)

  const onSearch = (search: string) => {
    setSearchValue(search);
  }

  const onEdit = async (equipmentTypeId: RecordId) => {
    
  }

  const onDelete = async (equipmentTypeId: RecordId) => {
    console.log("Delete !", { equipmentTypeId })
    deleteEquipmentTypeMutation.mutate(equipmentTypeId)
  } 

  return (
    <Stack spacing="5">
      <Box px={{ base: '4', md: '6' }} pt="5">
        <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
          <Text textStyle="lg" fontWeight="medium">
          {data?.meta?.count} équipements
          </Text>
          <InputGroup maxW="xs">
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color="fg.muted" boxSize="5" />
            </InputLeftElement>
            <Input placeholder="Rechercher" onChange={(e) => onSearch(e.target.value)} />
          </InputGroup>
        </Stack>
      </Box>
      {data?.data && <EquipmentTypeTable onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} data={data} />}
    </Stack>
  );
}
