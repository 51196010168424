import React from 'react';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useLogin } from '@/hooks/auth';
import { LoginPayloadValues } from '@/api/user';

export function LoginForm() {
  const login = useLogin();

  const [error, setError] = React.useState<string | null>(null);

  const onSubmit = async (
    values: LoginPayloadValues,
    { setErrors }: FormikHelpers<LoginPayloadValues>
  ) => {

    try {
      setError(null)
      setErrors({})
      const response = await login.mutateAsync({ user: values });
      console.log({ response })
    } catch (err: any) {
      if (err.error) {
        setError(err.error);
      }
      else if (err.errors) {
        setErrors(err.errors);
      }
    }
  }

  const initialValues = {
    email: '',
    password: '',
  }

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {(props) => (
      <Form>
        <Stack spacing="6">
          <Stack spacing="5">
            <Field name='email'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.email && form.touched.email ? true : false}>
                  <FormLabel htmlFor='email'>Email</FormLabel>
                  <Input {...field} id='email' type='email' placeholder='Email' />
                  <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='password'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={form.errors.password && form.touched.password ? true : false}>
                  <FormLabel htmlFor='password'>Mot de passe</FormLabel>
                  <Input {...field} id='password' type='password' placeholder='Mot de passe' />
                  <FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
          {!props.isSubmitting && error && <Text color="red.700">{error}</Text>}
        </Stack>
        <Stack spacing="6">
          <Button
            mt={4}
            variant="primary"
            colorScheme='teal'
            isLoading={props.isSubmitting}
            type='submit'
          >
            Connexion
          </Button>
        </Stack>
      </Form>
    )}
  </Formik>
  );
}
