
import { UsersList } from './UsersList';


export const StructureUsersPage = () => {

  return (
    <UsersList />
  );
}

export default StructureUsersPage;