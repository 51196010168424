import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  FormErrorMessage,
  VStack,
  Switch,
  Box,
  HStack,
  IconButton,
  Select,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateEquipmentTypeAttributes, EquipmentTypeAttributes } from '@/interfaces/equipmentType';
import { updateEquipmentType } from '@/api/equipmentType';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { TbSettingsPlus, TbSettingsX } from 'react-icons/tb';
import { EQUIPMENT_CATEGORIES_OPTIONS } from '@/utils/equipment';

export interface EquipmentTypeFormProps {
  equipmentType: EquipmentTypeAttributes,
  orgId: RecordId,
  onSave: (newEquipmentType: ApiResourceResponse<EquipmentTypeAttributes>) => Promise<any>
}

const EditEquipmentTypeForm = ({ equipmentType, orgId, onSave }: EquipmentTypeFormProps) => {

  const initialValues: CreateEquipmentTypeAttributes = {
    name: equipmentType?.name || '',
    category: equipmentType?.category || 'vehicule',
    description: equipmentType?.description || '',
    image_url: equipmentType?.image_url || '',
    mobile: equipmentType?.mobile === true,
    configuration_template: equipmentType.configuration_template ? Object.keys(equipmentType.configuration_template).map(e => ({ name: e, type: equipmentType.configuration_template [e] })) || [] : []
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (equipmentTypePayload: CreateEquipmentTypeAttributes) => updateEquipmentType(equipmentType.id, equipmentTypePayload)
  })


  const onSubmit = async (
    values: CreateEquipmentTypeAttributes,
    actions: FormikHelpers<CreateEquipmentTypeAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {({ values, ...props }) => (
      <Form>
        <ErrorMessage
          name="equipmentType"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        <Heading py={3} size={'xs'}>Général</Heading>
        <VStack spacing="3" alignItems={'flex-start'}>
          <Field name='name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>
                <FormLabel htmlFor='name'>Nom</FormLabel>
                <Input {...field} id='name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='category'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.category && form.touched.category ? true : false}>
                <FormLabel htmlFor='category'>Catégorie</FormLabel>

                <Select required {...field}>
                  {EQUIPMENT_CATEGORIES_OPTIONS.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                </Select>
                <FormErrorMessage>{form.errors.category as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='description'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.description && form.touched.description ? true : false}>
                <FormLabel htmlFor='description'>Description</FormLabel>
                <Input {...field} id='description' type='text' placeholder='Description' />
                <FormErrorMessage>{form.errors.description as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='image_url'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.image_url && form.touched.image_url ? true : false}>
                <FormLabel htmlFor='image_url'>Image</FormLabel>
                <Input {...field} id='image_url' type='text' placeholder='Image' />
                <FormErrorMessage>{form.errors.image_url as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='mobile'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.mobile && form.touched.mobile ? true : false}>
                <FormLabel htmlFor='mobile'>Mobile ?</FormLabel>
                <Switch {...field} isChecked={field.value === true} id='mobile' />
                <FormErrorMessage>{form.errors.mobile as string}</FormErrorMessage>
              </FormControl> 
            )}
          </Field>

          <Heading py={3} size={'xs'}>Propriétés</Heading>
          <VStack w={'100%'}>
            <FieldArray name="configuration_template">
              {({ insert, remove, push }) => (
                <>
                  {(values.configuration_template?.length || 0) > 0 &&
                    values.configuration_template?.map((mer, index) => (
                      <Box w={'100%'} flex={1} bg={'gray.50'} p={3} my={3} key={index}>
                        <HStack alignItems={"flex-end"}>
                          <Field name={`configuration_template.${index}.name`}>
                            {({ field, ...other }: FieldProps) => (
                              <FormControl>
                                <FormLabel
                                  htmlFor={`configuration_template.${index}.name`}
                                >Nom</FormLabel>
                                <Input required type='string' {...field} />
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`configuration_template.${index}.type`}>
                            {({ field, ...other }: FieldProps) => (
                              <FormControl>
                                <FormLabel
                                  htmlFor={`configuration_template.${index}.type`}
                                >Type</FormLabel>
                                <Select required {...field}>
                                  <option value="string">Texte</option>
                                  <option value="number">Nombre</option>
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                          <IconButton
                            aria-label='Supprimer'
                            type="button"
                            variant="secondary"
                            icon={<TbSettingsX />}
                            onClick={() => remove(index)}
                          />
                        </HStack>
                      </Box>
                    ))}
                  <Button
                    type="button"
                    variant="secondary"
                    leftIcon={<TbSettingsPlus />}
                    onClick={() => push({})}
                  >
                    Ajouter une propriété
                  </Button>
                </>
              )}
            </FieldArray>
          </VStack>

          <Button
            variant="primary"
            isLoading={{ values, ...props }.isSubmitting}
            disabled={!props.dirty}
            type='submit'
          >
            Mettre à jour
          </Button>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default EditEquipmentTypeForm