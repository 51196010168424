
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';


export interface StepFiveFinalFinishProps {
  equipment: EquipmentAttributes,
  currentState: TakeoutState,
  onNext: (state: TakeoutState) => void
}

export const StepFiveFinalFinish = ({
  equipment,
  currentState,
  onNext,
}: StepFiveFinalFinishProps) => {


  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={6}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius={4}
        borderColor={'bg.accent'}
        borderWidth={1}
        borderBottomRadius={4}
        textAlign={'left'}
      >
        <VStack spacing={3} flex={1} alignItems={'stretch'}>
          <Heading fontSize={'large'}>Merci !</Heading>
          <Text>Votre trajet a bien été enregistré.</Text>
        </VStack>
      </Box>
      <Text textAlign='center' textColor={'fg.subtle'}>Vous pouvez maintenant fermer cette fenêtre</Text>
    </VStack>
  );
}
