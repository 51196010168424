
import { goEquipmentRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Button, Divider, Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { LuPartyPopper } from "react-icons/lu";
import { TbArrowLeft } from 'react-icons/tb';
import { Link as RouterLink } from 'react-router-dom';


export interface StepFiveFinalStartProps {
  equipment: EquipmentAttributes,
  currentState: TakeoutState,
  onNext: (state: TakeoutState) => void
}

export const StepFiveFinalStart = ({
  equipment,
  currentState,
  onNext,
}: StepFiveFinalStartProps) => {

  const { selectedOrganizationId } = useOrganizations()

  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={6}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius={4}
        borderColor={'bg.accent'}
        // borderWidth={1}
        borderBottomRadius={4}
        textAlign={'left'}
      >
        <VStack spacing={3} flex={1} alignItems={'center'}>
          <Heading fontSize={'xx-large'}>Bonne route !</Heading>
          <Text fontSize={'large'}>Restez vigilant et respectez le code de la route.</Text>
          <Heading fontSize={'xxx-large'} color={'brand.400'}><LuPartyPopper /></Heading>
        </VStack>
      </Box>
      <Text textAlign='center' textColor={'fg.subtle'} fontSize={'sm'}>Pensez à faire le plein d'essence s'il reste moins de 50% du carburant lors de la restitution du véhicule !</Text>

      <Divider />

      <Button as={RouterLink} leftIcon={<TbArrowLeft />} variant={'outline'} to={goEquipmentRoute(selectedOrganizationId, equipment.id)}>Retour</Button>

    </VStack>
  );
}
