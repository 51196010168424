import { parseAndFormatDateShort } from '@/utils/datesFormat';
import { Box, HStack, Heading, Tag, Text, VStack } from '@chakra-ui/react';

interface ValueWithLabelProps {
  icon?: React.ReactNode,
  label: string | React.ReactNode
  children: React.ReactNode
}

const ValueWithLabel = ({ icon, label, children }: ValueWithLabelProps) => {


  return (
    <VStack spacing={1} flex={1} alignItems={'stretch'}>
      <HStack spacing={2} alignItems={'center'}>
        <Text color='brand.600'>{icon}</Text>
        <Text textTransform={'uppercase'} fontSize={'small'}>{label}</Text>
      </HStack>
      <HStack spacing={1} flexWrap={'wrap'}>
        {children}
      </HStack>
    </VStack>
  )


}


export default ValueWithLabel