import { Button, ButtonGroupProps, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { TbCalendar, TbTruck } from "react-icons/tb"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getEquipmentHistoriesKey } from "@/api/equipmentHistory"
import { actionOnEquipment, getEquipmentQuery } from "@/api/equipment"
import { RecordId } from "@/interfaces/id"
import { EquipmentActionMetadata, EquipmentActionModal } from "../Action/Modal"
import { EquipmentEvent } from "@/interfaces/equipment"


export interface EquipmentActionMoveButtonProps extends ButtonProps {
  equipmentIds: RecordId[],
  isLoading?: boolean,
}

export const EquipmentActionMoveButton = ({
  equipmentIds,
  isLoading = false,
  isDisabled,
  ...props
}: EquipmentActionMoveButtonProps) => {

  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedEquipments, setSelectedEquipments] = useState<RecordId[]>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onMoveSelected = (equipmentIds: RecordId[]) => {
    setSelectedEquipments(equipmentIds)
    onOpen()
  }

  const { data, mutateAsync: mutateMove, isPending: actionPending } = useMutation({
    mutationFn: (payload: { equipmentId: RecordId, meta: EquipmentActionMetadata }) => actionOnEquipment(payload.equipmentId, 'move', payload.meta)
  })

  const onMove = async (_action: EquipmentEvent, meta: EquipmentActionMetadata) => {
    console.log({ equipmentIds, meta })
    const updates = Promise.all(equipmentIds.map((equipmentId) => {
      return mutateMove({ equipmentId, meta }, {
        onSettled: async () => {
          const toInvalidate = [
            getEquipmentQuery(equipmentId).queryKey,
            [getEquipmentHistoriesKey, equipmentId],
          ]

          console.log("Invalidate !", toInvalidate)
          for await (const key of toInvalidate) {
            await queryClient.invalidateQueries({ queryKey: key })
          }
          return true
        }
      })

    }))

    try {
      await updates
      onClose()
      setSelectedEquipments(undefined)
      toast({ title: 'Déplacement effectué', status: 'success', isClosable: true, duration: 2000 })
    } catch (error) {
      console.log("onError", { error })
      toast({ title: "Erreur lors de la réservation", description: (error.message || error.error), status: 'error', isClosable: true, duration: 5000 })
    }
  }

  return (<>
    {selectedEquipments && <EquipmentActionModal action={'move'} onAction={onMove} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    <Button isDisabled={isDisabled || isLoading || actionPending} {...props} onClick={() => onMoveSelected(equipmentIds)} leftIcon={<TbTruck />}>Déplacer</Button>
  </>)
}