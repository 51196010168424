import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, ButtonProps, useDisclosure } from "@chakra-ui/react"
import React from "react"


interface ButtonWithAlertProps extends ButtonProps {
  buttonText: string,
  onAccept: () => void,
  dialogText ?: string,
  dialogTitle ?: string,
  cancelText ?: string,
  acceptText ?: string,
}

const ButtonWithAlert = ({
  buttonText = "Supprimer",
  onAccept,
  dialogText = "Cette opération est irréversible",
  dialogTitle = "Êtes vous sûr(e) ?",
  cancelText = "Annuler",
  acceptText = "Ok",
  ...buttonProps
}: ButtonWithAlertProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef <HTMLButtonElement>(null)

  const onClickAccept = () => {
    onClose()
    onAccept()
  }

  return (
    <>
      <Button colorScheme='red' onClick={onOpen} {...buttonProps}>
        {buttonText}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {dialogTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {dialogText}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelText}
              </Button>
              <Button colorScheme='red' onClick={onClickAccept} ml={3}>
                {acceptText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ButtonWithAlert