import { EquipmentAttributes } from '@/interfaces/equipment';
import { RecordId } from '@/interfaces/id';
import { VehiculesListQueryParams } from '@/pages/Vehicules/VehiculesList';
import { useNanuqQuery } from '@/hooks/query';
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { VehiculeCard } from './VehiculeCard';


export interface AsyncVehiculeCardProps {
  equipmentId: RecordId
  orgId: RecordId
  queryParams?: VehiculesListQueryParams
  partialVehicule?: EquipmentAttributes
}

export const AsyncVehiculeCard = ({ equipmentId, orgId, partialVehicule, queryParams }: AsyncVehiculeCardProps) => {

  const { isLoading, data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null)
    
  return (
    data && <VehiculeCard data={data.data} orgId={orgId} queryParams={queryParams} />
  );

}
