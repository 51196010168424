
import { getRide, getRideKey } from '@/api/ride';
import { equipmentRoute, missionRoute, vehiculeSummaryRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { formatDateShort } from '@/utils/datesFormat';
import { RIDE_USABLE_LABEL } from '@/utils/rides';
import { truncate } from '@/utils/string';
import { Box, CircularProgress, CircularProgressLabel, HStack, Heading, Image, Kbd, Link, Progress, Tag, Text, VStack } from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import { MdOutlineModeComment } from 'react-icons/md';
import { TbArrowBackUp, TbCalendar, TbCheck, TbGasStation, TbGauge, TbTruck, TbUser } from 'react-icons/tb';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { colorsForCode } from '@/utils/missions';


export const RideSummary = () => {

  const { orgId, rideId } = useParams()

  const { data: ride } = useNanuqQuery([getRideKey, rideId], () => rideId ? getRide(rideId) : null)


  if (!ride) {
    return <></>
  }

  const {
    bg: eventBg,
    border: eventBorder,
    fg: eventFg,
    subtle: eventSubtle
  } = colorsForCode(ride.data.meta?.mission.mission_type)

  return (
    <Box px={3} py={3}>
      <VStack alignItems={'flex-start'}>
        <Heading fontSize={'x-large'}>Résumé</Heading>
      </VStack>
      <HStack alignItems={{ base: 'stretch', md: 'flex-start' }} flexDirection={{ base: 'column', md: 'row' }} flex={1} spacing={6}>
        <VStack alignItems={'flex-start'} flex={1} spacing={3}>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbCalendar /></Text>
            <Text>Départ le {formatDateShort(parseISO(ride?.data?.attributes?.begin_at))}</Text>
            {ride?.data?.attributes?.end_at && <Text> - Fin le {formatDateShort(parseISO(ride?.data?.attributes?.end_at))}</Text>}
          </HStack>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbTruck /></Text>
            <Text color={'fg.subtle'}>Véhicule</Text>
            <Link
              as={RouterLink}
              to={vehiculeSummaryRoute(orgId, ride?.data?.meta?.equipment?.id)}
              color="fg.muted"
            >
            <Text>{ride?.data?.meta?.equipment?.name} <Kbd>{ride?.data?.meta?.equipment?.serial}</Kbd></Text>
            </Link>
          </HStack>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbArrowBackUp /></Text>
            <Text color={'fg.subtle'}>Mission</Text>
            <Tag
              color={eventFg} bgColor={eventBg}
            >{ride.data.meta?.mission.mission_type}</Tag>
            <Link
              as={RouterLink}
              to={missionRoute(orgId, ride?.data?.meta?.mission?.id)}
              color="fg.muted"
            >
            <Text>{truncate(ride?.data?.meta?.mission?.name, 40)}</Text>
            </Link>
          </HStack>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbUser /></Text>
            <Text color={'fg.subtle'}>Conducteur</Text>
            <Text>{ride?.data?.meta?.user.full_name}</Text>
          </HStack>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbGasStation /></Text>
            <Text color={'fg.subtle'}>Niveau d'essence</Text>
            {/* <CircularProgress value={ride?.data?.attributes?.gas_level_after} color='green.400'>
              <CircularProgressLabel>{ride?.data?.attributes?.gas_level_after}%</CircularProgressLabel>
            </CircularProgress> */}
            <Text>{ride?.data?.attributes?.gas_level_after}%</Text>
            <Progress ml={1} minW={'100px'} colorScheme={ride?.data?.attributes?.gas_level_after >= 50 ? 'green' : 'red'} hasStripe value={ride?.data?.attributes?.gas_level_after || 0} />
          </HStack>
        </VStack>
        <VStack alignItems={'flex-start'} spacing={3} flex={1}>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbCheck /></Text>
            <Text color={'fg.subtle'}>État</Text>
            <Text>{RIDE_USABLE_LABEL[ride?.data?.attributes?.usable]}</Text>
          </HStack>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><TbGauge /></Text>
            <Text color={'fg.subtle'}>Kilométrage</Text>
            <Text>{ride?.data?.attributes?.km_before}km - {ride?.data?.attributes?.km_after ?? '?'}km</Text>
          </HStack>
          <HStack alignItems={'center'} spacing={1}>
            <Text color={'fg.subtle'}><MdOutlineModeComment /></Text>
            <Text color={'fg.subtle'}>Commentaire</Text>
            <Text>{ride?.data?.attributes?.comment?.length > 0 ? ride?.data?.attributes?.comment : <span> - </span>}</Text>
          </HStack>
        </VStack>
      </HStack>
      <VStack alignItems={'flex-start'}>
          <Heading fontSize={'x-large'}>Images</Heading>
          <Heading fontSize={'large'}>Avant le trajet</Heading>
          <HStack overflowX={'auto'} maxW={'100%'} alignItems={{ base: 'stretch', md: 'flex-start' }} flexDirection={{ base: 'column', md: 'row' }} flex={1} spacing={6}>
            <Zoom>
              <Image
                maxH={300}
                decoding="async"
                loading="lazy"
                src={ride.data.attributes.photo_front_before_thumb_url}
                srcSet={`${ride.data.attributes.photo_front_before_thumb_url} 400w, ${ride.data.attributes.photo_front_before_url} 900w`}
                alt="Face avant du véhicule"
              />
            </Zoom>
            <Zoom>
              <Image
                maxH={300}
                decoding="async"
                loading="lazy"
                src={ride.data.attributes.photo_right_before_thumb_url}
                srcSet={`${ride.data.attributes.photo_right_before_thumb_url} 400w, ${ride.data.attributes.photo_right_before_url} 900w`}
                alt="Coté droit du véhicule"
              />
            </Zoom>
            <Zoom>
              <Image
                maxH={300}
                decoding="async"
                loading="lazy"
                src={ride.data.attributes.photo_back_before_thumb_url}
                srcSet={`${ride.data.attributes.photo_back_before_thumb_url} 400w, ${ride.data.attributes.photo_back_before_url} 900w`}
                alt="Face arrière du véhicule"
              />
            </Zoom>
            <Zoom>
              <Image
                maxH={300}
                decoding="async"
                loading="lazy"
                src={ride.data.attributes.photo_left_before_thumb_url}
                srcSet={`${ride.data.attributes.photo_left_before_thumb_url} 400w, ${ride.data.attributes.photo_left_before_url} 900w`}
                alt="Coté gauche du véhicule"
              />
            </Zoom>
          </HStack>
          <Heading fontSize={'large'}>Après le trajet</Heading>
          <HStack overflowX={'auto'} maxW={'100%'} alignItems={{ base: 'stretch', md: 'flex-start' }} flexDirection={{ base: 'column', md: 'row' }} flex={1} spacing={6}>
              <Zoom>
                <Image
                  maxH={300}
                  decoding="async"
                  loading="lazy"
                  src={ride.data.attributes.photo_front_after_thumb_url}
                  srcSet={`${ride.data.attributes.photo_front_after_thumb_url} 400w, ${ride.data.attributes.photo_front_after_url} 900w`}
                  alt="Face avant du véhicule"
                />
              </Zoom>
              <Zoom>
                <Image
                  maxH={300}
                  decoding="async"
                  loading="lazy"
                  src={ride.data.attributes.photo_right_after_thumb_url}
                  srcSet={`${ride.data.attributes.photo_right_after_thumb_url} 400w, ${ride.data.attributes.photo_right_after_url} 900w`}
                  alt="Coté droit du véhicule"
                />
              </Zoom>
              <Zoom>
                <Image
                  maxH={300}
                  decoding="async"
                  loading="lazy"
                  src={ride.data.attributes.photo_back_after_thumb_url}
                  srcSet={`${ride.data.attributes.photo_back_after_thumb_url} 400w, ${ride.data.attributes.photo_back_after_url} 900w`}
                  alt="Face arrière du véhicule"
                />
              </Zoom>
              <Zoom>
                <Image
                  maxH={300}
                  decoding="async"
                  loading="lazy"
                  src={ride.data.attributes.photo_left_after_thumb_url}
                  srcSet={`${ride.data.attributes.photo_left_after_thumb_url} 400w, ${ride.data.attributes.photo_left_after_url} 900w`}
                  alt="Coté gauche du véhicule"
                />
              </Zoom>
            </HStack>
          </VStack>
      </Box>
  );
}

export default RideSummary;