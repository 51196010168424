import {
  Box,
  Table as ChakraTable,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
} from '@chakra-ui/react'

import {
  flexRender,
  Table,
} from '@tanstack/react-table'
import { TablePagination } from '../TablePagination/TablePagination'

export interface CollectionTableProps<T> extends TableProps {
  table: Table<T>
}

export function CollectionTable<T> ({ table, ...props }: CollectionTableProps<T>) {

  const rowFunction = table.options.meta && table.options.meta['rowClass']

  return (
    <Stack spacing="5">

      {table.getRowModel().rows.length > 0 ? (
        <>
          <Box overflowX="auto">
            <ChakraTable variant={'simple'} size={'md'} {...props}>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <Th
                        key={header.id}
                        style={{width: header.getSize()}}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ↑',
                              desc: ' ↓',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map(row => (
                  <Tr key={row.id} className={rowFunction ? rowFunction(row) : ''}>
                    {row.getVisibleCells().map(cell => (
                      <Td
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </ChakraTable>
          </Box>
          { table.getPageCount() > 1 && <TablePagination table={table} /> }
        </>) : (
          <Text color='gray.600' textAlign={'center'} py={4}>Aucun résultat</Text>
        )}
    </Stack>
  )
}
