import { StructureAttributes, StructureAttributesMeta } from "@/interfaces/structure"
import { Box } from "@chakra-ui/react"
import { ApiResource } from "@/utils/api"
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { DivIcon, LatLngBounds } from "leaflet"
import { renderToStaticMarkup } from "react-dom/server"
import { TbHome } from "react-icons/tb"
import styles from "./StructureMapPreview.module.scss"
import { MdOutlineLocationOn } from "react-icons/md"
import { useEffect } from "react"


export interface StructureMapPreviewProps {
  base: ApiResource<StructureAttributes, StructureAttributesMeta>,
  current?: ApiResource<StructureAttributes, StructureAttributesMeta>,
}

export const StructureMapPreview = ({
  base,
  current
}: StructureMapPreviewProps) => {

  console.log([base?.attributes.lat, base?.attributes.long], [(current || base)?.attributes.lat, (current || base)?.attributes.long])
  const boundsBox = new LatLngBounds([base?.attributes.lat, base?.attributes.long], [(current || base)?.attributes.lat, (current || base)?.attributes.long])
  const baseIcon = new DivIcon({
    html: renderToStaticMarkup(<TbHome />),
    className: styles.markerIcon,
    iconSize: [32, 32],
  })
  const currentIcon = new DivIcon({
    html: renderToStaticMarkup(<MdOutlineLocationOn />),
    className: styles.locationMarkerIcon,
    iconSize: [32, 32],
  })

  const hasSameLocation = !current || current.id === base.id

  const AutoFit = ({ boundsBox }: {boundsBox: LatLngBounds}) => {
    const map = useMap()

    useEffect(() => {
      map.fitBounds(boundsBox)
    }, [map, boundsBox])

    return <></>
  }

  return (
    <Box
      borderRadius={6}
      borderColor={'bg.accent'}
      borderWidth={1}
      bg={'bg.subtle'}
      p={1}
      // flex={1}
      width={'100%'}
    >
      <MapContainer
        // center={[base?.attributes.lat, base?.attributes.long]}
        // zoom={12}
        bounds={boundsBox}
        style={{ minHeight: 200, width: '100%' }}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
          url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          />
        <Marker
          position={[base?.attributes.lat, base?.attributes.long]}
          icon={baseIcon}
        >
          <Popup>
            {base.attributes.name}
          </Popup>
        </Marker>
        {!hasSameLocation && (<Marker
          position={[current?.attributes.lat, current?.attributes.long]}
          icon={currentIcon}
        >
          <Popup>
            {current.attributes.name}
          </Popup>
        </Marker>)}
        <AutoFit boundsBox={boundsBox} />
      </MapContainer>
    </Box>
  )
}