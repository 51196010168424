import React, { useState } from 'react';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { SignupPayload, SignupPayloadValues, sendMagicLink } from '@/api/user';
import { useRegister } from '@/hooks/auth';
import { useBoolean } from 'react-use';
import { useMutation } from '@tanstack/react-query';
import { LOCALSTORAGE_REDIRECT_KEY } from '@/constants/routes';
// import { useForm } from '../hooks/useForm';

export function MagicLinkForm() {
  // const { values, onChange } = useForm<SignupPayload>();
  const [error, setError] = React.useState<unknown>(null);
  const [mailSent, setMailSent] = useState<string | false>(false)


  const { mutate, mutateAsync, isPending, isSuccess, data: responseData, reset } = useMutation(
    { mutationFn: (data: SignupPayload) => sendMagicLink(data) }
  )

  const onSubmit = async (
    values: SignupPayloadValues,
    { setSubmitting, setErrors }: FormikHelpers<SignupPayloadValues>
  ) => {

    try {
      const redirectTo = localStorage.getItem(LOCALSTORAGE_REDIRECT_KEY) || undefined
      const result = await mutateAsync({ user: { email: values.email }, redirectTo });
      setMailSent(values.email)
    } catch (err: unknown) {
      setError(err);

      if ((err as any).errors) {
        setErrors((err as any).errors)
      }
    }
  }

  const initialValues = {
    email: '',
  }

  return mailSent ? (
        <Box p={4}>
          <Text color='gray.600' textAlign={'center'} py={4}>Un mail a été envoyé à {mailSent}</Text>
        </Box>
      ) : (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form>
            <Stack spacing="6">
              <Stack spacing="5">
                <Field name='email'>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={form.errors.email && form.touched.email ? true : false}>
                      <FormLabel htmlFor='email'>Email</FormLabel>
                      <Input {...field} id='email' type='email' placeholder='Email' />
                      <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
            </Stack>
            <Stack spacing="6">
              <Button
                mt={4}
                variant="primary"
                colorScheme='brand'
                isLoading={props.isSubmitting}
                type='submit'
              >
                Recevoir un lien de connexion
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
      );
}
