
import { deleteStructure, getOrganizationStructures, getOrganizationStructuresKey } from '@/api/structure';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { StructureAttributes } from '@/interfaces/structure';
import { StructureTable } from '@/screens/StructureTable/StructureTable';
import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { useUser } from '@/hooks/auth';


export const StructuresList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()
  const { data: user } = useUser()

  const [{ pageIndex }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  
  const queryKey = [getOrganizationStructuresKey, orgId, structureId, pageIndex]
  const {
    data,
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationStructures(orgId) : null
  )

  const deleteStructureMutation = useOptimisticDeleteMutation<ApiResourceResponse<StructureAttributes>, RecordId>(queryClient, deleteStructure, queryKey)

  const onEdit = async () => {
    
  }

  const onDelete = async (structureId: RecordId) => {
    deleteStructureMutation.mutate(structureId)
  }

  const userStructures = user?.meta?.user_structures || []

  return (
    data?.data && <StructureTable userStructures={userStructures} onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} data={data} />
  );
}
