
import {
  Box,
  Link,
  StackProps,
  Text,
  TextProps,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export interface EmptyListProps extends TextProps {
  content?: string
  list?: any[] | null
  actionText?: string
  actionLink?: string
  stackProps?: StackProps
}

const EmptyList = ({ list, actionText, actionLink, children, stackProps, ...props }: EmptyListProps) => {

  const color = useColorModeValue('gray.500', 'gray.300')

  return (list || []).length == 0 && (
    <VStack px={3} py={6} {...stackProps}>
      <Text fontSize="medium" textAlign="center" color={color} lineHeight="1" {...props}>
        {children}
      </Text>
      {actionText && actionLink && (
        <Link as={RouterLink} to={actionLink}>{actionText}</Link>
      )}
    </VStack>
  )
}

export default EmptyList