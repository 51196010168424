
import { useRouteLoaderData } from 'react-router-dom';
import { EquipmentsList } from './EquipmentsList';
import { StructureAttributes, StructureAttributesMeta } from '@/interfaces/structure';
import { ApiResourceResponse } from '@/utils/api';


export const StructureEquipmentsPage = () => {

  const structure = useRouteLoaderData("structure") as ApiResourceResponse<StructureAttributes, unknown, StructureAttributesMeta>;

  const refinements = structure ? ({ structure_short_name: [structure.data?.attributes?.short_name] }) : undefined
  return (
    <EquipmentsList refinementList={refinements}/>
  );
}

export default StructureEquipmentsPage;