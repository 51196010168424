import {
  HStack,
  IconButton,
  TableProps,
  Text,
  Link,
  Tag
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  PaginationState,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { MissionAttributes, MissionAttributesMeta } from '@/interfaces/mission'
import { RecordId } from '@/interfaces/id'
import { formatDateShort } from '@/utils/datesFormat'
import { parseISO } from 'date-fns'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { missionEditRoute, missionSummaryRoute } from '@/constants/routes'
import { CollectionTable } from '@/components/CollectionTable/CollectionTable'
import { useOrganizations } from '@/contexts/useOrganizationsContext'
import { truncate } from '@/utils/string'
import { colorsForCode } from '@/utils/missions'
import { getPaginationHandler } from '@/utils/table'
import { StatusTagForDates } from '@/components/StatusTagForDates/StatusTagForDates'
import ButtonWithAlert from '@/components/ButtonWithAlert/ButtonWithAlert'


const columnHelper = createColumnHelper<ApiResource<MissionAttributes, MissionAttributesMeta>>()


export interface MissionTableProps extends TableProps {
  data: ApiCollectionResponse<MissionAttributes, unknown, MissionAttributesMeta>
  onDelete: (missionId: RecordId) => Promise<void>
  onPaginationChange?: (newPage: number) => void
  onSortingChange?: OnChangeFn<SortingState> | undefined
  sorting?: SortingState
}

export const MissionTable = ({ data, onDelete, onPaginationChange, onSortingChange, sorting, ...props }: MissionTableProps) => {

  const { orgId } = useParams()
  const { ability } = useOrganizations()

  const columns = [
    columnHelper.accessor(row => row.attributes.name, {
      id: 'name',
      cell: info => <Link as={RouterLink} to={missionSummaryRoute(orgId, info.row.original.attributes.slug)}>{info.getValue()}</Link>,
      header: () => <span>Nom</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.mission_type', {
      id: 'mission_type',
      header: 'Code',
      cell: info => {

        const {
          bg: eventBg,
          border: eventBorder,
          fg: eventFg,
          subtle: eventSubtle
        } = colorsForCode(info.row.original.attributes.mission_type)

        return ability?.can('show', 'mission', info.row.original.attributes) ? (
          <Tag
            color={eventFg} bgColor={eventBg}
          >{info.getValue() || 'Inconnu'}</Tag>) : (
          <Text title={info.getValue()}>{truncate(info.getValue(), 20)}</Text>
        )
      },
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: "status",
      header: 'Status',
      cell: info => <StatusTagForDates beginAt={info.row.original.attributes.begin_at} endAt={info.row.original.attributes.end_at} />,
      enableSorting: false,
    }),
    columnHelper.accessor('meta.user.full_name', {
      header: 'De',
      cell: info => truncate(info.getValue(), 20),
      footer: info => info.column.id,
      enableSorting: false,
    }),
    columnHelper.accessor('attributes.begin_at', {
      id: 'begin_at',
      header: 'Début',
      cell: info => <Text>{formatDateShort(parseISO(info.getValue()))}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('attributes.end_at', {
      id: 'end_at',
      header: 'Fin',
      cell: info => <Text>{formatDateShort(parseISO(info.getValue()))}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('meta.structure.short_name', {
      header: () => <span>Structure</span>,
      cell: info => (
        // <Link
        //   as={RouterLink}
        //   to={missionsForStructureRoute(orgId, info.row.original.attributes.structure_id)}
        //   color="fg.muted"
        // >{info.getValue()}</Link>
          <Text title={info.getValue()}>{truncate(info.getValue(), 20)}</Text>
      ),
      enableSorting: false,
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => (<HStack spacing="1">
        { ability?.can('update', 'mission', props.row.original?.attributes) && (

          <IconButton as={RouterLink} variant={'link'} icon={<FiEdit2 />} to={missionEditRoute(orgId, props.row.original.id)} aria-label="Edit" />
        )}
        { ability?.can('delete', 'mission', props.row.original?.attributes) && (
          <ButtonWithAlert buttonText='' onAccept={() => onDelete(props.row.original.id)} variant={'link'} leftIcon={<FiTrash2 />} aria-label="Delete" />
        )}
      </HStack>),
      enableSorting: false,
      footer: info => info.column.id,
    })
  ]


  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data.meta?.pages ?? -1,
    state: {
      pagination: {
        pageIndex: data?.meta?.page ? data?.meta?.page - 1 : 0,
        pageSize: data?.meta?.items ?? 10
      },
      sorting
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: getPaginationHandler(data, onPaginationChange),
    manualSorting: true,
    onSortingChange
  })

  return (<CollectionTable table={table} {...props} />)
}
