
import { getEquipmentType, getEquipmentTypeKey, getEquipmentTypeQuery } from '@/api/equipmentType';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { TabNav } from '@/components/TabNav/TabNav';
import { TabNavButton } from '@/components/TabNavButton/NavButton';
import { equipmentTypeSummaryRoute, equipmentTypesRoute, settingsOrganizationRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentTypeAttributes, EquipmentTypeAttributesMeta } from '@/interfaces/equipmentType';
import { ApiResourceResponse } from '@/utils/api';
import { QueryClient } from '@tanstack/react-query';
import { TbArrowLoopRight2 } from 'react-icons/tb';
import { LoaderFunction, NavLink, Outlet, useLoaderData, useParams } from 'react-router-dom';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentTypeQuery(params.equipmentTypeId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentTypeQuery(params.equipmentTypeId as RecordId))
    } 
    return data
  }
)

export const EquipmentTypePage = () => {

  const { orgId, equipmentTypeId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentTypeAttributes, unknown, EquipmentTypeAttributesMeta>;

  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery([getEquipmentTypeKey, equipmentTypeId], () => equipmentTypeId ? getEquipmentType(equipmentTypeId) : null, { initialData })

  return (
    <PageWithTitle
      isLoading={isLoading}
      breadcrumbs={[
        { label: 'Paramètres', url: settingsOrganizationRoute(orgId) },
        { label: 'Types de matériel', url: equipmentTypesRoute(orgId) },
      ]}
      title={data?.data?.attributes?.name}
      subtitle={data?.data?.attributes?.description}
      actions={[
      ]}
      stackProps={{px: 0}}
    >
      <BoxSection px={0} py={0}>

        <TabNav>
          <TabNavButton icon={TbArrowLoopRight2} label='Résumé' as={NavLink} to={equipmentTypeSummaryRoute(orgId, equipmentTypeId)} />
          {/* <TabNavButton icon={TbBoxSeam} label='Matériel' as={NavLink} to={equipmentTypeEquipmentTypesRoute(orgId, equipmentTypeId)} />
          <TabNavButton icon={TbSettings} label='Paramètres' as={NavLink} to={equipmentTypeEditRoute(orgId, equipmentTypeId)} /> */}
          {/* <TabNavButton icon={TbArrowLoopRight2} label='EquipmentTypes' as={NavLink} to={equipmentTypesForEquipmentTypeRoute(orgId, equipmentTypeId)} /> */}
        </TabNav>
        <Outlet />
      </BoxSection>
    </PageWithTitle>
  );
}

export default EquipmentTypePage;