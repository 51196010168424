import { MissionEquipmentAttributes, MissionEquipmentPlanning, MissionEquipmentPlanningMeta } from "@/interfaces/missionEquipment";
import { ROUTES } from "../constants/routes";
import { EquipmentAttributes, EquipmentAttributesMeta } from "../interfaces/equipment";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import qs from "qs";
import { StateKey } from "@/utils/states";


export const getOrganizationEquipmentsKey = "OrganizationEquipments"
export const getEquipmentsSuggestedForMissionKey = "getEquipmentsSuggestedForMission"
export const getEquipmentKey = "Equipment"
export const getMissionEquipmentKey = "MissionEquipment"
export const getMissionEquipmentsPlanningForMissionKey = "getMissionEquipmentsPlanningForMission"
export const getMissionEquipmentsPlanningForEquipmentKey = "getMissionEquipmentsPlanningForEquipment"
export const getMissionEquipmentsPlanningForStructureKey = "getMissionEquipmentsPlanningForStructure"

export async function getEquipmentsSuggestedForMission(missionId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<EquipmentAttributes, any, EquipmentAttributesMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipments.suggestedForMission(missionId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getMissionEquipments(missionId: RecordId): Promise<ApiCollectionResponse<MissionEquipmentAttributes>> {
  const response = await fetch(
    ROUTES.equipments.missionEquipmentList(missionId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getMissionEquipmentsPlanningForMission(missionId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipments.missionEquipmentPlanningForMission(missionId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getMissionEquipmentsPlanningForEquipment(equipmentId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipments.missionEquipmentPlanningForEquipment(equipmentId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getMissionEquipmentsPlanningForStructure(structureId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<MissionEquipmentPlanning, any, MissionEquipmentPlanningMeta>> {
  const params = qs.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.equipments.missionEquipmentPlanningForStructure(structureId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}


export async function addEquipmentToMission(equipmentId: RecordId, missionId: RecordId): Promise<ApiResourceResponse<EquipmentAttributes>> {
  const response = await fetch(
    ROUTES.equipments.addToMission(missionId), {
    headers: defaultAuthHeaders(),
    method: 'POST',
    body: JSON.stringify({ mission_equipment: { equipment_id: equipmentId } })
  })

  return await handleApiResponse(response);
}

export async function removeEquipmentFromMission(missionEquipmentId: RecordId): Promise<ApiResourceResponse<EquipmentAttributes>> {
  const response = await fetch(
    ROUTES.equipments.removeFromMission(missionEquipmentId), {
    headers: defaultAuthHeaders(),
    method: 'DELETE'
  })

  return await handleApiResponse(response);
}


export async function transitionMissionEquipment(equipmentId: RecordId, transition: StateKey): Promise<ApiResourceResponse<MissionEquipmentAttributes>> {
  const response = await fetch(
    ROUTES.equipments.transitionEquipment(`${equipmentId}`), {
    headers: defaultAuthHeaders(),
    method: 'PATCH',
    body: JSON.stringify({ transition })
  })
  return await handleApiResponse(response);
}
