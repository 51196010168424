// import "instantsearch.css/themes/algolia-min.css";

import { Link as RouterLink } from 'react-router-dom';
import { Box, HStack, Link, Tag, Text, VStack } from '@chakra-ui/react';

import { missionRoute } from "@/constants/routes";
import { MdOutlineLocationOn } from 'react-icons/md';
import { RecordId } from '@/interfaces/id';
import { useNanuqQuery } from '@/hooks/query';
import { getMission, getMissionQuery } from '@/api/mission';
import { formatDateShort } from '@/utils/datesFormat';
import { parseISO } from 'date-fns';
import { TbArrowRight, TbUser } from 'react-icons/tb';

export interface MissionAsyncCardProps {
  missionId?: RecordId,
  orgId?: RecordId,
}

export const MissionAsyncCard = ({ missionId, orgId }: MissionAsyncCardProps) => {
  const { data: mission } = useNanuqQuery(getMissionQuery(missionId as RecordId).queryKey, () => missionId ? getMission(missionId) : null, { refetchInterval: false })

  return (<Box bg={'bg.subtle'} borderRadius={3} p={3} flex={1} alignContent={'flex-start'} justifyContent={'flex-start'} alignItems={'stretch'} key={missionId}>
    <VStack alignItems={'stretch'}>
      {mission?.data?.attributes && (<HStack alignContent={'flex-start'} justifyContent={'space-between'}>
        <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{formatDateShort(parseISO(mission?.data?.attributes?.begin_at))}</Text>
        <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}><TbArrowRight /></Text>
        <Text color="fg.muted" textTransform={'uppercase'} fontSize={'small'}>{formatDateShort(parseISO(mission?.data?.attributes?.end_at))}</Text>
      </HStack>)}
      <Link noOfLines={1} fontSize={'lg'} as={RouterLink} to={missionRoute(orgId, mission?.data?.id || '')}>{mission?.data?.attributes?.name}</Link>
      <HStack spacing={3}>
        <HStack>
          <Tag lineHeight={0} textTransform={'uppercase'} size='sm' fontSize={'xs'} variant='outline'>{mission?.data?.attributes.mission_type}</Tag>
        </HStack>
        <HStack spacing={1}>
          <Text color='fg.subtle'><MdOutlineLocationOn /></Text>
          <Text noOfLines={1} color='fg.subtle'>{mission?.data?.meta?.structure?.short_name}</Text>
        </HStack>
        <HStack spacing={1}>
          <Text color='fg.subtle'><TbUser /></Text>
          <Text noOfLines={1} color='fg.subtle'>{mission?.data?.meta?.user?.full_name}</Text>
        </HStack>
      </HStack>
    </VStack>
  </Box>)
}