
import { Outlet } from 'react-router-dom';

export const SettingsLayoutPage = () => {

  return (
    <Outlet />
  );
}

export default SettingsLayoutPage