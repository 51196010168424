import { configureAuth } from '@/lib/react-query-auth';
import { User } from '../interfaces/user';
import { storage } from '../utils/storage';
import { AuthResponse, getUserProfile, LoginPayload, loginWithEmailAndPassword, loginWithMagicToken, resetWithEmail, SignupPayload } from '../api/user';


interface Error {
  message: string;
}

async function handleUserResponse(authData: AuthResponse): Promise<User> {
  const { jwt, data } = authData;
  storage.setToken(jwt);
  return data;
}

async function userFn() {
  const { data } = await getUserProfile();
  return data ?? null;
}

async function loginFn(data: LoginPayload): Promise<User> {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function magicLoginFn(data: { token: string }): Promise<User> {
  const response = await loginWithMagicToken(data.token);
  const user = await handleUserResponse(response);
  return user;
}


async function registerFn(data: SignupPayload): Promise<User> {
  const response = await resetWithEmail(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  await storage.clearToken();
}


export const { useUser, useLogin, useRegister, useMagicLogin, useLogout, AuthLoader } =
  configureAuth({
    userFn,
    loginFn,
    magicLoginFn,
    registerFn,
    logoutFn,
  });