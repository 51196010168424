import { colorsForCode } from "@/utils/missions";
import { CalendarEvent } from "@schedule-x/calendar";

import { useContext } from "react";

export default function CustomDateGridEvent({ calendarEvent, ...props }: { calendarEvent: CalendarEvent, hasOverflowLeft: boolean, hasOverflowRight: boolean }) {

  const colors = colorsForCode(calendarEvent['mission_type'])


  const styles = {
    background: colors.bg,
    color: colors.fg,
  }
  return (
    <>
      <span
        style={{
          height: '100%',
          width: '100%',
          padding: '2px 3px',
          marginLeft: '5px',
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'row',
          columnGap: '5px',
          border: '1px solid white',
          ...styles
        }}
      >
        <b>{calendarEvent.title}</b>
        <span style={{fontWeight: '400'}}>{calendarEvent.mission_name}</span>
      </span>
    </>
  )
}