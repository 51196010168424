import { RecordId } from "@/interfaces/id"
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { ApiResourceResponse } from "@/utils/api"
import { useParams } from "react-router-dom"
import { RiUserAddLine } from "react-icons/ri"
import CreateUserStructureForm from "./CreateUserStructureForm"
import { UserStructureAttributes } from "@/interfaces/userStructure"

export interface AddUserToStructureModalProps {
  onSave: (newUserStructure: ApiResourceResponse<UserStructureAttributes>) => Promise<any>,
  structureId: RecordId,
}

export function AddUserToStructureModal ({
  onSave,
  structureId,
}: AddUserToStructureModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { orgId } = useParams()

  const onCreate = async (eq: ApiResourceResponse<UserStructureAttributes>) => {
    onSave(eq)
    onClose()
  }

  return (
    <>
      <Button variant={'outline'} leftIcon={<RiUserAddLine />} onClick={onOpen}>Ajouter</Button>

      <Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Utilisateurs</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateUserStructureForm orgId={orgId!} structureId={structureId} onSave={onCreate} />
          </ModalBody>

          {/* <ModalFooter>
            {multiple && <Button colorScheme='blue' mr={3} onClick={onMultipleselect}>
              Ajouter
            </Button>}
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={() => refetch()}>Rafraichir</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  )
}