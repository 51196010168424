
import { BoxSection } from '@/components/BoxSection/BoxSection';
import NewEquipmentForm from '@/screens/EquipmentForm/NewEquipmentForm';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { equipmentsNewRoute, equipmentsRoute } from '@/constants/routes';
import { EquipmentAttributes } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { useNavigate, useParams } from 'react-router-dom';


export const NewEquipment = () => {

  const { orgId } = useParams()
  const navigate = useNavigate()

  const onSave = async (data: ApiResourceResponse<EquipmentAttributes>) => {
    navigate(equipmentsRoute(orgId))
  }

  return (
    <PageWithTitle
      title="Nouveau Matériel"
      breadcrumbs={[
        {label: 'Matériel', url: equipmentsRoute(orgId)},
        {label: 'Nouveau', url: equipmentsNewRoute(orgId)},
      ]}
      actions={[
      ]}
    >

      <BoxSection>
        <NewEquipmentForm orgId={`${orgId}`} onSave={onSave}/>
      </BoxSection>
    </PageWithTitle>
  );
}

export default NewEquipment;