
import { getRide, getRideKey, getRideQuery } from '@/api/ride';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { TabNav } from '@/components/TabNav/TabNav';
import { TabNavButton } from '@/components/TabNavButton/NavButton';
import { rideRoute, rideSummaryRoute, ridesRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { RideAttributes, RideAttributesMeta } from '@/interfaces/ride';
import { ApiResourceResponse } from '@/utils/api';
import { truncate } from '@/utils/string';
import { QueryClient } from '@tanstack/react-query';
import { TbBuildingWarehouse } from 'react-icons/tb';
import { LoaderFunction, NavLink, Outlet, useLoaderData, useParams } from 'react-router-dom';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getRideQuery(params.rideId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getRideQuery(params.rideId as RecordId))
    }
    return data
  }
)

export const RidePage = () => {

  const { orgId, rideId } = useParams()
  const { ability } = useOrganizations()
  const initialData = useLoaderData() as ApiResourceResponse<RideAttributes, unknown, RideAttributesMeta>;

  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery([getRideKey, rideId], () => rideId ? getRide(rideId) : null)

  return (
    <PageWithTitle
      isLoading={isLoading}
      breadcrumbs={[
        { label: 'Trajets', url: ridesRoute(orgId) },
        { label: truncate(data?.data?.meta?.mission?.name, 20), url: rideRoute(orgId, rideId) },
      ]}
      title={truncate(data?.data?.meta?.mission?.name, 30)}
      subtitle={data?.data?.meta?.equipment?.name}
      actions={[
      ]}
    >

      <BoxSection px={0} py={0}>
        <TabNav>
          {ability?.can('show', 'ride') && (
            <TabNavButton icon={TbBuildingWarehouse} label='Résumé' as={NavLink} to={rideSummaryRoute(orgId, rideId)} />
          )}
        </TabNav>

        <Outlet />
      </BoxSection>
    </PageWithTitle>
  );
}

export default RidePage;