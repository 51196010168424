import { ROUTES } from "../constants/routes";
import { UserStructureAttributes, CreateUserStructureAttributes, UserStructureAttributesMeta } from "../interfaces/userStructure";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, ApiResourceResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import QueryString from "qs";


export const getStructureUserStructuresKey = "StructureUserStructures"
export const getUserUserStructuresKey = "UserUserStructures"
export const getUserStructureKey = "UserStructure"

export async function getStructureUserStructures(structureId: RecordId, page: number = 1, filters = {}): Promise<ApiCollectionResponse<UserStructureAttributes, any, UserStructureAttributesMeta>> {
  const params = QueryString.stringify({ page, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.userStructures.listForStructure(structureId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}
export async function getUserUserStructures(userId: RecordId): Promise<ApiCollectionResponse<UserStructureAttributes, unknown, UserStructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.userStructures.listForUser(userId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function getUserStructure(userStructureId: RecordId): Promise<ApiResourceResponse<UserStructureAttributes, unknown, UserStructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.userStructures.get(userStructureId), { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}



export async function createUserStructure(structureId: RecordId, userStructure: CreateUserStructureAttributes): Promise<ApiResourceResponse<UserStructureAttributes, unknown, UserStructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.userStructures.createForStructure(structureId), {
      headers: defaultAuthHeaders(),
      method: 'POST',
      body: JSON.stringify({ user_structure: userStructure }),
    }
  )
  return await handleApiResponse(response);
}

export async function updateUserStructure(userStructureId: RecordId, userStructure: CreateUserStructureAttributes): Promise<ApiResourceResponse<UserStructureAttributes, unknown, UserStructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.userStructures.update(`${userStructureId}`), {
      headers: defaultAuthHeaders(),
      method: 'PATCH',
      body: JSON.stringify({ user_structure: userStructure }),
    }
  )
  return await handleApiResponse(response);
}

export async function deleteUserStructure(userStructureId: RecordId): Promise<ApiResourceResponse<UserStructureAttributes, unknown, UserStructureAttributesMeta>> {
  const response = await fetch(
    ROUTES.userStructures.update(`${userStructureId}`), {
      headers: defaultAuthHeaders(),
      method: 'DELETE',
    }
  )
  return await handleApiResponse(response);
}

