
import { EquipmentAttributes, TakeoutState } from '@/interfaces/equipment';
import { Box, Button, FormHelperText, HStack, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useUser } from '@/hooks/auth';
import { useState } from 'react';
import { RideAttributes } from '@/interfaces/ride';


export interface StepThreeVehiculeMetricsProps {
  equipment: EquipmentAttributes,
  ride?: RideAttributes | null,
  currentState: TakeoutState,
  onNext: (state: TakeoutState) => void
}

export const StepThreeVehiculeMetrics = ({
  equipment,
  ride,
  currentState,
  onNext,
}: StepThreeVehiculeMetricsProps) => {

  const { equipmentId, missionId } = useParams()
  const { data: user } = useUser();
  const { ability, selectedOrganizationId } = useOrganizations()
  const [km, setKm] = useState<number | undefined>(currentState.current_km)

  const onSubmit = () => {
    console.log("validate", km)
    currentState = {
      ...currentState,
      current_km: km
    }
    onNext(currentState)
  }

  return (
    <VStack spacing={6} flex={1} alignItems={'stretch'}>
      <Box
        px={6}
        py={3}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius={4}
        borderColor={'bg.accent'}
        borderWidth={1}
        borderBottomRadius={4}
        textAlign={'left'}
      >
        {ride ? (
          <VStack spacing={3} flex={1} alignItems={'stretch'}>
            <Text>Veuillez renseigner le kilométrage affiché sur le tableau de bord</Text>
            <VStack flex={1} alignItems={'stretch'} justifyContent={'flex-start'}>
              <InputGroup>
                <Input type='number' min={ride.km_before} placeholder={`Kilométrage`} value={km} onChange={e => setKm(parseInt(e.target.value))} />
                <InputRightAddon>km</InputRightAddon>
              </InputGroup>
              <Text color={'fg.subtle'}>{km ? `Vous avez parcouru ${km - ride.km_before}km` : `Au départ, vous étiez à ${ride.km_before}km`}</Text>
            </VStack>
          </VStack>
        ) : (
          <VStack spacing={3} flex={1} alignItems={'stretch'}>
            <Text>Veuillez renseigner le kilométrage affiché sur le tableau de bord</Text>
            <VStack flex={1} alignItems={'stretch'} justifyContent={'flex-start'}>
              <InputGroup>
                <Input type='number' placeholder='Kilométrage' value={km} onChange={e => setKm(parseInt(e.target.value))} />
                <InputRightAddon>km</InputRightAddon>
              </InputGroup>
            </VStack>
          </VStack>
        )}
      </Box>
      <Button colorScheme='brand' isDisabled={!km || (!!ride && km - ride.km_before < 0)} onClick={onSubmit}>Valider</Button>
    </VStack>
  );
}
