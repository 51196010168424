import {
  HStack,
  IconButton,
  TableProps,
  Text,
  Link,
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  PaginationState,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { EquipmentTypeAttributes, EquipmentTypeAttributesMeta } from '@/interfaces/equipmentType'
import { RecordId } from '@/interfaces/id'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { equipmentTypeEditRoute, equipmentTypeRoute } from '@/constants/routes'
import { CollectionTable } from '@/components/CollectionTable/CollectionTable'
import { Deletebutton } from '@/components/DeleteButton/DeleteButton'


const columnHelper = createColumnHelper<ApiResource<EquipmentTypeAttributes, EquipmentTypeAttributesMeta>>()


export interface EquipmentTypeTableProps extends TableProps {
  data: ApiCollectionResponse<EquipmentTypeAttributes, unknown, EquipmentTypeAttributesMeta>
  onDelete: (equipmentTypeId: RecordId) => Promise<void>
  onEdit: (equipmentTypeId: RecordId) => Promise<void>
  onPaginationChange?: OnChangeFn<PaginationState> | undefined
  onFilterChange?: (q: string) => Promise<void> | undefined
  onSortingChange?: OnChangeFn<SortingState> | undefined
}

export const EquipmentTypeTable = ({ data, onDelete, onEdit, onPaginationChange, onSortingChange, ...props }: EquipmentTypeTableProps) => {

  const { orgId } = useParams()
  // const [sorting, setSorting] = useState<SortingState>([])

  // const handleSortingChange = (sort: Updater<SortingState>) => {
  //   setSorting(sort)
  //   console.log(sort, sorting)
  //   onSortingChange && onSortingChange(sort)
  // }

  const columns = [
    columnHelper.accessor(row => row.attributes.name, {
      id: 'name',
      cell: info => <Link as={RouterLink} to={equipmentTypeRoute(orgId, info.row.original.id)}>{info.getValue().includes('VIRT') ? 'Inconnu' : info.getValue()}</Link>,
      header: () => <span>Nom</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.attributes.category, {
      id: 'category',
      header: () => 'Catégorie',
      cell: info => <Text color="fg.muted">{info.getValue()}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.attributes.description, {
      id: 'description',
      header: () => 'Description',
      cell: info => <Text color="fg.muted">{info.getValue()}</Text>,
      footer: info => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => (<HStack spacing="1">
        <IconButton as={RouterLink} variant={'link'} icon={<FiEdit2 />} to={equipmentTypeEditRoute(orgId, props.row.original.id)} aria-label="Edit" />
        <Deletebutton
          variant={'link'}
          leftIcon={<FiTrash2 />}
          onConfirm={() => onDelete(props.row.original.id)}
          title={`Supprimer ${props.row?.original?.attributes?.name}`}
        ></Deletebutton> 
      </HStack>),
      footer: info => info.column.id,
    })
  ]

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    pageCount: data.meta?.pages ?? -1,
    state: {
      pagination: {
        pageIndex: data?.meta?.page ?? 0,
        pageSize: data?.meta?.items ?? 10
      },
      // sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange,
  })

  return (<CollectionTable table={table} {...props} />)
}
