import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Switch,
  Select,
} from '@chakra-ui/react'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateEquipmentTypeAttributes, EquipmentTypeAttributes } from '@/interfaces/equipmentType';
import { createEquipmentType } from '@/api/equipmentType';
import { RecordId } from '@/interfaces/id';
import { useMutation } from '@tanstack/react-query';
import { ApiResourceResponse } from '@/utils/api';
import { EQUIPMENT_CATEGORIES_OPTIONS } from '@/utils/equipment';

export interface EquipmentTypeFormProps {
  equipmentType?: EquipmentTypeAttributes,
  orgId: RecordId,
  onSave: (newEquipmentType: ApiResourceResponse<EquipmentTypeAttributes>) => Promise<any>
}

const NewEquipmentTypeForm = ({ equipmentType, orgId, onSave }: EquipmentTypeFormProps) => {

  const initialValues: CreateEquipmentTypeAttributes = {
    name: equipmentType?.name || '',
    category: equipmentType?.category || 'vehicule',
    description: equipmentType?.description || '',
    image_url: equipmentType?.image_url || '',
    mobile: equipmentType?.mobile || true,
    configuration_template: []
  }

  const { data, mutateAsync } = useMutation({
    mutationFn: (equipmentTypePayload: CreateEquipmentTypeAttributes) => createEquipmentType(orgId || '', equipmentTypePayload)
  })

  const onSubmit = async (
    values: CreateEquipmentTypeAttributes,
    actions: FormikHelpers<CreateEquipmentTypeAttributes>
  ) => {
    try {
      if (data === undefined) {
        const newData = await mutateAsync(values);
        actions.resetForm()
        onSave(newData)
      }
    } catch (err: any) {
      console.error(err)
      actions.setErrors(err)
      // setError(err);
    }
  }

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    {(props) => (
      <Form>
        <ErrorMessage
          name="equipmentType"
          component="div"
          className="field-error"
        />
        <ErrorMessage
          name="structure"
          component="div"
          className="field-error"
        />
        <VStack spacing="3" alignItems={'flex-start'}>
          <Field name='name'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.name && form.touched.name ? true : false}>
                <FormLabel htmlFor='name'>Nom</FormLabel>
                <Input {...field} id='name' type='text' placeholder='Nom' />
                <FormErrorMessage>{form.errors.name as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='category'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.category && form.touched.category ? true : false}>
                <FormLabel htmlFor='category'>Catégorie</FormLabel>
                <Select required {...field}>
                  {EQUIPMENT_CATEGORIES_OPTIONS.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                </Select>
                <FormErrorMessage>{form.errors.category as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='description'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.description && form.touched.description ? true : false}>
                <FormLabel htmlFor='description'>Description</FormLabel>
                <Input {...field} id='description' type='text' placeholder='Description' />
                <FormErrorMessage>{form.errors.description as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='image_url'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.image_url && form.touched.image_url ? true : false}>
                <FormLabel htmlFor='image_url'>Image</FormLabel>
                <Input {...field} id='image_url' type='text' placeholder='Image' />
                <FormErrorMessage>{form.errors.image_url as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='mobile'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={form.errors.mobile && form.touched.mobile ? true : false}>
                <FormLabel htmlFor='mobile'>Mobile ?</FormLabel>
                <Switch {...field} id='mobile' />
                <FormErrorMessage>{form.errors.mobile as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            variant="primary"
            isLoading={props.isSubmitting}
            disabled={!props.dirty}
            type='submit'
          >
            Créer
          </Button>
        </VStack>
      </Form>
    )}
  </Formik>
  )
}

export default NewEquipmentTypeForm