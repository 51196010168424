// import "instantsearch.css/themes/algolia-min.css";

import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react';

import { vehiculesRoute } from "@/constants/routes";
import { TbArrowLeft } from 'react-icons/tb';
import { VehiculesListQueryParams } from './VehiculesList';
import { VehiculesListActiveFilters } from './VehiculesListActiveFilters';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiCollectionResponse } from '@/utils/api';
import { VehiculesListFiltersModal } from './VehiculesListFiltersModal';


export interface VehiculesListToolbarProps {
  params?: VehiculesListQueryParams
  isLoading?:boolean
  data?: ApiCollectionResponse<EquipmentAttributes, any, EquipmentAttributesMeta> | null
}
export const VehiculesListToolbar = ({
  params,
  isLoading,
  data
}: VehiculesListToolbarProps) => {

  const { orgId } = useParams()

  return (
    <VStack spacing={3} mt={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
      <VehiculesListActiveFilters params={params} />
      <HStack alignContent={'flex-start'} alignItems={'center'} justifyContent={'space-between'}>
        <Text>{data?.meta?.count || '0'} véhicules</Text>

        <VehiculesListFiltersModal params={params} />
      </HStack>

      <Divider />

    </VStack>
  );
}
