import { Text, Stack, VStack, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {

  const navigate = useNavigate();
  return (
    <Stack justifyContent="center" height="50vh" p={6} alignContent={'center'}>
      <VStack justifyContent="center" p={6} alignContent={'center'}>
        <Text pb={6} fontSize={'large'}>Page introuvable !</Text>
        <Button onClick={() => navigate(-1)}>← Retour</Button>
      </VStack>
    </Stack>
  );
}
