
import { deleteRide, exportRides, getOrganizationRides, getOrganizationRidesKey } from '@/api/ride';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ApiCollectionResponse, ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { RideAttributes, RideAttributesMeta } from '@/interfaces/ride';
import { PaginationState } from '@tanstack/react-table';
import { RideTable } from '@/screens/RideTable/RideTable';
import { useMemo, useState } from 'react';
import { parseRideQueryParams } from '@/utils/rides';
import { ridesRoute } from '@/constants/routes';
import QueryString from 'qs';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';


export const RidesList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => parseRideQueryParams(searchParams), [searchParams])
  const currentPage = queryParams.page ? parseInt(queryParams.page) : 1


  const queryKey = [getOrganizationRidesKey, orgId, structureId, currentPage]
  const query = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationRides(orgId, currentPage, { structure: [structureId], ...queryParams }) : null
  )

  const deleteRideMutation = useOptimisticDeleteMutation<ApiResourceResponse<RideAttributes>, RecordId>(queryClient, deleteRide, queryKey)

  const onEdit = async () => {

  }

  const onPaginationChange = (page: number) => {
    console.log("Pagination change: ", { page })
    navigate(ridesRoute(orgId) + '?' + QueryString.stringify({ ...queryParams, page }))
  }

  const onDelete = async (rideId: RecordId) => {
    console.log("Delete !", { rideId })
    deleteRideMutation.mutate(rideId)
  }

  return <RemoteFetchContainer
    {...query}
    render={(data) => (data ? <RideTable onDelete={onDelete} onEdit={onEdit} onPaginationChange={onPaginationChange} data={data} /> : <></>)}
  >

  </RemoteFetchContainer>
}
