
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { vehiculesRoute } from '@/constants/routes';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import ReservationSearchForm from '@/screens/ReservationSearchForm/ReservationSearchForm';
import QueryString from 'qs';
import { VehiculesListQueryParams } from '../Vehicules/VehiculesList';

export const ReservationsSearch = () => {

  const { orgId } = useParams()
  const navigate = useNavigate()

  const onSearch = (params: VehiculesListQueryParams) => {
    console.log(params)
    navigate(vehiculesRoute(orgId) + '?' + QueryString.stringify(params))
  }

  return (
    <PageWithTitle
      title="Rechercher un véhicule disponible"
    >
      <BoxSection px={[4, 6]} py={[4, 6]}>
        <ReservationSearchForm onSearch={onSearch} />
      </BoxSection>
    </PageWithTitle>
  );
}

export default ReservationsSearch;