
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { equipmentTypesNewRoute, equipmentsNewRoute } from '@/constants/routes';
import { Button } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { EquipmentTypesList } from './EquipmentTypesList';
import { BoxSection } from '@/components/BoxSection/BoxSection';


export const AllEquipmentTypesPage = () => {

  const { orgId } = useParams()

  return (
    <PageWithTitle
      title="Types de matériel"
      actions={[
        <Button size={'sm'} as={Link} variant="primary" to={equipmentTypesNewRoute(orgId)}>Nouveau</Button>
      ]}
    >

      <BoxSection px={0}>
        <EquipmentTypesList />
      </BoxSection>
    </PageWithTitle>
  );
}

export default AllEquipmentTypesPage;