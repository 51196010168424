
export function pick(object: {[key: string]: any}, keys: any[]):  {[key: string]: any} {
  return keys.reduce((obj, key) => {
     if (object && object.hasOwnProperty(key)) {
        obj[key] = object[key];
     }
     return obj;
   }, {});
}

export function groupBy<T extends object>(collection: T[], iteratee: (x: T) => (string | number)): object {
   const it = typeof iteratee === 'function' ?
      iteratee : ({ [iteratee]: prop }) => prop;

   const array = Array.isArray(collection) ? collection : Object.values(collection);

   return array.reduce<object>((r, e) => {
      const k = (it(e as T) as (string | number));

      r[k] = r[k] || [];

      r[k].push(e);

      return r;
   }, {});
}

export const sortBy = (key) => {
   return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
};

export const sortByF = (f: (key) => number | string) => {
   return (a, b) => (f(a) > f(b)) ? 1 : ((f(b) > f(a)) ? -1 : 0);
};
