
import { FormikHelpers } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { updateOrganization } from '@/api/organization';
import OrganizationEditForm from '@/screens/OrganizationEditForm/OrganizationEditForm';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { OrganizationAttributes } from '@/interfaces/organization';
import { Button, HStack, Stack, StackDivider, Text } from '@chakra-ui/react';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import { OrganizationsUsersList } from './OrganizationUsersList';
import { MdMail } from 'react-icons/md';
import InviteOrganizationsUserModal from '@/screens/InviteOrganizationsUserModal/InviteOrganizationsUserModal';
import { useState } from 'react';


export const GeneralSettingsPage = () => {

  const { getSelectedOrganization, refetch, selectedOrganizationId } = useOrganizations()

  const organizationId = selectedOrganizationId || ''

  const organization = getSelectedOrganization()
  const [isOrganizationUserModalOpen, setOrganizationUserModalOpen] = useState<boolean>(false)

  const { mutate } = useMutation({
    mutationFn: (organizationPayload: Partial<OrganizationAttributes>) => updateOrganization(organizationId, organizationPayload),
    onSuccess: () => {
      // queryClient.invalidateQueries([getUserOrganizationsKey])
    }
  })

  const onOrganizationChange = async (values: Partial<OrganizationAttributes>, { resetForm }: FormikHelpers<Partial<OrganizationAttributes>>) => {
    try {
      await mutate(values);
      refetch && await refetch()
      resetForm({ values })
    } catch (err: unknown) {
      console.error(err)
    }
  }

  const onClickCreateOrganizationsUser = () => {
    setOrganizationUserModalOpen(true)
  }

  return (
      <PageWithTitle
        title={`Paramètres`}
        actions={[
        ]}
      >
      <BoxSection>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Général
            </Text>
            <Text fontSize="sm" color="muted">
              Paramètres généraux.
            </Text>
          </Stack>
          {organization?.attributes && <OrganizationEditForm organization={organization?.attributes} onSubmit={onOrganizationChange} />}

        </Stack>
      </BoxSection>

      <BoxSection>
        <Stack spacing="5" divider={<StackDivider />}>
          <HStack spacing="1" justify="space-between">
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Membre
              </Text>
              <Text fontSize="sm" color="muted">
                Personnes dans l'organisation.
              </Text>
            </Stack>
            <Stack spacing="1">
              <Button variant="primary" size={"sm"} leftIcon={<MdMail />} onClick={onClickCreateOrganizationsUser}>Inviter</Button>
            </Stack>
          </HStack>
          <OrganizationsUsersList orgId={organizationId} />
        </Stack>
      </BoxSection>

      {/* <BoxSection>
        <Stack spacing="5" divider={<StackDivider />}>
          <HStack spacing="1" justify="space-between">
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Integrations
              </Text>
              <Text fontSize="sm" color="muted">
                Connect Nanuq to your Slack workspace.
              </Text>
            </Stack>
            <Menu>
              <MenuButton variant="primary" as={Button} size={"sm"} leftIcon={<MdAdd />}>
                Setup new integration
              </MenuButton>
              <MenuList>
                <MenuItem><AddToSlackButton organizationId={organizationId} /></MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <IntegrationsList orgId={organizationId} />
        </Stack>
      </BoxSection> */}

      {/* @TODO delete organization */}
      {/* <BoxSection>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Danger zone
            </Text>
            <Text fontSize="sm" color="muted">
              General settings for your organization.
            </Text>
          </Stack>

        </Stack>
      </BoxSection> */}

      <InviteOrganizationsUserModal
        isOpen={isOrganizationUserModalOpen}
        onClose={() => setOrganizationUserModalOpen(false)}
        orgId={organizationId}
      />

    </PageWithTitle>
  );
}

export default GeneralSettingsPage;