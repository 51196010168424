

import { Box } from '@chakra-ui/react'
import './TruckIconRunning.css'
import { FaTruckMoving } from 'react-icons/fa'

const TruckIconRunning = () => {

  return (
    <Box className="animation-truck-running" fontSize={'xx-large'}>
      <FaTruckMoving color='currentColor' />
    </Box>
  )
}

export default TruckIconRunning