import { MissionAttributes, MissionWithEquipmentsAttributes } from '@/interfaces/mission';
import { OrganizationAttributes, OrganizationsUserAttributes } from '../interfaces/organization';
import { User, UserAttributes, UserStructureBlock } from '../interfaces/user';
import { EquipmentAttributes } from '@/interfaces/equipment';
import { StructureAttributes } from '@/interfaces/structure';
import { UserStructureAttributes } from '@/interfaces/userStructure';
import { EquipmentHistoryAttributes } from '@/interfaces/equipmentHistory';
import { RideAttributes } from '@/interfaces/ride';
import { MissionEquipmentAttributes, MissionEquipmentPlanning } from '@/interfaces/missionEquipment';
import { RecordId } from '@/interfaces/id';

export type OrgRole = 'viewer' | 'member' | 'owner'

type AbilityAction = 'list' | 'show' | 'create' | 'update' | 'delete' | 'reserve' | 'ride'
type AbilityTargetType =  'organizationsUser' |
                          'organization' |
                          'mission' |
                          'equipment_reservation' |
                          'equipment' |
                          'structure' |
                          'user' |
                          'equipment_history' |
                          'user_structure' |
                          'ride'

type AbilityTargetInstance = OrganizationsUserAttributes | 
                             MissionAttributes | 
                             MissionWithEquipmentsAttributes | 
                             MissionEquipmentPlanning | 
                             EquipmentAttributes | 
                             StructureAttributes | 
                             UserStructureAttributes | 
                             EquipmentHistoryAttributes | 
                             UserAttributes |
                             OrganizationAttributes |
                             RideAttributes

export class Ability {
  user: User | undefined
  organizationId: string | undefined
  orgRole: OrgRole | undefined

  constructor(user: User | undefined, organizationId: string | undefined) {
    this.user = user
    this.organizationId = organizationId
    this.orgRole = this.user?.meta?.organizations_users?.find(e => `${e.organization_id}` === `${organizationId}`)?.role as OrgRole
  }

  can(action: AbilityAction, type: AbilityTargetType, target?: AbilityTargetInstance): boolean {
    const canDo = this.canRaw(action, type, target)
    // console.log(`CAN ${this.user} ${action} on ${type} ${target} ? ${canDo ? 'YES' : 'NO'}`, this.user, target)
    return canDo
  }

  canRaw(action: AbilityAction, type: AbilityTargetType, target?: AbilityTargetInstance): boolean {
    switch (type) {
      case 'organizationsUser':
        return this.organizationsUser(action, target as OrganizationsUserAttributes)    
      case 'organization':
        return this.organization(action, target as OrganizationAttributes)    
      case 'mission':
        return this.mission(action, target as MissionAttributes)    
      case 'equipment_reservation':
        return this.equipment_reservation(action, target as MissionEquipmentPlanning)    
      case 'equipment':
        return this.equipment(action, target as EquipmentAttributes)    
      case 'structure':
        return this.structure(action, target as StructureAttributes)    
      case 'user':
        return this.member(action, target as UserAttributes)    
      case 'user_structure':
        return this.userStructure(action, target as UserStructureAttributes)    
      case 'equipment_history':
        return this.equipmentHistory(action, target as EquipmentHistoryAttributes)    
      case 'ride':
        return this.ride(action, target as RideAttributes)    
      default:
        return false
    }
  }

  // -------------------------------------------------
  
  
  
  organizationsUser(action: AbilityAction, target?: OrganizationsUserAttributes): boolean {
    switch (action) {
      case 'list':
        return this.isOrgOwner()
      case 'show':
        return this.isOrgOwner()
      case 'create':
        return this.isOrgOwner()
      case 'update':
        return this.isOrgOwner()
      case 'delete':
        return (
          (this.orgRole && target && target.role !== 'owner')
          || false
        )
      default:
        return false
    }
  }
  
  
  organization(action: AbilityAction, target?: OrganizationAttributes): boolean {
    switch (action) {
      case 'reserve':
        return this.orgRole !== null
      case 'list':
        return this.isOrgOwner()
      case 'show':
        return this.isOrgOwner()
      case 'create':
        return this.isOrgOwner()
      case 'update':
        return this.isOrgOwner()
      case 'delete':
        return this.isOrgOwner()
      default:
        return false
    }
  }
  
  mission(action: AbilityAction, target?: MissionAttributes | MissionWithEquipmentsAttributes): boolean {
    switch (action) {
      case 'list':
        return this.isOrgViewer() ||
          this.isAtLeastMemberForStructure(target?.structure_id)
      case 'show':
        return this.isOrgViewer() || this.isAtLeastMemberForStructure(target?.structure_id)
      case 'create':
        return this.isOrgOwner() ||
          this.isAtLeastMemberForStructure(target?.structure_id)
      case 'delete':
        return this.isOrgOwner() || this.isAtLeastMemberForStructure(target?.structure_id) ||
          this.isUser(target?.user_id || '')
      case 'update':
        return this.isOrgOwner() ||
          this.isAtLeastMemberForStructure(target?.structure_id) ||
          this.isUser(target?.user_id || '')
      default:
        return this.isOrgOwner()
    }
  }
  
  equipment_reservation(action: AbilityAction, target?: MissionEquipmentPlanning): boolean {
    switch (action) {
      case 'list':
        return this.isOrgOwner()
      case 'show':
        return this.isOrgOwner()
      case 'create':
        return this.isOrgOwner()
      case 'delete':
        return this.isOrgOwner() || this.isAtLeastMemberForStructure(target?.structure_id)
      case 'update':
        return this.isOrgOwner() || this.isAtLeastMemberForStructure(target?.structure_id)
      default:
        return this.isOrgOwner()
    }
  }
  
  ride(action: AbilityAction, target?: RideAttributes): boolean {
    switch (action) {
      case 'list':
        return this.orgRole !== null
      case 'show':
        return this.isOrgOwner()
          || this.isAtLeastMemberForStructure(target?.structure_id)
          || this.isUser(target?.user_id)
      case 'create':
        return this.isOrgOwner() || this.isAtLeastMemberForStructure(target?.structure_id)
      case 'delete':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.structure_id)
      case 'update':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.structure_id)
      default:
        return this.isOrgOwner()
    }
  }
  
  equipment(action: AbilityAction, target?: EquipmentAttributes): boolean {
    switch (action) {
      case 'reserve':
        // console.log(`[ability] can reserve equipment ? ${this.isOrgOwner()} || ${this.isAtLeastViewerForStructure(target?.structure_id) }`)
        return this.isOrgOwner() || this.isAtLeastViewerForStructure(target?.structure_id)
      case 'ride':
        return this.isOrgOwner() || this.isAtLeastViewerForStructure(target?.structure_id)
      case 'list':
        return this.orgRole !== null
      case 'show':
        return this.orgRole !== null
      case 'create':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.structure_id)
      case 'delete':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.structure_id)
      case 'update':
        return this.isOrgOwner() || this.isAtLeastMemberForStructure(target?.structure_id)
      default:
        return this.isOrgOwner()
    }
  }
  
  structure(action: AbilityAction, target?: StructureAttributes): boolean {
    switch (action) {
      case 'reserve':
        return this.isOrgOwner() || (
          this.user?.meta.user_structures?.filter(e => e.structure_id.toString() === target?.id.toString()) ||
          []
        )?.length > 0
      case 'list':
        return this.isOrgOwner()
      case 'show':
        return this.isOrgOwner()
      case 'create':
        return this.isOrgOwner()
      case 'delete':
        return this.isOrgOwner()
      case 'update':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.id)
      default:
        return this.isOrgOwner()
    }
  }
  
  member(action: AbilityAction, _target?: UserAttributes): boolean {
    switch (action) {
      case 'list':
        return this.isOrgOwner()
      case 'show':
        return this.isOrgOwner()
      case 'create':
        return this.isOrgOwner()
      case 'delete':
        return this.isOrgOwner()
      case 'update':
        return this.isOrgOwner()
      default:
        return this.isOrgOwner()
    }
  }
  
  userStructure(action: AbilityAction, target?: UserStructureAttributes): boolean {
    switch (action) {
      case 'list':
        return this.isOrgOwner() || this.isAtLeastViewerForStructure(target?.structure_id)
      case 'show':
        return this.isOrgOwner() || this.isAtLeastViewerForStructure(target?.structure_id)
      case 'create':
        return this.isOrgOwner()
      case 'delete':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.structure_id)
      case 'update':
        return this.isOrgOwner() || this.isAtLeastOwnerForStructure(target?.structure_id)
      default:
        return this.isOrgOwner()
    }
  }
  
  equipmentHistory(action: AbilityAction, target?: EquipmentHistoryAttributes): boolean {
    switch (action) {
      case 'list':
        return this.isOrgOwner()
      case 'show':
        return this.isOrgOwner()
      case 'create':
        return this.isOrgOwner()
      case 'delete':
        return this.isOrgOwner()
      case 'update':
        return this.isOrgOwner() || target?.user_id === this.user?.attributes?.id
      default:
        return this.isOrgOwner()
    }
  }

  rolesforStructure(structure_id: RecordId | undefined): UserStructureBlock[] {
    return this.user?.meta.user_structures?.filter(e => e.structure_id.toString() === (structure_id || '').toString()) ?? []
  }

  isAtLeastViewerForStructure(structure_id: RecordId | undefined): boolean {
    // console.log(`[rolesforStructure ${structure_id}] => ${this.rolesforStructure(structure_id) }`)
    return this.rolesforStructure(structure_id).length > 0
  }

  isAtLeastMemberForStructure(structure_id: RecordId | undefined): boolean {
    return this.rolesforStructure(structure_id).filter(e => e.role !== 'viewer').length > 0
  }

  isAtLeastOwnerForStructure(structure_id: RecordId | undefined): boolean {
    return this.rolesforStructure(structure_id).filter(e => e.role === 'owner').length > 0
  }

  isSame(one: RecordId, two: RecordId): boolean {
    return one.toString() === two.toString()
  }

  isUser(userId: RecordId | undefined): boolean {
    return userId !== undefined && this.user?.attributes.id !== undefined && this.isSame(userId, this.user?.attributes.id)
  }

  isOrgOwner(): boolean {
    return this.orgRole === 'owner'
  }

  isOrgViewer(): boolean {
    return this.orgRole === 'viewer' || this.isOrgOwner()
  }

}