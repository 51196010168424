import { addDays, areIntervalsOverlapping, differenceInDays, isToday, startOfDay } from "date-fns";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Tooltip, Text } from "@chakra-ui/react";
import styles from "./GanttPlanning.module.scss";
import { formatDateShortWithoutYear } from "@/utils/datesFormat";
import { cx, stringToColor } from "@/utils/string";

export interface Task {
  id: string;
  name: string;
  start: Date;
  end: Date;
  group?: string;
}

export interface StructurePlanningGanttProps  {
  viewStart?: Date,
  viewEnd?: Date,
  tasks: Task[]
}

export function GanttPlanning({
  viewStart = addDays(new Date(), -1),
  viewEnd = addDays(new Date(), 30),
  tasks
}: StructurePlanningGanttProps) {


  const columnCount = differenceInDays(viewEnd, viewStart)
  const columns = [...Array(columnCount).keys()]
  const rowsGrouped = tasks.reduce((r, v, i, a, k = v.name) => ((r[k] || (r[k] = [])).push(v), r), {})

  const rowsLabels = Object.keys(rowsGrouped)
  const rowsCount = rowsLabels.length
  const rows = [...Array(rowsCount).keys()]

  const table = rows.map((rowIndex: number) => {
    const matchingRowTasks = tasks.filter(e => e.name === rowsLabels[rowIndex])

    return [...columns].map((columnIndex: number) => {
      const currentStart = addDays(startOfDay(viewStart), columnIndex)
      const currentEnd = addDays(currentStart, 1)
      const matchingTasks = matchingRowTasks.filter(e => areIntervalsOverlapping(
        { start: e.start, end: e.end },
        { start: currentStart, end: currentEnd }
        )
      )

      return matchingTasks
    })
  })

  return (
    <Box className={styles.ganttContainer}>

      {rowsCount === 0 ? (
        <Text textAlign="center" color="fg.subtle">Aucune mission</Text>
      ) : (
        
      <Table variant='simple' size='sm' overflowX='scroll' className={styles.gantt}>
      <Thead className={styles.rowHeader}>
        <Tr className={styles.rowHeader}>
            <Th key={'index'} className={styles.columnHeader}></Th>
            {columns.map((columnsIndex) => {
              const currentStart = addDays(startOfDay(viewStart), columnsIndex)
              return (
                <Th key={columnsIndex} p={2} className={styles.columnHeader} fontWeight={isToday(currentStart) ? 'bold' : 'normal'}>
                  {formatDateShortWithoutYear(currentStart, {}, 'dd/M')}
                </Th>
              )
            })}
          </Tr>
        </Thead>
      <Tbody>
    { table.map((row, index) => (
      <Tr key={index} className={styles.row}>
        <Th textAlign={'end'} position='sticky' key={'label'} className={styles.columnLabel}>
          <span>{rowsLabels[index]}</span>
        </Th>
        { row.map((r, i) => {
          
          const classes = [styles.column]
        if (r.length > 0) {
          classes.push(styles.active)

          if (i > 0 && row[i - 1].length > 0 && row[i - 1].some(item => r.map(e => e.group).includes(item.group))) {
            classes.push(styles.activeLast)
          }

          if (i > 0 && i < row.length && row[i + 1]?.length > 0 && row[i + 1].some(item => r.map(e => e.group).includes(item.group))) {
            classes.push(styles.activeFirst)
          }
        } else {
          classes.push(styles.inactive)
        }

        return (
          <Td key={i} p={0} textAlign='center' className={cx(classes)}>
            
            {r.map(e => 
              <Tooltip key={e.id} label={`${rowsLabels[index]} utilisé pour ${e.group} le ${formatDateShortWithoutYear(addDays(startOfDay(viewStart), i), {}, 'dd/M')}`} hasArrow arrowSize={15}>
                <Box w={`${100 / (r.length || 1)}%`} bg={r && r.length > 0 ? stringToColor(e.group) : undefined} className={styles.item} >
                <span className={styles.reservationBadge}></span>
                </Box>
              </Tooltip>
            )}
            {/* {r.length === 0 ? '' : <Badge>{r.length}</Badge>} */}
          </Td>
        )})}
      </Tr>
    )) }
      </Tbody>
    </Table>

    )}
    </Box>
  )
}
