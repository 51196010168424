
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, Outlet, useLoaderData, useParams } from 'react-router-dom';
import { Box, HStack, Heading, VStack } from '@chakra-ui/react';
import Marker from '@/components/Marker/Marker';
import StateBadge from '@/components/StateBadge/StateBadge';
import { useLazyImage } from '@/hooks/useLazyImage';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const TakeoutEquipmentPage = () => {

  const { equipmentId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { isLoading, data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })

  const { imageUrl, isImageLoaded } = useLazyImage(data?.data?.attributes.image_url, data?.data?.attributes.image_thumb_url)

  return (
    <VStack alignItems={'stretch'}>
      <VStack
        borderRadius={4}
        borderBottomRadius={0}
        alignItems={'stretch'}
        justifyContent={'space-between'}
        minH={'25vh'}
        w={'100%'}
        p={3}
        zIndex={1}
        backgroundSize={'cover'}
        transition="1s filter linear"
        backdropFilter={`blur(${isImageLoaded ? '0' : '5'}px)`}
        backgroundPosition={'center'}
        backgroundImage={`url(${imageUrl})`}
      >
        <HStack alignItems={'flex-start'}>
          <StateBadge minW={'100px'} borderRadius={4} variant={'solid'} size='lg' state={data?.data?.attributes?.current_availability} />
        </HStack>

        <HStack justifyContent={'center'}>
          <Heading fontSize={'x-large'}><Marker color={'yellow'}>{data?.data?.attributes?.name}</Marker></Heading>
        </HStack>
      </VStack>
      <Box mt={-10} zIndex={5}>
        <Outlet />
      </Box>
    </VStack>
  );
}

export default TakeoutEquipmentPage;