import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools"

const baseStyle = defineStyle((props: StyleFunctionProps) => ({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  // color: mode('brand.900', 'brand.100')(props),
  fontWeight: '500',
  textDecoration: "none",
  outline: "none",
  _hover: {
    textDecoration: "underline",
    color: mode('gray.800', 'gray.100')(props)
  },
  _focusVisible: {
    boxShadow: "outline",
  },
}))

const sizes = {
  // lg: {
  //   fontSize: 'md',
  // },
}

const variants = {
  link: (props: StyleFunctionProps) => ({
    transitionProperty: "common",
    transitionDuration: "fast",
    transitionTimingFunction: "ease-out",
    cursor: "pointer",
    color: mode('brand.800', 'brand.100')(props),
    // fontWeight: '600',
    textDecoration: "underline",
    outline: "none",
    _hover: {
      textDecoration: "underline",
      color: mode('gray.800', 'gray.100')(props)
    },
    _focusVisible: {
      boxShadow: "outline",
    },
  })
}

export default {
  baseStyle,
  variants,
  sizes,
}
