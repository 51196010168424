
import { getMissionEquipmentsPlanningForStructure, getMissionEquipmentsPlanningForStructureKey } from '@/api/missionEquipment';
import { getStructure, getStructureKey } from '@/api/structure';
import { missionsNewRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useNanuqQuery } from '@/hooks/query';
import { StructurePlanningGantt } from '@/screens/StructurePlanningGantt/StructurePlanningGantt';
import { formatDateShortWithoutYear } from '@/utils/datesFormat';
import { Box, Button, ButtonGroup, HStack, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { addMonths, startOfMonth } from 'date-fns';
import { useState } from 'react';
import { TbArrowLeft, TbArrowRight, TbPlus } from 'react-icons/tb';
import { Link, useParams } from 'react-router-dom';


export const StructurePlanning = () => {

  const { orgId, structureId } = useParams()
  const { ability } = useOrganizations()

  const [currentDate, setCurrentDate] = useState<Date>(startOfMonth(new Date()))

  const { data: structure } = useNanuqQuery([getStructureKey, structureId], () => structureId ? getStructure(structureId) : null)
  const { data: planning } = useNanuqQuery([getMissionEquipmentsPlanningForStructureKey, structureId], () => structureId ? getMissionEquipmentsPlanningForStructure(structureId) : null)

  const previousMonth = () => {
    setCurrentDate(addMonths(currentDate, -1))
  }
  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1))
  }
  const now = () => {
    setCurrentDate(startOfMonth(new Date()))
  }

  if (!structure) {
    return <></>
  }

  return (
      <Box>
        <HStack p={3} justifyContent={'space-between'} alignItems={{ base: 'stretch', md: 'flex-start' }} flexDirection={{ base: 'column', md: 'row' }} flex={1}>
          <HStack pt={1} px={3} flex={1}>
            <Text size={'sm'} textTransform={'capitalize'} variant={'outline'}>{formatDateShortWithoutYear(currentDate, {}, 'LLLL yyyy')}</Text>
          </HStack>
          <HStack px={3} flex={1}>
            <ButtonGroup isAttached>
              <Button size={'sm'} variant={'outline'} onClick={previousMonth} leftIcon={<TbArrowLeft />}>{formatDateShortWithoutYear(addMonths(currentDate, -1), {}, 'LLLL')}</Button>
              <Button size={'sm'} variant={'outline'} onClick={now}>maintenant</Button>
              <Button size={'sm'} variant={'outline'} onClick={nextMonth} rightIcon={<TbArrowRight />}>{formatDateShortWithoutYear(addMonths(currentDate, 1), {}, 'LLLL')}</Button>
            </ButtonGroup>
          </HStack>
          <HStack flex={1} justifyContent={'flex-end'}>
            { ability?.can('create', 'mission') && <Button key="new" size={'sm'} as={Link} leftIcon={<TbPlus />} variant="primary" to={missionsNewRoute(orgId)}>Mission</Button> }
          </HStack>
        </HStack>
      {planning && (
        <StructurePlanningGantt
          data={planning}
          viewStart={currentDate}
          viewEnd={addMonths(currentDate, 1)}
        />
      )}
      </Box>
  );
}

export default StructurePlanning;