
import { getMission, getMissionKey, getMissionQuery } from '@/api/mission';
import Loader from '@/components/Loader/Loader';
import EditMissionForm from '@/screens/MissionForm/EditMissionForm';
import { missionRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { MissionAttributes, MissionAttributesMeta, MissionWithEquipmentsAttributes } from '@/interfaces/mission';
import { ApiResourceResponse } from '@/utils/api';
import { Box } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useOrganizations } from '@/contexts/useOrganizationsContext';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getMissionQuery(params.missionId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getMissionQuery(params.missionId as RecordId))
    }
    return data
  }
)


export const MissionEdit = () => {
  const { orgId, missionId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<MissionWithEquipmentsAttributes, unknown, MissionAttributesMeta>;

  const {
    data,
  } = useNanuqQuery([getMissionKey, missionId], () => missionId ? getMission(missionId) : null, { initialData })

  const navigate = useNavigate()

  const { ability } = useOrganizations()
  const canUpdate = ability?.can('update', 'mission', data?.data.attributes)

  const onSave = async (data: ApiResourceResponse<MissionAttributes>) => {
    navigate(missionRoute(orgId, data.data?.id))
  }

  return (
    <Box
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
    >
      {canUpdate && data?.data?.attributes ?
        <EditMissionForm mission={data?.data?.attributes} orgId={`${orgId}`} onSave={onSave} /> :
        <Loader />
      }
    </Box>
  );
}

export default MissionEdit;