import { Outlet, ScrollRestoration } from 'react-router-dom';
import {
  Box,
  Container,
  Flex,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { OrganizationsProvider } from '@/contexts/useOrganizationsContext';
import { Navbar } from '../Navbar/Navbar';
import { Sidebar } from '../Sidebar/Sidebar';


export const Layout = () => {

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <OrganizationsProvider>

      <Flex
        as="section"
        direction={{ base: 'column', lg: 'row' }}
        height="100vh"
        maxW={'100%'}
        bg="bg-canvas"
        overflowY="auto"
      >
        <Box
          height={{ sm: 'initial', lg: 'full' }}
          width={{ sm: '100%', lg: '12rem' }}
          display={{ base: 'initial', lg: 'initial' }}
          overflowY="auto"
          borderRightWidth={{ base: '0px', lg: '1px' }}
          bg="bg-canvas"
        >
          {isDesktop && <Sidebar />}
        </Box>

        <Box
          bg={useColorModeValue('gray.50', 'gray.900')}
          flex="1"
          maxW={'100%'}
          overflowY="auto"
          py={0}
        >
          <Container py="0" px={0} flex="1">
            <Stack py={0} spacing={{ base: '0', lg: '6' }}>
              <Outlet />
            </Stack>
          </Container>
        </Box>

        {!isDesktop && <Navbar />}

      </Flex>
      <ScrollRestoration />
    </OrganizationsProvider>
  );
}
