import { Outlet } from 'react-router-dom';
import {
  Container,
  Flex,
  Stack,
} from '@chakra-ui/react'

export const EmptyLayout = () => {

  return (
    <Outlet />
  );
}
